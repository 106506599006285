import { baseUrl, onTokenExpired } from "./apiConfig";

const getLocations = async (token) =>{
    return fetch(baseUrl+'/api/locations',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
            return onTokenExpired().then((newToken)=>{
              return getLocations(newToken)
            })
        }

        throw new Error("error")
    })
}

export {getLocations};