import React, { Fragment } from 'react';
import HomePageContent from './HomePageContent';
import { Breadcrumbs } from '../../AbstractElements';

const HomePage = () => {
  return (
    <Fragment>
       <Breadcrumbs parent="Home"  />
      <HomePageContent />
    </Fragment>
  );
};
export default HomePage;