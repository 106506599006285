import { baseUrl,onTokenExpired } from "./apiConfig";

const getProfil = async (token) =>{
    return fetch(baseUrl+'/api/profil',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
            return onTokenExpired().then((newToken)=>{
              return getProfil(newToken)
            })
        }

        throw new Error("error")
    })
}

const getPersons = async(token)=>{

    return fetch(baseUrl+'/api/persons',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
            return onTokenExpired().then((newToken)=>{
              return getPersons(newToken)
            })
        }

        throw new Error("error")
    })
}

const createUser=async(token, user)=>{
    return fetch(baseUrl+'/api/register',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(user)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
            return onTokenExpired().then((newToken)=>{
             return createUser(newToken)
            })
      }
      
      return response.json().then((resJson)=>{
        throw new Error(resJson.message)
      })
    })
}

const setNotificationToken =async(token, notificationToken)=>{
  return fetch(baseUrl+'/api/users/notification-token',{
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(notificationToken)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
          return onTokenExpired().then((newToken)=>{
           return setNotificationToken(newToken, notificationToken)
          })
    }
    
    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}


const getUserById = async(token, id)=>{
  return fetch(baseUrl+'/api/users/'+id,{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
        }
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getUserById(newToken, id)
      })
    }
   
    throw new Error("error")
  })
}


const updateUser= async (token, user)=>{
  return fetch(baseUrl+'/api/users',{
    method: 'PUT',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(user)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return updateUser(newToken, user)
      })
    }
    
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })

};

const updatePassword = async(token, userId, password)=>{
  return fetch(baseUrl+'/api/users/'+userId+`/password`,{
    method: 'PUT',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(password)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return updatePassword(newToken, userId, password)
      })
    }
    
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })

}


export {getProfil, 
        getPersons, 
        createUser,
        updateUser,
        updatePassword, 
        setNotificationToken, 
        getUserById};