import SparepartForm from './SparepartForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const SparepartFormPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Sparepart Form" />
             <SparepartForm/>
            </Fragment>
        )
}

export default SparepartFormPage;