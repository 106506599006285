import React, { Fragment, useState, useEffect } from 'react';
import { redirect } from "react-router-dom";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Btn, H5, UL } from '../../../AbstractElements';
import { EmailAddress, LoginWithJWT, Password, SignIn } from '../../../Constant';
import { useNavigate } from 'react-router-dom';
import { firebase_app, Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/1.png';
import { handleResponse } from '../../../Services/Fack.Backend';
import FormHeader from './FormHeader';
import { useAuth } from '../../../AuthContext';

const LoginTab = ({ selected }) => {
    const {onLogin} = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();

    const [value, setValue] = useState(
        localStorage.getItem('profileURL' || man)
    );
    const [name, setName] = useState(
        localStorage.getItem('Name')
    );

    useEffect(() => {
        localStorage.setItem('profileURL', value);
        localStorage.setItem('Name', name);
    }, [value, name]);

   
    const loginWithJwt = (e) => {
        setLoading(true);
        onLogin(email, password).then(()=>{
            redirect( `/dashboard/default/`)
        }).catch(()=>{
            setLoading(false);
        })
       
    };
    return (
        <Fragment>
            <Form className="theme-form login-form">
                <FormHeader selected={selected} />
                <FormGroup>
                    <Label>Email</Label>
                    <InputGroup>
                        <InputGroupText><i className='icon-user'></i></InputGroupText>
                        <Input className="form-control" type="email" required="" onChange={e => setEmail(e.target.value)} defaultValue={email} />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>{Password}</Label>
                    <InputGroup>
                        <InputGroupText><i class="fa fa-lock"></i></InputGroupText>
                        <Input className="form-control" type={togglePassword ? 'text' : 'password'} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? '' : 'show'}></span></div>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Btn attrBtn={{ color: 'primary', className: 'btn-block', disabled: loading ? loading : loading, onClick: (e) => loginWithJwt(e) }} >{loading ? 'LOADING...' : 'login'}</Btn>
                </FormGroup>
            </Form>
        </Fragment>
    );
};

export default LoginTab;