import { Oval } from  'react-loader-spinner';

const Loading= ({loading})=>{
    return  <Oval
    height={100}
    width={100}
    color="blue"
    wrapperStyle={{}}
    wrapperClass=""
    visible={loading}
    ariaLabel='oval-loading'
    secondaryColor="blue"
    strokeWidth={2}
    strokeWidthSecondary={2}

/>
}

export default Loading;