import { Btn, H5 } from '../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Button,  ListGroup, ListGroupItem, Table } from 'reactstrap';
import { useAuth } from '../../AuthContext';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../CommonElements/Loading';
import { createReport, createReportAttachment, createReportMaterial, deleteReportAttachmentById, deleteReportMaterialById, doneReport, getReportAttachmentsByScheduleIdAndSequenceNumber, getReportByScheduleIdAndSequenceNumber, getReportMaterialsByScheduleIdAndSequenceNumber, updateReport, uploadAttachmentFile } from '../../api/report';
import { getScheduleById } from '../../api/schedule';
import { JsonFormCreatorWrapper, JsonFormWrapper } from '../../Component/JsonForm';
import { getSpareparts } from '../../api/sparepart';
import Select from 'react-select';
import { useNotification } from '../../NotificationContext';
import { useNavigate } from 'react-router';
import { baseUrl } from '../../api/apiConfig';

const AddMaterialModal = (props) => {
  const [stock, setStock] = useState('')
  const [sparepart, setSparepart] = useState(-1)
  const [stockError, setStockError] = useState('value must be greater than 0')
  const [sparepartError, setSparepartError] = useState('sparepart is required')
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Add Material
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
        <Label>Sparepart :</Label>
          <Select
                        value={sparepart}
                        isSearchable={true}
                        options={props.spareparts}
                        onChange={(selectedOption)=>{
                          setSparepart(selectedOption)
                          if(selectedOption.value>=1)setSparepartError("")
                          else setSparepartError("sparepart is required");
                        }}
          />
           <span style={{color:"red"}}>{sparepartError}</span><div style={{marginBottom:'0.5em'}}></div>
          <Label>Count :</Label>
          <input className="form-control digits" value={stock}   pattern="^[-/d]/d*$" type='text' onChange={(e)=>{
          const re =/^[0-9\b]+$/;

          // if value is not blank, then test the regex
          if(e.target.value.trim()==='')
          {
            setStock('')
            setStockError("value must be greater than 0")
            return
          }
      
          if (re.test(e.target.value)) {
            let val = parseInt(e.target.value.trim())
            setStock(val)
            if(val<1){
              setStockError("value must be greater than 0")
            }else{
              setStockError("")
            }
            
          }
        }}></input>
          <span style={{color:"red"}}>{stockError}</span><div style={{marginBottom:'0.5em'}}></div>
          <Button color='primary' size="sm" onClick={()=>{
            if(parseInt(stock)<1)return;
            if(sparepart.value<1)return;
            props.toggler()
            props.onAddMaterial({
              sparepart_id: sparepart.value,
              value: stock
            })
          }}>add material</Button>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};




const SuccesModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Success
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          Form saved
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };
  
  const FailureModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Error
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          {props.message}
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };

  const CompletePreviousReportModalMessage = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Error
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
            CompletePreviousReport
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };


const FormContent = ({ScheduleID, SequenceID}) => {
  const {profil, token} = useAuth()
  const {refreshNotification} = useNotification()
  const [actionType, setActionType] = useState("CREATE"); 
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState({});
  const [formJson, setFormJson] = useState({});
  const [report, setReport] = useState({});
  const [reportMaterials, setReportMaterials] = useState([]);
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [creatingFailureModal, setCreatingFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState('');
  const [addMaterialModal, setAddMaterialModal] = useState(false);
  const [spareparts, setSpareparts]  = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [curAttachmentFile, setCurAttachmentFile]  = useState(null)
  
  const navigate = useNavigate()
  const toggleMaterial = ()=>{setAddMaterialModal(!addMaterialModal)}
  const toggleSuccess = ()=>{
    setSuccessModal(!succesModal);
    navigate(-1);
    
  }



  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  const onAddMaterial = async (jsonMaterial)=>{
    setLoading(true);
    const uploadedMaterial = {...jsonMaterial, schedule_id: parseInt(ScheduleID), schedule_sequence_number: parseInt(SequenceID)}
    createReportMaterial(token, uploadedMaterial).then(()=>{
      getReportMaterialsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((resReportMaterials)=>{
        setReportMaterials(resReportMaterials);
        getSpareparts(token).then((resSparepartJson)=>{
          let options = []
          for(let i=0;i<resSparepartJson.length;i++)
          {
            options.push({value: resSparepartJson[i].ID, label: resSparepartJson[i].name+`(${resSparepartJson[i].stock})`})
          }
    
          setSpareparts(options)
          setLoading(false)
        }).catch(()=>{
    
        })
    
      })
    }).catch((error)=>{
        setFailureModalMessage(error.message)
        setFailureModal(true);
        setLoading(false);
    })
  }

  const doneReportForm = async ()=>{
    let uploadReport ={
      schedule_id : report.schedule_id,
      schedule_sequence_number: report.schedule_sequence_number
      , report_json:JSON.stringify(formJson)};
  
  
  
    doneReport(token, uploadReport).then(()=>{
        refreshNotification()
        setSuccessModal(true);
        setLoading(false);
    }).catch((error)=>{
        setFailureModalMessage(error.message)
        setFailureModal(true);
        setLoading(false);
    })

  }

  const onSubmit = async ()=>{
    setLoading(true);
    let uploadReport ={
        schedule_id : report.schedule_id,
        schedule_sequence_number: report.schedule_sequence_number
        , report_json:JSON.stringify(formJson)};
    
    
    
    updateReport(token, uploadReport).then(()=>{
        setSuccessModal(true);
        setLoading(false);
       
    }).catch((error)=>{
        setFailureModalMessage(error.message);
        setFailureModal(true);
        setLoading(false);
    })
    

    
  }

  const onDeleteAttachment = async(attachmentId)=>{
    setLoading(true);
    deleteReportAttachmentById(token, attachmentId).then(()=>{
      getReportAttachmentsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((attachmentsRes)=>{
        setAttachments(attachmentsRes)
        setCurAttachmentFile(null)
        setLoading(false)
      })
    })
  }

  const onAddAttachment = async ()=>{
    if(curAttachmentFile===null)return
    setLoading(true)
    uploadAttachmentFile(token, curAttachmentFile).then((uploadRes)=>{
      return createReportAttachment(token, {
        schedule_id : report.schedule_id,
        schedule_sequence_number: report.schedule_sequence_number,
        name: uploadRes.file_name
      }).then(()=>{
         return getReportAttachmentsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((attachmentsRes)=>{
            setAttachments(attachmentsRes)
            setCurAttachmentFile(null)
            setLoading(false)
          })

      })
    }).catch((error)=>{
        setFailureModalMessage(error.message)
        setFailureModal(true)
        setLoading(false)
    })
  }

  useEffect(()=>{
    
    getSpareparts(token).then((resSparepartJson)=>{
      let options = []
      for(let i=0;i<resSparepartJson.length;i++)
      {
        options.push({value: resSparepartJson[i].ID, label: resSparepartJson[i].name+`(${resSparepartJson[i].stock})`})
      }

      setSpareparts(options)
    }).catch(()=>{

    })

    getScheduleById(token, ScheduleID).then((scheduleRes)=>{
      createReport(token, {
          schedule_id : parseInt(ScheduleID),
          schedule_sequence_number : parseInt(SequenceID),
          report_json : scheduleRes.json_scheme
      }).then((reportRes)=>{
        getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
          getReportMaterialsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((resReportMaterials)=>{
            setAttachments(jsonRes.attachments)
            setReportMaterials(resReportMaterials);
            setFormJson(JSON.parse(jsonRes.report_json));
            setSchedule(jsonRes.Schedule);
            setReport(jsonRes);
            setLoading(false);
          }).catch(()=>{

          })
          
        }).catch(()=>{

        })
      }).catch((error)=>{
        if(error.message==="complete previous report"){
          setCreatingFailureModal(true);
        }
        getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
          getReportMaterialsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((resReportMaterials)=>{
            setAttachments(jsonRes.attachments)
            setReportMaterials(resReportMaterials);
            setFormJson(JSON.parse(jsonRes.report_json));
            setSchedule(jsonRes.Schedule);
            setReport(jsonRes);
            setLoading(false);
          }).catch(()=>{

          })
        
      }).catch(()=>{

      })

      })
    })

    
    
    getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
        getReportMaterialsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((resReportMaterials)=>{
          setReportMaterials(resReportMaterials);
          setFormJson(JSON.parse(jsonRes.report_json));
          setSchedule(jsonRes.Schedule);
          setReport(jsonRes);
          setActionType("UPDATE")
          setLoading(false);
        })
      
    }).catch((err)=>{
        if(err.message==="not found")
        {

            getScheduleById(token, ScheduleID).then((scheduleRes)=>{
              createReport(token, {
                  schedule_id : parseInt(ScheduleID),
                  schedule_sequence_number : parseInt(SequenceID),
                  report_json : scheduleRes.json_scheme
              }).then((reportRes)=>{
                  setFormJson(JSON.parse(scheduleRes.json_scheme));
                  setSchedule(scheduleRes);
                  setReport(reportRes);
                  setActionType("UPDATE")
                  setLoading(false);
              }).catch(()=>{

              })
            })
           
        }

    });
  }, [])
  return (
    <Fragment>
      <AddMaterialModal onAddMaterial={onAddMaterial} spareparts={spareparts} isOpen={addMaterialModal} toggler={toggleMaterial} >....</AddMaterialModal>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      <CompletePreviousReportModalMessage message={""} isOpen={creatingFailureModal} toggler={()=>{navigate(-1)}} >....</CompletePreviousReportModalMessage>
      
      {loading?(
      <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        
            
          <Loading loading={loading}/>
      </CardBody>
    
      ):(
      <CardBody>

     
        <Table style={{marginTop:'0.5em', marginBottom:'2em'}}>
        <tbody>
          <tr>
            <td>Schedule name</td><td>{schedule.name}</td>  
          </tr>   
          <tr>
            <td>Equipment name</td><td>{schedule.Equipment.name}</td>  
          </tr>   
          <tr>
            <td>Part number</td><td>{schedule.Equipment.part_number}</td>  
          </tr>   
          <tr>
            <td>Serial number</td><td>{schedule.Equipment.serial_number}</td>  
          </tr>  
          <tr>
            <td>Schedule date</td><td>{report.schedule_date}</td>  
          </tr> 
          <tr>
            <td>Person In Charge</td><td>{report.user.Username}</td>  
          </tr> 
       
       
        </tbody>
        </Table>
        
       
        <JsonFormWrapper jsonForm={formJson} formTitle="Schedule" onUpdateJson={(jsonForm)=>{
            setFormJson(jsonForm);
        }}/>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Materials</Label><span>:</span></div>
        <Table style={{marginTop:'0.5em'}}>
        <thead>
          <tr>
            <th>Sparepart Name</th>
            <th>Count</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {reportMaterials.map((reportMaterial)=>{
            return (<tr><td>{reportMaterial.sparepart.name}</td><td>{reportMaterial.value}</td><td>
                <Button color='danger' size="sm" onClick={()=>{
                  setLoading(true);
                  deleteReportMaterialById(token, reportMaterial.ID).then(()=>{
                    getReportMaterialsByScheduleIdAndSequenceNumber(token, parseInt(ScheduleID), parseInt(SequenceID)).then((materialsJson)=>{
                      setReportMaterials(materialsJson)
                      getSpareparts(token).then((resSparepartJson)=>{
                        let options = []
                        for(let i=0;i<resSparepartJson.length;i++)
                        {
                          options.push({value: resSparepartJson[i].ID, label: resSparepartJson[i].name+`(${resSparepartJson[i].stock})`})
                        }
                  
                        setSpareparts(options)
                        setLoading(false)
                      }).catch(()=>{
                  
                      })
                    })
                  }).catch((error)=>{
                    setFailureModalMessage(error.message)
                    setFailureModal(true)
                    setLoading(false)
                  })

                }}>remove</Button>
              </td></tr>)
          })}
        <tr>
          <td colSpan={3}><Button onClick={()=>{setAddMaterialModal(true)}}>Add Material</Button></td>
        </tr>
        </tbody>
        </Table>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Attachments </Label><span>:</span></div>
        <ol style={{display:'flex', flexDirection:'column'}}>
        {
          
          attachments.map((attachment)=>{
          
              return (
                <li style={{marginTop:'0.2em'}}>
                  <a target="_blank" href={baseUrl+"/report/attachment/"+attachment.name}>{attachment.name}</a>
                  <Button onClick={()=>{
                    onDeleteAttachment(attachment.ID)
                  }} size='sm'  style={{marginLeft:'0.1em'}} color='danger'>Delete Attachment</Button>
                </li>)
           
          })
          
        }
        </ol>
        <input type="file" accept="image/png, image/jpeg,  image/jpg"  onChange={(e)=>{
                setCurAttachmentFile(e.target.files[0])
            }}/>
        <Button onClick={()=>onAddAttachment()}>Add Attachment</Button>
       
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Approval</Label><span>:</span></div>
        <ol style={{display:'flex', flexDirection:'column'}}>
        {
          
          report.approvals.map((approval)=>{
            if(approval.approval_type.name==="WAITING")
            {
              return (
                <li>
                  <Label>Approval {approval.user.username} STATUS {approval.approval_type.name}</Label>
                  
                </li>)
            }
            return (
            <li>
              <Label>Approval {approval.user.username} STATUS {approval.approval_type.name}, reason:</Label>
              <div className='border'>{approval.reason}</div>
            </li>)
          })
          
        }
        </ol>
        <CardFooter>
          <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
            <Button style={{margin:'0.5em'}} color='primary' onClick={onSubmit}>Save as draft</Button>
            <Button style={{margin:'0.5em'}} color='success' onClick={doneReportForm}>Submit</Button>
          </div>
        </CardFooter>
      </CardBody>)}
    
    
    </Fragment>
  );
};




const ReportForm = ({ScheduleID, SequenceID}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Report Form</H5>
              </CardHeader>

              <FormContent ScheduleID = {ScheduleID} SequenceID={SequenceID} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ReportForm;


