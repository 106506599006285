import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm,Controller } from 'react-hook-form';
import { Card, Button, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getActivities } from '../../../api/activity';
import { getMachines } from '../../../api/machine';
import { getPersons } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { createSchedule, deleteSchedule, getScheduleById, stopSchedule } from '../../../api/schedule';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../CommonElements/Loading';
import { json, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { updateSchedule } from '../../../api/schedule';
import { JsonFormCreatorWrapper } from '../../../Component/JsonForm';
import { deleteSparepartById } from '../../../api/sparepart';
import rfc3339 from '../../../_helper/Function/rfc3339';
import { createScheduleApproval, deleteScheduleApprovalById, getScheduleApprovalByScheduleId } from '../../../api/approval';
import Select from 'react-select';




const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Schedule succesfully updated
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Fail to update schedule
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
  name: yup.string().min(4, "name is required, min 4 character"),
}).required();

const FormContent = ({scheduleId}) => {
  const {profil, token} = useAuth()
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const [activities, setActivities] = useState([])
  const [currentActivity, setCurrentActivity] = useState('');
  const [machines, setMachines] = useState([])
  const [users, setUsers] = useState([])
  const [intervals, setIntervals] = useState([])
  const [loading, setLoading] = useState(true);
  const [jsonForm, setJsonForm] = useState({});
  const [approvals, setApprovals] = useState([])
  const [currentApproval, setCurrentApproval] = useState({value:-1, label:''})
  const [lastScheduleState, setLastScheduleState] = useState(null)
  const navigate = useNavigate();

  const addActivity = ()=>{
    
    if(currentActivity.trim()==="")return;
    const finalActivities = activities;
    finalActivities.push(currentActivity.trim());
    setCurrentActivity('');
    setActivities(finalActivities);
  }

  const deleteActivity = (activity)=>{
    let arr = activities;
    arr = arr.filter(e => e !== activity); // will return ['A', 'C']
    setActivities(arr);
  }

  useEffect(()=>{
    getPersons(token).then((jsonRes)=>{
      let persons = [];
      for(let i=0;i<jsonRes.length;i++)
      {
          persons.push({value:jsonRes[i].id, label: jsonRes[i].username+'-'+jsonRes[i].role+'-'+jsonRes[i].location})
      }
      setUsers(persons);
    
    }).catch((error)=>{

    })

    getScheduleApprovalByScheduleId(token, scheduleId).then((jsonResApprovals)=>{
      setApprovals(jsonResApprovals)
      
    })

    getScheduleById(token, scheduleId).then((scheduleRes)=>{
        setLastScheduleState(scheduleRes);
        reset({name: scheduleRes.name});
        setJsonForm(JSON.parse(scheduleRes.json_scheme));
        setLoading(false)

    })


   
  }, [])

  const onAddApproval = async ()=>{
    if(currentApproval.value<1)return
    setLoading(true)
    createScheduleApproval(token, scheduleId, {
      schedule_id: parseInt(scheduleId),
      user_id: parseInt(currentApproval.value)
    }).then(()=>{
      getScheduleApprovalByScheduleId(token, scheduleId).then((jsonResApprovals)=>{
        setApprovals(jsonResApprovals)
        setLoading(false)
      })
    }).catch((error)=>{
      alert(error.message)
    })

  }

  const onRemoveApproval = async (approvalId)=>{
    setLoading(true)
    deleteScheduleApprovalById(token, approvalId).then(()=>{
      getScheduleApprovalByScheduleId(token, scheduleId).then((jsonResApprovals)=>{
        setApprovals(jsonResApprovals)
        setLoading(false)
      })

    })
  }


  const onSubmit = (data) => { 
      if(jsonForm.hasOwnProperty("children")===false || jsonForm.children.length<1)return;
      setLoading(true);

      
    
      const uploadedData = {
        ID: parseInt(scheduleId),
        name: data.name,
        json_scheme: JSON.stringify(jsonForm),
      }

      
      updateSchedule(token, uploadedData).then(()=>{
        setSuccessModal(true);
        setLoading(false);
      }).catch(()=>{
        setFailureModal(true)
        setLoading(false);
      })
    
    
  };

  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const toggleSuccess = ()=>{navigate(`/masterdata/schedule`)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
      <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Schedule name</Label>
                <input  {...register("name")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.name?.message}</p>
                
              </Col>
          </Row>

        
          <Row>
          <Col md="12 mb-3" style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                 <JsonFormCreatorWrapper jsonForm={jsonForm} onUpdateJson={(newJsonForm)=>{setJsonForm(newJsonForm)}} formTitle="Schedule"/>
            </Col>
          </Row>

          <Row>
          <Col md="12 mb-3">
            <Label htmlFor="approval">Approval :</Label>
              {
                approvals.map((approval)=>{
                  return ( <div style={{display:'flex', flexDirection:'row', justifyContent:'stretch', marginBottom:'0.2em'}}>
                      <input  className="form-control digits" type='text' value={approval.user.username+'-'+approval.user.role+'-'+approval.user.location} disabled={true}/>
                      <Button  size='sm' style={{marginLeft:'0.2em'}}  color="danger" onClick={()=>{
                        onRemoveApproval(approval.ID)
                      }}>remove</Button>
                    </div>)
                })
              }
              <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                <Select 
                            
                            isSearchable={true}
                            options={users}
                            onChange={(value)=>{
                              setCurrentApproval(value)
                            }}
                          />
                <Button size='sm' style={{marginLeft:'0.2em'}} color="primary" onClick={onAddApproval}>add approval</Button>
              </div>
              {approvals.length<1?(<span style={{color:'red'}}>*approval is required</span>):('')}
            </Col>
          </Row>

          {lastScheduleState.stop_date.Valid===true?(
            <Row>
            <Col md="12 mb-3">
             <Label htmlFor="pic">Stop Date</Label>
                 <input disabled value={lastScheduleState.stop_date.Time}  type="text" className="form-control digits"/>
             
                 
               </Col>
           </Row>
          ):('')}
         
          


          

        </CardBody>
        <CardFooter className="text-end">
            {
              lastScheduleState.stop_date.Valid===false?(
                <Button style={{marginRight:'0.2em'}} color='warning' onClick={()=>{
                  let stopDate = new Date()
                  stopDate.setDate(stopDate.getDate()+8);
                 
                  setLoading(true)
               
                  stopSchedule(token, scheduleId, {stop_date: rfc3339(stopDate)}).then(()=>{
                    setLoading(false)
                    navigate("/masterdata/schedule")
                  }).catch((error)=>{
                    alert(error.message)
                    setLoading(false)
                  })
              }}>Stop Schedule</Button>

              ):('')
            }
           

            <Button style={{marginRight:'0.2em'}} color='danger' onClick={()=>{
                setLoading(true)
                deleteSchedule(token, scheduleId).then(()=>{
                  setLoading(false)
                  navigate("/masterdata/schedule")
                }).catch((error)=>{
                  alert(error.message)
                  setLoading(false)
                })
            }}>Remove Schedule</Button>
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
        </CardFooter>
      </form>
      </Card>
      )
    }
    </Fragment>
    
  );
};




const EditScheduleForm = ({scheduleId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Edit Schedule Form</H5>
              </CardHeader>
              <FormContent scheduleId={scheduleId} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default EditScheduleForm;


