import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import MachinesDataTable from './MachineTable';

const MachinesTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="Equipment Table" />
      <MachinesDataTable />
    </Fragment>
  );
};
export default MachinesTablePage;