import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = require('./app_config.json');

// Firebase
export const firebase_app = firebase.initializeApp({

    apiKey: "AIzaSyBpXTZ4Nk_AKk7bwibLUWX0AsOcbRqnsts",
    authDomain: "pm-logcard-system.firebaseapp.com",
    projectId: "pm-logcard-system",
    storageBucket: "pm-logcard-system.appspot.com",
    messagingSenderId: "200972157310",
    appId: "1:200972157310:web:6ea9ce4d2e8323773df32f",
    measurementId: "G-M1VM9BDQB2"

});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();


// Auth0
export const auth0 = ({
    domain: config.auth0.domain,
    clientId: config.auth0.clientID,
    redirectUri: config.auth0.redirectUri
});

// Jwt
export const Jwt_token = config.jwt_token;


