import SparepartDetail from './SparepartDetail';
import {useParams} from "react-router-dom";
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const SparepartDetailPage=()=>{
    var {sparepartId} = useParams()

        return(
            <Fragment>
            <Breadcrumbs  parent="Master Data" title="Sparepart Detail" />
             <SparepartDetail sparepartId={sparepartId}/>
            </Fragment>
        )
}

export default SparepartDetailPage;