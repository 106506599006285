import EditSparepartForm from "./EditSparepartForm";
import {useParams} from "react-router-dom";
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const EditSparepartFormPage=()=>{
    var {sparepartId} = useParams()

        return(
            <Fragment>
            <Breadcrumbs  parent="Master Data" title="Edit Sparepart Detail" />
             <EditSparepartForm sparepartId={sparepartId}/>
            </Fragment>
        )
}

export default EditSparepartFormPage;