import SparepartRackForm from './SpaprepartRackForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const SparepartRackFormPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Sparepart Rack Form" />
             <SparepartRackForm/>
            </Fragment>
        )
}

export default SparepartRackFormPage;