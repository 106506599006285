import * as React from 'react';
import { setApiOnTokenExpired } from './api/apiConfig';
import { getNumberOfUnprocessedApprovals } from './api/approval';
import { login, refreshToken, refreshTokenCookie } from './api/authentication';
import { getNumberOfTodoReport, getNumberOfUserRejectedReport } from './api/report';
import { getProfil, setNotificationToken } from './api/user';
import { useAuth } from './AuthContext';
import { getNumberOfSparepartReachSafetyStock } from './api/sparepart';








const NotificationContext = React.createContext();

const useNotification = () => {
    return React.useContext(NotificationContext);
};

const NotificationProvider = (props ) => {
    const {token} = useAuth()
    const [approval, setApproval] = React.useState(0)
    const [rejected, setRejected] = React.useState(0)
    const [todo, setTodo] = React.useState(0)
    const [sparepart, setSparepart] = React.useState(0)

    
      

    React.useEffect(()=>{
        getNumberOfUnprocessedApprovals(token).then((jsonRes)=>{
            if(approval!==jsonRes.result){
                setApproval(jsonRes.result)
            }
        })

        getNumberOfUserRejectedReport(token).then((jsonRes)=>{
            if(rejected!==jsonRes.result){
                setRejected(jsonRes.result)
            }
        })

        getNumberOfTodoReport(token).then((jsonRes)=>{
            if(todo!==jsonRes.result){
                setTodo(jsonRes.result)
            }
        })

        getNumberOfSparepartReachSafetyStock(token).then((jsonRes)=>{
            if(sparepart!==jsonRes.result){
                setSparepart(jsonRes.result)
            }
        })

        
        
    })
        

    const refreshNotification =  ()=>{
        getNumberOfUnprocessedApprovals(token).then((jsonRes)=>{
            if(approval!==jsonRes.result){
                setApproval(jsonRes.result)
            }
        })

        getNumberOfUserRejectedReport(token).then((jsonRes)=>{
            if(rejected!==jsonRes.result){
                setRejected(jsonRes.result)
            }
        })

        getNumberOfTodoReport(token).then((jsonRes)=>{
        
            if(todo!==jsonRes.result){
                setTodo(jsonRes.result)
            }
        })

        getNumberOfSparepartReachSafetyStock(token).then((jsonRes)=>{
            if(sparepart!==jsonRes.result){
                setSparepart(jsonRes.result)
            }
        })
    }
    
    
   

   
  
    return (
      <NotificationContext.Provider value={{
          ...props,
          notificationApproval: approval,
          notificationRejected: rejected,
          notificationTodo: todo,
          notificationSparepart: sparepart,
          refreshNotification: refreshNotification
         
        
      }}
        >
        {props.children}
      </NotificationContext.Provider>
    );
};

export {useNotification, NotificationProvider};