import * as React from 'react';
import { setApiOnTokenExpired } from './api/apiConfig';
import { login, logout, refreshToken, refreshTokenCookie } from './api/authentication';
import { getProfil } from './api/user';
import { firebase_app } from './Config/Config';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setNotificationToken } from './api/user';



const AuthContext = React.createContext();

const useAuth = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = (props ) => {
    const [profil, setProfil] = React.useState({
                                    username:"",
                                    email:"",
                                    role:""
                                });
    
   

    const [token, setToken] = React.useState("kosong");
   

    const refreshTokenWithLocalStorage = async ()=>{
        const rToken = localStorage.getItem("token")
        if(rToken===null){
          throw new Error("refresh token is not provided")
        }

        let newToken = await refreshToken(rToken)
        localStorage.setItem("token", newToken.refresh_token) 
        
        setToken(newToken.access_token)

        //reload profil
        getProfil(newToken.access_token).then((jsonRes)=>{
          setProfil(jsonRes)
        }).catch(()=>{

        })

        return newToken.access_token
    }

    const refreshTokenWithCookie = async ()=>{
      
      let newToken = await refreshTokenCookie()
      
      setToken(newToken.access_token)

      //reload profil
      getProfil(newToken.access_token).then((jsonRes)=>{
        setProfil(jsonRes)
      }).catch((error)=>{
        
      })

      return newToken.access_token
  }

    React.useEffect(()=>{
      setApiOnTokenExpired(async ()=>{
        
      if (process.env.NODE_ENV !== 'production') {
        return refreshTokenWithLocalStorage().catch(()=>{
          setToken(null);
          localStorage.setItem("token", null) 
          throw new Error("error")
        })
      }else{
        return refreshTokenWithCookie().catch(()=>{
          setToken(null);
          localStorage.setItem("token", null) 
          throw new Error("error")
        })
      }
       
      })
      
      
      

    }, [])

    React.useEffect(()=>{
      if(profil.username===''){
        getProfil(token).then((profilRes)=>{
          setProfil(profilRes)
        })
      }
    })
  
    const handleLogin = async (username, password) => {
      const loginInfo = await login(username, password);
      localStorage.setItem("token", loginInfo.refresh_token)
      localStorage.setItem("profil", profil) 
      setToken(loginInfo.access_token);
      getProfil(loginInfo.access_token).then((jsonRes)=>{
                   setProfil(jsonRes)
               }).catch(()=>{

               })

      
      

    };
  
    const handleLogout = () => {
      if (process.env.NODE_ENV !== 'production') {
        localStorage.setItem("profil", null) 
        localStorage.setItem("token", null)
        setToken(null);
      }else{
        logout().then(()=>{
          localStorage.setItem("profil", null) 
          localStorage.setItem("token", null)
          setToken(null);
        }).catch(()=>{

        })
      }
     
    };
  
    const value = {
      token: token,
      profil: profil,
      onLogin: handleLogin,
      onLogout: handleLogout,
    };
  
    return (
      <AuthContext.Provider value={{
          ...props,
          token: token,
          profil: profil,
          onLogin: handleLogin,
          onLogout: handleLogout,
        
      }}
        >
        {props.children}
      </AuthContext.Provider>
    );
};

export {useAuth, AuthProvider};