import React, { Fragment } from 'react';
import ChangeUserPasswordForm from './ChangeUserPasswordForm';
import { Breadcrumbs } from '../../../AbstractElements';
import { useParams } from 'react-router';

const ChangeUserPasswordPage = () => {
    var {userId} = useParams()
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="Change User Password" />
      <ChangeUserPasswordForm userId={userId} />
    </Fragment>
  );
};
export default ChangeUserPasswordPage;