import { Btn, H5 } from '../../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import { useAuth } from '../../../AuthContext';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Loading from '../../../CommonElements/Loading';
import { createReport, getReportByScheduleIdAndSequenceNumber, updateReport } from '../../../api/report';
import { getScheduleById } from '../../../api/schedule';
import { JsonFormDisabledWrapper } from '../../../Component/JsonForm';
import { updateApproval } from '../../../api/approval';
import { useNotification } from '../../../NotificationContext';
import { baseUrl } from '../../../api/apiConfig';

const SuccesModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Success
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          Form saved
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };
  
  const FailureModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Error
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          Fail to save form
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };


const FormContent = ({ScheduleID, SequenceID}) => {
  const {profil, token} = useAuth()
  const {refreshNotification} = useNotification()
  const [reason, setReason] = useState([]);
  const [actionType, setActionType] = useState("CREATE"); 
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState({});
  const [formJson, setFormJson] = useState({});
  const [report, setReport] = useState({});
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const toggleSuccess = ()=>{setSuccessModal(!succesModal)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  const onSubmit = async ()=>{
   
  }

  useEffect(()=>{
    getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
        setFormJson(JSON.parse(jsonRes.report_json));
        setSchedule(jsonRes.Schedule);

        //init reason array
        let arr = []
        for(let i=0;i<jsonRes.approvals.length;i++)
        {
          
          arr[jsonRes.approvals[i].toString()]=jsonRes.approvals[i].reason;
          
        }
        setReason(arr)
        setReport(jsonRes);
        setLoading(false);
    }).catch((err)=>{
       

    });
  }, [])

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
 
      {loading?(
      <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        
            
          <Loading loading={loading}/>
      </CardBody>
    
      ):(
      <CardBody style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>

        
<Table style={{marginTop:'0.5em', marginBottom:'2em'}}>
        <tbody>
          <tr>
            <td>Schedule name</td><td>{schedule.name}</td>  
          </tr>   
          <tr>
            <td>Equipment name</td><td>{schedule.Equipment.name}</td>  
          </tr>   
          <tr>
            <td>Part number</td><td>{schedule.Equipment.part_number}</td>  
          </tr>   
          <tr>
            <td>Serial number</td><td>{schedule.Equipment.serial_number}</td>  
          </tr>  
          <tr>
            <td>Schedule date</td><td>{report.schedule_date}</td>  
          </tr> 
          <tr>
            <td>Person In Charge</td><td>{report.user.Username}</td>  
          </tr> 
       
       
        </tbody>
        </Table>
       
        <div style={{alignSelf:'stretch'}}>
        <JsonFormDisabledWrapper  jsonForm={formJson} formTitle="Schedule" onUpdateJson={(jsonForm)=>{
            
        }}/>
        </div>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Materials</Label><span>:</span></div>
        <Table style={{marginTop:'0.5em'}}>
        <thead>
          <tr>
            <th>Sparepart Name</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {report.materials.map((reportMaterial)=>{
            return (<tr><td>{reportMaterial.sparepart.name}</td><td>{reportMaterial.value}</td></tr>)
          })}
        </tbody>
        </Table>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Attachments</Label><span>:</span></div>
        <ol style={{display:'flex', flexDirection:'column'}}>
        {
          
          report.attachments.map((attachment)=>{
          
              return (
                <li>
                  <a target="_blank" href={baseUrl+"/report/attachment/"+attachment.name}>{attachment.name}</a>
                  
                </li>)
           
          })
          
        }
        </ol>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Approvals</Label><span>:</span></div>
        {
            report.approvals.map((approval)=>{
                if(profil.role !="admin" && profil.role!= "hod" && approval.user.username!==profil.username)return ''
              

                if (approval.approval_type.name!=="WAITING")
                {
                    return <><Label  style={{marginRight:'0.5em', marginTop:'0.5em'}}>aproval {approval.user.username} STATUS : {approval.approval_type.name}</Label>
                    
                    </>
                }
                return (
                <>
                <Label>approval {approval.user.username} Note :</Label>
                <Input type='textarea' value={reason[approval.ID.toString()]} onChange={(e)=>{
                    const arr = [...reason]
                    arr[approval.ID.toString()] = e.target.value;
                    setReason(arr)
                   
                }}/>
                <div style={{marginTop:'0.5em' ,display:'flex', flexDirection:'row', alignItems:'flex-start'}}>
                    <Button style={{marginRight:'0.5em'}} color='success'
                    onClick={()=>{
                        setLoading(true);
                        updateApproval(token, {
                            "ID": approval.ID,
                            "user_id": approval.user_id,
                            "approval_type_id": 2,
                            "reason": reason[approval.ID.toString()]
                        }).then(()=>{
                            refreshNotification()
                            getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
                                setFormJson(JSON.parse(jsonRes.report_json));
                                setSchedule(jsonRes.Schedule);
                                setReport(jsonRes);
                                setLoading(false);
                                setSuccessModal(true)
                            }).catch((err)=>{
                                
                        
                            });
                        }).catch(()=>{
                            setFailureModal(true);
                         })
                    }}
                    >approve</Button>
                    <Button color='danger'
                     onClick={()=>{
                        setLoading(true);
                        updateApproval(token, {
                            "ID": approval.ID,
                            "user_id": approval.user_id,
                            "approval_type_id": 3,
                            "reason": reason[approval.ID.toString()]
                        }).then(()=>{
                            getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
                                setFormJson(JSON.parse(jsonRes.report_json));
                                setSchedule(jsonRes.Schedule);
                                let arr = []
                                for(let i=0;i<jsonRes.approvals.length;i++)
                                {
                                  
                                  arr[jsonRes.approvals[i].toString()]=jsonRes.approvals[i].reason;
                                  
                                }
                                setReason(arr)
                                setReport(jsonRes);
                                setLoading(false);
                                setSuccessModal(true)
                            }).catch((err)=>{
                               
                        
                            });
                        }).catch(()=>{
                            setFailureModal(true);
                         })
                    }}
                    >reject</Button>
                </div>
                </>
                )
            })
        }
       
      </CardBody>)}
     
    </Fragment>
  );
};




const ApprovalForm = ({ScheduleID, SequenceID}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Report Form</H5>
              </CardHeader>

              <FormContent ScheduleID = {ScheduleID} SequenceID={SequenceID} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ApprovalForm;


