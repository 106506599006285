import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Button, CardFooter, Label } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { getMonthlySchedules, getSchedule, getScheduleById, getSchedulesByEquipmentId, getWeeklySchedules } from '../../api/schedule';
import { useAuth } from '../../AuthContext';
import { useNavigate } from  "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Close, SaveChanges } from '../../Constant/index';
import { map } from 'leaflet';
import { JsonFormWrapper } from '../../Component/JsonForm';
import { checkStatusBatchOfReport, getReportByScheduleIdAndSequenceNumber } from '../../api/report';
import { DownloadReportPdfButton } from '../../Component/Report PDF';
import Loading from '../../CommonElements/Loading';
import { getMachines } from '../../api/machine';
import { array } from 'yup';
import { getIntervals } from '../../api/interval';



function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function dateInWeekOfTheYar(a) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(a.getFullYear(), 0, 1);

  let daysOfTheYear = Math.floor((utc1 - utc2) / _MS_PER_DAY)+1;
  let weekOftheYear = Math.ceil(daysOfTheYear/7)
  return weekOftheYear;
}



const numberOfWeek = [5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 5];
const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


const startAtWeek = [];
for(let i=0;i<12;i++)
{
  let curMonth = 0;
  for(let j=0;j<i;j++)
  {
    curMonth+=numberOfWeek[j];
  }

  startAtWeek.push(curMonth);
}



function daysInMonth (month, year) {
  return new Date(year, month, 0).getDate();
}


const InfoModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >{Close}</Btn>
      </ModalFooter>
    </Modal>
  );
};






const curDate = new Date();
let tempMonths= [];
const tempColumnWeek = []
for(let i=0;i<53;i++)
{
  tempColumnWeek.push([])
}

if (curDate.getMonth()%3===0){
  tempMonths.push(curDate.getMonth())
  tempMonths.push(curDate.getMonth()+1)
  tempMonths.push(curDate.getMonth()+2)
 
}else if(curDate.getMonth()%3===1){
  tempMonths.push(curDate.getMonth()-1)
  tempMonths.push(curDate.getMonth())
  tempMonths.push(curDate.getMonth()+1)

 
}else{
  tempMonths.push(curDate.getMonth()-2)
  tempMonths.push(curDate.getMonth()-1)
  tempMonths.push(curDate.getMonth())

  
}


const proccessData = (equipments, year, dataSchedules, reports)=>{

    let columnWeek = []
    for(let i=0;i<53;i++)
    {
        columnWeek.push([])
        for(let j=0;j<equipments.length; j++)
        {
            columnWeek[i].push([])
        }
    }

    let hasil = 0;
   
    for(let ei=0; ei<equipments.length; ei++)
    {
        for(let i=0;i<dataSchedules.length;i++)
        {
            if(dataSchedules[i].equipment_id!=equipments[ei].ID)continue
            
            const schDate = new Date(Date.parse(dataSchedules[i].start_date));
            var stopDate = null
            if(dataSchedules[i].stop_date.Valid===true)
            {
              stopDate = new Date(Date.parse(dataSchedules[i].stop_date.Time));
            }

            let startDate = new Date(year, 0, 1);
            let lastDate = new Date(year, 11, 31);
        

            for(let d = startDate; d<=lastDate; d.setDate(d.getDate()+1))
            {
                let dayDiff = dateDiffInDays(schDate, d);
                if(dayDiff<0)continue;

                //cek if schedule have stopped
                if(stopDate!==null && dateDiffInDays(stopDate, d)>0)continue;

                if(dayDiff===0 && dataSchedules[i].Interval.abbr==='FX'){
                  columnWeek[dateInWeekOfTheYar(d)-1][ei].push({...dataSchedules[i],  sequence_id:1});
                  reports.push({schedule_id:dataSchedules[i].ID, sequence_number:1})
                  continue
                }

              

                if(dayDiff%dataSchedules[i].Interval.interval===0)
                {
                  /*
                  //coding buat  multischedule in one cell
                  columnWeek[dateInWeekOfTheYar(d)-1][ei].push({...dataSchedules[i],  sequence_id:dayDiff/dataSchedules[i].Interval.interval+1});
                  reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
                  */

                  //coding single schedule in one cell
                  if(columnWeek[dateInWeekOfTheYar(d)-1][ei].length>0)
                  {
                    if(columnWeek[dateInWeekOfTheYar(d)-1][ei][0].Interval.interval<dataSchedules[i].Interval.interval)
                    {
                    
                      columnWeek[dateInWeekOfTheYar(d)-1][ei][0]={...dataSchedules[i],  sequence_id:dayDiff/dataSchedules[i].Interval.interval+1};
                      reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
                    }
                  }else{
                    columnWeek[dateInWeekOfTheYar(d)-1][ei].push({...dataSchedules[i],  sequence_id:dayDiff/dataSchedules[i].Interval.interval+1});
                    reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
                  }
                }
           
            }
            
        }

    }

    
    return columnWeek
}

const convertCheckStatusBatchOfReportApiResultToDict = (apiResult)=>{
  const result={};
  for(let i=0;i<apiResult.length;i++)
  {
    result[apiResult[i].schedule_id.toString()+","+apiResult[i].sequence_number.toString()] = apiResult[i];
  }
  return result;
}

const todayWeek = dateInWeekOfTheYar(new Date())
const thisWeekColor = "#43ff64d9";

const LogCardViewContent = ()=>{
    const {token, profil} = useAuth();
    const [reportsStatus, setReportsStatus]= useState({});
    const [year, setYear] = useState(curDate.getFullYear());
    const [columnWeek, setColumnWeek] = useState(tempColumnWeek);
    const [equipments, setEquipments] = useState([])
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    const [months, setMonths] = useState(tempMonths)

    const [modalInfo, setModalInfo] = useState({title:'', content:'', jsonForm:{}, visible:false, sequence_id:-1, schedule_id:-1, approval_visible:false, done: false});

    const toogleModal=()=>{
        setModalInfo({...modalInfo, visible:!modalInfo.visible});
    }

  
    const onScheduleClicked = (id, sequenceId)=>{
        getScheduleById(token, id).then((jsonRes)=>{
          const info ={}
          info.title = jsonRes.name
          info.content = (<ul class="list-group">
                          <li class="list-group-item">Machine  : {jsonRes.equipment_name}</li>
                          <li class="list-group-item">Location : {jsonRes.location_name}</li>
                          <li class="list-group-item">Pic : {jsonRes.pic}</li>
                       
                        </ul>);
          info.jsonForm = JSON.parse(jsonRes.json_scheme);
          info.schedule = jsonRes;
          info.sequence_id = sequenceId;
          info.schedule_id = id;
          info.formReportVisible = false

          if(profil.username===jsonRes.pic || profil.role==="admin" || profil.role=="hod"){
            info.formReportVisible = true
          }

          getReportByScheduleIdAndSequenceNumber(token, id, sequenceId).then((jsonReport)=>{
              info.visible = true
              info.approval_visible = false;
              info.jsonForm = JSON.parse(jsonReport.report_json)
              info.done = jsonReport.done
              info.report = jsonReport
              
              if(jsonReport.done===true){
                info.approval_visible = true;
              }
              setModalInfo(info)
          }).catch(()=>{
              info.visible = true
              info.done = false
              info.approval_visible = false;
              setModalInfo(info)
          })

         
        })
    }

    useEffect(()=>{
      getSchedule(token).then((jsonRes)=>{
        setData(jsonRes);
       
        const reports=[]

        getMachines(token).then((jsonEquipmentRes)=>{
          //sort equipment
          jsonEquipmentRes.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          let resColumnWeek=proccessData(jsonEquipmentRes, year, jsonRes, reports);
           

          checkStatusBatchOfReport(token, reports).then((res)=>{
              const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
              setReportsStatus(dictReport)
              setColumnWeek(resColumnWeek);
              setEquipments(jsonEquipmentRes)

              setLoading(false)
              
            })
            
        })
       
 
       
        
      }).catch(()=>{

      })
    }, [])

    //next calendar callback
    const nextCallback = ()=>{
      
      setLoading(true);

      const reports=[]

      setYear(year+1)
      let resColumnWeek=proccessData(equipments, year+1, data, reports);
     

      checkStatusBatchOfReport(token, reports).then((res)=>{
          const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
        
          setReportsStatus(dictReport)
          setColumnWeek(resColumnWeek);

         
          

          setLoading(false)
          
        })

      
    }

    const previousCallback = ()=>{
        setLoading(true);

        const reports=[]
  
        setYear(year-1)
        let resColumnWeek=proccessData(equipments, year-1, data, reports);
        

        checkStatusBatchOfReport(token, reports).then((res)=>{
            const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
            setReportsStatus(dictReport)
            setColumnWeek(resColumnWeek);
            

            setLoading(false)
            
            })
    }

    const header = []
    //setup header
      
    const content = []


    if(loading===false){
        
        content.push([])
        content[0].push(<td></td>)
        for(let i=1;i<=53;i++)
        {
          header.push(<th>{i+1}</th>);
          if(todayWeek===i){
            content[0].push(<td style={{backgroundColor:thisWeekColor}}>This Week</td>)
            continue;
          }
          content[0].push(<td>{i}</td>)
        }
        
       
    
       
        
        for(let y=1;y<=equipments.length;y++)
        {
          content.push([])
          content[y].push(<td>{equipments[y-1].name}</td>)
      
          for(let i=0;i<53;i++)
          {
           
             
    
    
          
       
              
              
                
              content[y].push((
              <td style={{backgroundColor:(i+1)==todayWeek?thisWeekColor:""}}>{columnWeek[i][y-1].map((columnSch)=>{
                    let abbr = columnSch.Interval.abbr
                    let color =""
                    if(abbr==="MT")
                    {
                      color = "#ffff00"
                    }else if(abbr==="QT")
                    {
                      color="#ff9900";
                    }else if(abbr==="6M")
                    {
                      color="#008001"
                    }else if(abbr==="AA")
                    {
                      color="#97cefe"
                    }else if(abbr==="3Y"){
                      color="#ff99cb"
                    }else if(abbr==="FX"){
                      color="#800081"
                    }else{
                      color="#9a99ff"
                    }

                    
                    
                    if(reportsStatus[columnSch.ID.toString()+","+columnSch.sequence_id.toString()].approved.Valid===true){
                      if( reportsStatus[columnSch.ID.toString()+","+columnSch.sequence_id.toString()].approved.Bool===true)
                      {
                          color="success"
                      }else{
                          color="danger"
                      }
                      return (<Button style={{padding:'0.2em', marginTop:'0.2em'}} onClick={()=>{onScheduleClicked(columnSch.ID, columnSch.sequence_id)}} color={color} size="sm">
                                {columnSch.Interval.abbr}
                              </Button>)
                    }
                   
    
                    if(reportsStatus[columnSch.ID.toString()+","+columnSch.sequence_id.toString()].done){
                        color="light"
                        return (<Button style={{padding:'0.2em', marginTop:'0.2em'}} onClick={()=>{onScheduleClicked(columnSch.ID, columnSch.sequence_id)}} color={color} size="sm">
                                  {columnSch.Interval.abbr}
                                </Button>)
                    }
        
                    
    
                    return (<Button style={{padding:'0.2em', marginTop:'0.2em', backgroundColor: color}} onClick={()=>{onScheduleClicked(columnSch.ID, columnSch.sequence_id)}} color={color} size="sm">
                                {columnSch.Interval.abbr}
                            </Button>)
    
    
    
                })
            }    
              </td>
            ))
            
          }
        }

    }
   

    
    
    return(
      <Fragment>
      <Container fluid={true}>
        <InfoModal title={modalInfo.title} isOpen={modalInfo.visible} toggler={toogleModal}>
          {modalInfo.content}

          <div style={{marginTop:'0.5em'}}>
         
          {modalInfo.done===false && modalInfo.formReportVisible===true?(<Button style={{marginRight:'0.5em'}} color='primary' onClick={()=>{navigate("/schedule/"+modalInfo.schedule_id+"/"+modalInfo.sequence_id)}}>form report</Button>):('')}
          {modalInfo.approval_visible===true?(<Button style={{marginRight:'0.5em'}} color='success' onClick={()=>{navigate("/approval/schedule/"+modalInfo.schedule_id+"/"+modalInfo.sequence_id)}}>approval</Button>):''}
          {modalInfo.approval_visible===true?(<DownloadReportPdfButton 
                                                schedule={modalInfo.schedule}
                                                report = {modalInfo.report}
                                                jsonReport={modalInfo.jsonForm}
                                                date={modalInfo.report.schedule_date}
                                                startDate={modalInfo.report.start_date}
                                                finishedDate={modalInfo.report.end_date}
                                                equipmentName={modalInfo.schedule.equipment_name}
                                                equipmentSerialNumber={modalInfo.schedule.equipment_serial_number}
                                               
                                              
                                                >Download report</DownloadReportPdfButton>):('')}

          </div>
        </InfoModal>
        {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
        ):(
        
        <Row>
          <Col sm="12">
        <Card style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>

             <CardHeader  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              
                <Btn  attrBtn={{ color: 'primary', onClick:()=>{previousCallback()},  class:"btn-pill"}} ><i class="fa fa-angle-left"></i>  previous</Btn>
              
                  <H5 class="text-center" style={{flex:5}}>Calendar {year}</H5>
               
                <Btn  attrBtn={{ color: 'primary', onClick:()=>{nextCallback()},  class:"btn-pill"}} >next  <i class="fa fa-angle-right"></i></Btn>
               
              </CardHeader>
           
            <CardBody style={{overflow: 'auto'}}>
             
         
          
            <Table bordered>
                <thead>
                  <tr>
                  <th class="text-center">Equipment Name</th>
                    <th class="text-center" colspan={numberOfWeek[0]}>{monthName[0]}</th>
                    <th class="text-center" colspan={numberOfWeek[1]}>{monthName[1]}</th>
                    <th class="text-center" colspan={numberOfWeek[2]}>{monthName[2]}</th>
                    <th class="text-center" colspan={numberOfWeek[3]}>{monthName[3]}</th>
                    <th class="text-center" colspan={numberOfWeek[4]}>{monthName[4]}</th>
                    <th class="text-center" colspan={numberOfWeek[5]}>{monthName[5]}</th>
                    <th class="text-center" colspan={numberOfWeek[6]}>{monthName[6]}</th>
                    <th class="text-center" colspan={numberOfWeek[7]}>{monthName[7]}</th>
                    <th class="text-center" colspan={numberOfWeek[8]}>{monthName[8]}</th>
                    <th class="text-center" colspan={numberOfWeek[9]}>{monthName[9]}</th>
                    <th class="text-center" colspan={numberOfWeek[10]}>{monthName[10]}</th>
                    <th class="text-center" colspan={numberOfWeek[11]}>{monthName[11]}</th>
                  </tr>
                </thead>
                <tbody>
                  {content.map((rcontent)=>{
                    return(
                      <tr>{rcontent}</tr>
                    )
                  })}
                </tbody>
            </Table>
            </CardBody>
            <CardFooter style={{display:'flex', flexDirection:'row', justifyContent:'space-between', overflow: 'auto'}}>
              <Label style={{marginRight:'0.5em'}}>Legend :</Label>
              <div style={{display:'flex', flexDirection:'column'}}><Button   color= 'primary' size="sm" >None</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}><Button  size="sm"  color= 'danger' >Rejected</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}><Button  size="sm"  color= 'light' >Submited</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}><Button  size="sm"  color= 'success' >Approved</Button></div>
              

            </CardFooter>

        </Card>
        </Col>
        </Row>
        )}
        </Container>
        </Fragment>
    )
}

export default LogCardViewContent;