import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { Navigate, Outlet } from 'react-router-dom';


const PrivateRoute = () => {
    const {token} = useAuth();
   

    useEffect(() => {
       
    }, []);

    return (
        (token !== null)?
            <Outlet />
            :
            <Navigate exact to={`/login`} />
    );
};

export default PrivateRoute;

