import Profil from './Profil';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';


const ProfilPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Profil" title="" />
             <Profil/>
            </Fragment>
        )
}

export default ProfilPage;