import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { createUser, getPersons, updatePassword } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loading from '../../../CommonElements/Loading';
import { getUserRoles } from '../../../api/role';
import { getLocations } from '../../../api/location';



const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Password updated
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
 
  password: yup.string().min(8, "password is required, minimum 8 character").required("username is required, minimum 8 character"),
  confirm_password: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match'),
 
}).required();

const FormContent = ({userId}) => {
  const {profil, token} = useAuth()
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roles, setUserRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState('')

  useEffect(()=>{
    getUserRoles(token).then((jsonRes)=>{
        setUserRoles(jsonRes);
    }).catch(()=>{

    })

    getLocations(token).then((jsonRes)=>{
        setLocations(jsonRes)
    }).catch(()=>{

    })
  

  }, [])


  const onSubmit = (data) => { 
    setLoading(true)
    updatePassword(token, userId, {password: data.password}).then((res)=>{
      setLoading(false)
      setSuccessModal(true)
    }).catch((error)=>{
      setLoading(false)
      setFailureModalMessage(error.message)
      setFailureModal(true)

    })


    
    
  };

  
  const toggleSuccess = ()=>{navigate(`/masterdata/user`)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
      <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        
      
          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Password</Label>
                <input  {...register("password")} type="password" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.password?.message}</p>
                
              </Col>
          </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Confirm Password</Label>
                <input  {...register("confirm_password")} type="password" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.confirm_password?.message}</p>
                
              </Col>
          </Row>
         
         
         

        

        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
        </CardFooter>
      </form>
      </Card>)}
    </Fragment>
  );
};




const ChangeUserPasswordForm = ({userId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Change Password Form</H5>
              </CardHeader>
              <FormContent userId={userId} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ChangeUserPasswordForm;


