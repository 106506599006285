import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { getLocations } from '../../../api/location';
import { Oval } from 'react-loader-spinner';
import { faL } from '@fortawesome/free-solid-svg-icons';

export const tableColumns = [
    {
        name: 'ID',
        selector: Row => Row['ID'],
        sortable: true,
        center: true,
    },
    {
        name: 'Name',
        selector: Row => Row['location_name'],
        sortable: true,
        center: true,
    },
    {
        name: 'Description',
        selector: Row => Row['description'],
        sortable: true,
        center: true,
    }
];




const LocationsDataTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getLocations(token).then((jsonRes)=>{
        setData(jsonRes);
        setLoading(false);
    }).catch(()=>{

    })

  },[])

  return (
    <Fragment>
      <Container fluid={true} className="data-tables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Data Table</H5>
              </CardHeader>
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              {loading?(
                    <Oval
                        height={100}
                        width={100}
                        color="blue"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loading}
                        ariaLabel='oval-loading'
                        secondaryColor="blue"
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />):(
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                 
                />)}
             

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default LocationsDataTable;