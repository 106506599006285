import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button, Input, Label } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { deleteEquipmentById, getMachines } from '../../../api/machine';
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import QRCode from "react-qr-code";


var rowDeleteFunc = null
var showQrCode = null
var navigateToDetail = null
var navigateToEditMachine = null


const QRModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Equipment QrCode
      </ModalHeader>
      <ModalBody style={{ background: 'white', padding: '16px' }}>
          <QRCode value={window.location.hostname+"/equipment/detail/"+props.qrValue.toString()} /><br></br>
          <Label>Equipment name :</Label> {props.equipment_name} <br></br>
          <Label>Location :</Label> {props.location} <br></br>
          <Label>Pic :</Label> {props.pic} <br></br>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

export const tableColumns = [
    {
        name: 'Name',
        selector: Row =>{ 

         return (<span onClick={()=>{
          navigateToDetail(Row['ID']); 
         } }>{Row['name']}</span>) 
        },
        sortable: true,
        center: true,
    },
    {
      name: 'Location Name',
      selector: Row => Row['Location']['location_name'],
      sortable: true,
      center: true,
   }, 
   {
      name: 'PIC',
      selector: Row => Row['user']['username'],
      sortable: true,
      center: true,
  }, {
    name: 'Action',
    selector: Row =>(
    <>
      
      <Button size="sm" color= 'primary' onClick={()=>{
        navigateToEditMachine(Row['ID']);
        
      } } >Edit</Button>

      <Button size="sm" style={{marginLeft:'0.05em'}} color="primary" onClick={()=>{
        showQrCode(Row['ID'], Row['name'], Row['Location']['location_name'], Row['user']['username']);
        
      } } >Show Qr</Button>
    </>
    
    ),
    sortable: true,
    center: true,
},
];




const MachinesDataTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data)
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterEquipmentName ,setFilterEquipmentName] = useState("")
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const [qrModalOpen, setQrModalOpen] = useState({open: false, value:0});
  const [qrModalValue, setQrModalValue] = useState(0);

  const navigate = useNavigate();

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);


  const qrModalToggler = ()=>{
    setQrModalOpen({open: false, value: 0, equipment_name:"", location:"", pic:""})
  }
   
  showQrCode = (qrVal, equipment_name, location, pic)=>{
    
    setQrModalOpen({open: true, value: qrVal, equipment_name: equipment_name, location: location, pic:pic})

  }

  navigateToDetail = (id)=>{
    navigate("/equipment/detail/"+id)
  }
  navigateToEditMachine = (id)=>{
    navigate("/masterdata/equipment/"+id+"/edit")
  }

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getMachines(token).then((jsonRes)=>{
        setData(jsonRes);
        setFilteredData(jsonRes)
        setLoading(false);
    }).catch(()=>{

    })

  },[])


  rowDeleteFunc = async(id)=>{
    setLoading(true);
    deleteEquipmentById(token, id).then(()=>{
      getMachines(token).then((jsonRes)=>{
        setData(jsonRes);
        setFilteredData(jsonRes);
        setLoading(false);
      }).catch(()=>{

      })
    }).catch(()=>{
      setTimeout(()=>{
        getMachines(token).then((jsonRes)=>{
        setData(jsonRes);
        setLoading(false);
      }).catch(()=>{

      })
    }, 1000)})
    
     
  }

  return (
    <Fragment>
      <Container fluid={true} className="data-tables">
        <QRModal isOpen={qrModalOpen.open} toggler={qrModalToggler} qrValue={qrModalOpen.value} equipment_name={qrModalOpen.equipment_name} location={qrModalOpen.location} pic={qrModalOpen.pic}></QRModal>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
              Equipment Name: <Input type='text' onChange={(e)=>{
                  setFilterEquipmentName(e.target.value)
                  const filteredItems = data.filter(
                    item => item.name &&  item.name.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredData(filteredItems)
                  
                }}></Input>
              </CardHeader>
              
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              {loading?(
              <Oval
                  height={100}
                  width={100}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  ariaLabel='oval-loading'
                  secondaryColor="blue"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

              />):(
                <DataTable
                  data={filteredData}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                />
              )}
              </CardBody>
              <CardFooter>
              
              <Btn attrBtn={{ color: 'primary', onClick:()=>navigate(`/masterdata/equipment/create`) }} >Add Equipment</Btn>
            
              
            </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default MachinesDataTable;