import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { configureFakeBackend, authHeader, handleResponse, } from '../Services/Fack.Backend';
import Loader from '../Layout/Loader';
import LayoutRoutes from './LayoutRoutes';
import Callback from '../Auth/Callback';
import { authRoutes } from './AuthRoutes';
import PrivateRoute from './PrivateRoute';
import Signin from '../Auth/Signin';
import { useAuth } from '../AuthContext';
import DetailEquipmentPage from '../Pages/DetailEquipment/DetailEquipmentPage';

configureFakeBackend();


const Routers = () => {
  const {token} = useAuth();
  let tempProfil = localStorage.getItem("profil")!==null?(localStorage.getItem("profil")):(null);
  
  useEffect(() => {
    let abortController = new AbortController();
   
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Fragment>
        <BrowserRouter basename={'/'}> 
          <>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path={'/'} element={<PrivateRoute />}>
                  {token !== null?
                    <>
                      <Route exact
                        path={``}
                        element={<Navigate to={`/home/`} />}
                      />
                      <Route exact
                        path={`/`}
                        element={<Navigate to={`/home/`} />}
                      />
                    </> : ''}
                  <Route path={`/*`} element={<LayoutRoutes />} />
                </Route>
                <Route path={`/callback`} render={() => <Callback />} />
                <Route exact path={`/login`} element={
                    token !== null?
                    <>
                      <Navigate to={`/home/`}/>
                    </> : <><Signin/></>
                } />
              </Routes>
            </Suspense>
          </>
        </BrowserRouter>
        </Fragment>
  );
};
export default Routers;