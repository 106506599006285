import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Table, Button } from 'reactstrap';
import { createUser, getPersons } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loading from '../../../CommonElements/Loading';
import { getLocations } from '../../../api/location';
import { createMachine } from '../../../api/machine';
import Select from 'react-select';
import { createSparepart, createSparepartIn, createSparepartOut, getSparepartById } from '../../../api/sparepart';





const AddStockModal = (props) => {
  const [stock, setStock] = useState(0);
  const [stockError, setStockError] = useState("value must be greater than 0");
  const [noteError, setNoteError] = useState("note is required")
  const [note, setNote] = useState("");

  const onSubmit=async()=>{
    let val = parseInt(stock)
    if(val<1)throw "value must be greater than nol"
  }
  
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Add Stock
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        <label>Stock</label>
        <input className="form-control digits" value={stock}   pattern="^[-/d]/d*$" type='text' onChange={(e)=>{
          const re =/^[0-9\b]+$/;

          // if value is not blank, then test the regex
          if(e.target.value.trim()==='')
          {
            setStock('')
            setStockError("value must be greater than 0")
            return
          }
      
          if (re.test(e.target.value)) {
            let val = parseInt(e.target.value.trim())
            setStock(val)
            if(val<1){
              setStockError("value must be greater than 0")
            }else{
              setStockError("")
            }
            
          }
        }}></input>
        <span style={{color:"red"}}>{stockError}</span><div></div>
         <label>Note</label>

        <textarea className="form-control digits" value={note}  onChange={(e)=>{
          setNote(e.target.value)
          if(e.target.value.trim()==='')setNoteError("note is required");
          else setNoteError("")
        }}></textarea>
        <span style={{color:"red"}}>{noteError}</span><div></div>

      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick:()=> {
          if(parseInt(stock)<1)return
          if(note.trim()==='')return

          props.toggler()
          props.onAdd(parseInt(stock), note)
          
          } }} >Add Stock</Btn>
      </ModalFooter>
    </Modal>
  );
};



const AddStockOutModal = (props) => {
  const [stock, setStock] = useState(0);
  const [stockError, setStockError] = useState("value must be greater than 0");
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("note is required")

  const onSubmit=async()=>{
    let val = parseInt(stock)
    if(val<1)throw "value must be greater than nol"
  }
  
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Add Stock Out
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        <label>Stock</label>
        <input className="form-control digits" value={stock}   pattern="^[-/d]/d*$" type='text' onChange={(e)=>{
          const re =/^[0-9\b]+$/;

          // if value is not blank, then test the regex
          if(e.target.value.trim()==='')
          {
            setStock('')
            setStockError("value must be greater than 0")
            return
          }
      
          if (re.test(e.target.value)) {
            let val = parseInt(e.target.value.trim())
            setStock(val)
            if(val<1){
              setStockError("value must be greater than 0")
            }else{
              setStockError("")
            }
            
          }
        }}></input>
        <span style={{color:"red"}}>{stockError}</span><div></div>
         <label>Note</label>

        <textarea className="form-control digits" value={note} onChange={(e)=>{
           setNote(e.target.value)
           if(e.target.value.trim()==='')setNoteError("note is required");
           else setNoteError("")
        }}></textarea>
         <span style={{color:"red"}}>{noteError}</span><div></div>

      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick:()=> {
          if(parseInt(stock)<1)return
          if(note.trim()==="")return

          props.toggler()
          props.onAdd(parseInt(stock), note)
          
          } }} >Add Stock Out</Btn>
      </ModalFooter>
    </Modal>
  );
};






const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Sparepart stock succesfully updated
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};





const FormContent = ({sparepartId}) => {
  const {profil, token} = useAuth()
  const [sparepart, setSparepart] = useState({name:'', stock:'', ID:''})
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState("")
  const [addStockModal, setAddStockModal] = useState(false);
  const [addStockOutModal, setAddStockOutModal] = useState(false);

 


  useEffect(()=>{
    getSparepartById(token, sparepartId).then((jsonRes)=>{
      setSparepart(jsonRes)
      setLoading(false)
      
    }).catch((error)=>{
      
    })
  }, [])


  const addSparepartStock = (stockVal, stockNote)=>{
    setLoading(true);
   createSparepartIn(token, {sparepart_id: parseInt(sparepartId), value: stockVal, note: stockNote}).then(()=>{
      getSparepartById(token, sparepartId).then((jsonRes)=>{
        setSparepart(jsonRes)
        setLoading(false)
        setSuccessModal(true)
      })
    
   }).catch(()=>{
      setLoading(false);
      setFailureModalMessage("failed to add stock")
      setFailureModal(true);

   })
}


const addSparepartStockOut = (stockVal, stockNote)=>{
  setLoading(true);
 createSparepartOut(token, {sparepart_id: parseInt(sparepartId), value: stockVal, note: stockNote}).then(()=>{
    
    getSparepartById(token, sparepartId).then((jsonRes)=>{
      setSparepart(jsonRes)
      setLoading(false)
      setSuccessModal(true)
    })
  
 }).catch((error)=>{
    setLoading(false);
    setFailureModalMessage(error.message)
    setFailureModal(true);

 })
}

  const toggleSuccess = ()=>{
    setSuccessModal(!succesModal)
    setLoading(false);
}
  const toggleFailure = ()=>{
    setFailureModal(!failureModal)
    setLoading(false);
}

const toggleAddStock = ()=>{
  setAddStockModal(!addStockModal)
 
}


const toggleAddStockOut = ()=>{
  setAddStockOutModal(!addStockOutModal)
 
}

  

  return (
    <Fragment>
       <AddStockModal onAdd={addSparepartStock} isOpen={addStockModal} toggler={toggleAddStock} >....</AddStockModal>
       <AddStockOutModal  onAdd={addSparepartStockOut}  isOpen={addStockOutModal} toggler={toggleAddStockOut} >....</AddStockOutModal>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Loading loading={loading}/>
        
      </CardBody>
      ):(
      
      <>
      <CardBody>
        <Table style={{alignSelf:'flex-start'}}>
          <tbody>
            <tr>
              <td>Sparepart name</td><td>:</td><td>{sparepart.name}</td>
            </tr>
            <tr>
              <td>Stock</td><td>:</td><td>{sparepart.stock}</td>
            </tr>
            <tr>
              <td>Safe stock</td><td>:</td><td>{sparepart.safe_stock}</td>
            </tr>
            <tr>
              <td>Sparepart ID</td><td>:</td><td>{sparepart.sparepart_id}</td>
            </tr>
            <tr>
              <td>SAP ID</td><td>:</td><td>{sparepart.sap_id}</td>
            </tr>
          </tbody>
        </Table>

      </CardBody>
      <CardFooter>
        <Button color='success' onClick={()=>setAddStockModal(true)}>+ stock</Button>
        <Button style={{marginLeft:'0.5em'}}  onClick={()=>setAddStockOutModal(true)} color='warning'>- stock</Button>
        <Button style={{marginLeft:'0.5em'}} color='primary' onClick={()=>navigate("/masterdata/sparepart/"+sparepart.ID+"/edit")}>edit</Button>
      </CardFooter>
      </>
    
     )}
    </Fragment>
  );
};




const SparepartDetail = ({sparepartId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Sparepart Detail Page</H5>
              </CardHeader>
              <FormContent sparepartId={sparepartId}/>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SparepartDetail;


