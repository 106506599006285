import React, { Fragment } from 'react';
import UserTable from './UserTable';
import { Breadcrumbs } from '../../../AbstractElements';

const ScheduleTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="User" />
      <UserTable />
    </Fragment>
  );
};
export default ScheduleTablePage;