import RejectedReportList from './RejectedReportList';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const DashboardRejectedReportPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Dashboard" title="Rejected report" />
             <RejectedReportList/>
            </Fragment>
        )
}

export default DashboardRejectedReportPage;