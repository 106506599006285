import React, { Fragment } from 'react';
import {useParams} from "react-router-dom";
import ApprovalForm from './ApprovalForm';
import { Breadcrumbs } from '../../../AbstractElements';

const ApprovalFormPage = () => {
  var { schedule_id, sequence_id } = useParams();
  
  return (
    <Fragment>
      <Breadcrumbs parent="Schedule" title="Report" />
      <ApprovalForm ScheduleID={schedule_id} SequenceID = {sequence_id} />
    </Fragment>
  );
};
export default ApprovalFormPage;