import React, { Fragment } from 'react';
import ScheduleTable from './ScheduleTable';
import { Breadcrumbs } from '../../../AbstractElements';

const ScheduleTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="schedule" />
      <ScheduleTable />
    </Fragment>
  );
};
export default ScheduleTablePage;