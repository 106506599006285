import { Button } from 'reactstrap';
import html2pdf from 'html2pdf.js';
import { jsonFormToHtmlString, jsonFormToHtmlWithoutListString } from '../../Component/JsonForm';
import { MarginLeft } from '../../Constant';
import { baseUrl } from '../../api/apiConfig';

const DownloadReportPdfButton = ({onDownloadFinished, schedule, report, jsonReport, children, date, startDate, finishedDate, equipmentName, equipmentSerialNumber})=>{
    
    var cdate = new Date(Date.parse(date))
    cdate = cdate.getFullYear()+'-'+(cdate.getMonth()+1)+'-'+cdate.getDate()

    var sdate = new Date(Date.parse(startDate))
    sdate = sdate.getFullYear()+'-'+(sdate.getMonth()+1)+'-'+sdate.getDate()

    var edate = new Date(Date.parse(finishedDate))
    edate = edate.getFullYear()+'-'+(edate.getMonth()+1)+'-'+edate.getDate()

    
    var approvalTable = `<label>Approvals Table:</label><p><table><th>Username</th><th>Role</th><th>Status</th><th>Reason</th>`

    for(let i=0; i<report.approvals.length; i++)
    {
      approvalTable+=`<tr><td>`+report.approvals[i].user.username+`</td><td>`+report.approvals[i].user.role+`</td><td>`+report.approvals[i].approval_type.name+`</td><td>`+report.approvals[i].reason+`</td></tr>`

    }

    approvalTable+='</table>'

    var sparepartTable =  `<label>Materials Table:</label><p><table><th>Sparepart Name</th><th>Count</td>`

    for(let i=0; i<report.materials.length; i++)
    {
      sparepartTable+=`<tr><td>`+report.materials[i].sparepart.name+`</td><td>`+report.materials[i].value+`</td></tr>`

    }

    sparepartTable+='</table>'

    var attachments =`<label>Attachments: </label><p>`

    
    for(let i=0;i<report.attachments.length; i++)
    {
      attachments += `</label><img class="newPage" src="${baseUrl}/report/attachment/${report.attachments[i].name}"  style="width:70%;height:auto;"><p>`
    }

    
   
    

   

    return (<Button style={{MarginLeft:'0.5em'}} color="success" onClick={()=>{
        var opt = {
            margin:       0.25,
            
            filename:     'report - '+equipmentName+' - '+cdate+'.pdf',
            image:        { type: "jpg", quality: 0.95 },
            html2canvas:  { scale: 2, useCORS: true, dpi: 300, letterRendering: true },
            jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' },
            pagebreak: { before: '.newPage',  avoid: 'img' }
          };
    
        var doc = document.createElement("html")
        let style = `<style> table, th, td {
            text-align: center;
            border: 1px solid black;
            border-collapse: collapse;
          }
          
          table {
            width: 100%;
            margin-bottom: 16px;
          }
          
          </style>`
        let headerTable = `<table style="width:100%";   text-align: left ;>
                            <tr>
                                <th style="text-align: left ;">Equipment Name:`+ equipmentName +`</th><th style="text-align: left ;">Time Started:`+sdate+`</th>
                            </tr>
                            <tr>
                                <th style="text-align: left ;">Date:`+cdate+`</th><th style="text-align: left ;">Time Finished:`+edate+`</th>
                            </tr>
                            <tr>
                                <th style="text-align: left ;">Periode:`+ schedule.interval.name +`</th><th style="text-align: left ;">Machine No:`+equipmentSerialNumber+`</th>
                            </tr>
                            <tr>
                              <th style="text-align: left ;">PIC:`+ report.user.Username +`-`+ report.user.UserRole.user_role +`-`+ report.user.Location.location_name +`</th><th style="text-align: left ;"></th>
                            </tr>
                            </table>`
        doc.innerHTML = `<body>`+ style + headerTable+jsonFormToHtmlWithoutListString(jsonReport, 0)+ sparepartTable + approvalTable+ attachments +`</body>`
        var worker = html2pdf().set(opt).from(doc).save().then(()=>{onDownloadFinished()});
        
    }}>{children}</Button>)
}

export {DownloadReportPdfButton};