import { BlogSvg, BonusUISvg, ButtonsSvg, CalanderSvg, ChartsSvg, ChatSvg, ContactSvg, EcommerceSvg, EditorsSvg, EmailSvg, FAQSvg, FilemanagerSvg, FormsSvg, GallarySvg, HeaderBookmarkSvg, HomeSvg, IconsSvg, JobsearchSvg, KanbanSvg, KnowledgebaseSvg, LearningSvg, MapsSvg, OthersSvg, ProjectSvg, SamplePageSvg, SearchResultSvg, SocialappSvg, SupportTicketSvg, TablesSvg, TaskSvg, TodoSvg, UiKitsSvg, UsersComponentSvg, WidgetsSvg } from '../../Data/svgIcons';

export const MENUITEMS = [
    {
        menutitle: 'Home',
        Items: [
            { path: `/home`, icon: HomeSvg, title: 'Home', type: 'link' }
        ]
    },
    {
        menutitle: 'LogCard',
        Items: [
            { path: `/logcard/`, icon: CalanderSvg, title: 'Log Card View', type: 'link' }
        ]
    },
    {
        menutitle: 'Dashboard',
        Items: [
            {
                title: 'Dashboard', icon: TaskSvg, type: 'sub', badge: true, active: false, children: [
                    { path: `/dashboard/approval`, title: 'Approval', type: 'link' },
                    { path: `/dashboard/to-do-report`, title: 'To Do Report', type: 'link' }, 
                    { path: `/dashboard/rejected-report`, title: 'Rejected Report', type: 'link' },
                    { path: `/dashboard/approved-report`, title: 'Approved Report', type: 'link' },                 
                ]
            },
            
        ]
    },
    {
        menutitle: 'Master Data',
        Items: [
            {
                title: 'Master Data', icon: HomeSvg, type: 'sub', badge: true, active: false, children: [
                    { path: `/masterdata/user`, title: 'User', type: 'link' },
                    { path: `/masterdata/schedule`, title: 'Schedule', type: 'link' },
                    { path: `/masterdata/location/`, title: 'Location', type: 'link' },
                    { path: `/masterdata/equipment/`, title: 'Equipment', type: 'link' },
                    { path: `/masterdata/sparepart/`, title: 'Sparepart', type: 'link' },
                    { path: `/masterdata/sparepart-rack/`, title: 'Sparepart-Rack', type: 'link' },
                    
                   
                ]
            },
        ]
    }
    

];