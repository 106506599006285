import EditScheduleForm from './EditScheduleForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useParams } from 'react-router';


const EditScheduleFormPage=()=>{
    const {scheduleId} = useParams()

        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Edit Schedule Form" />
             <EditScheduleForm scheduleId={scheduleId}/>
            </Fragment>
        )
}

export default EditScheduleFormPage;