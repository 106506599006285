import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import SparepartRackDataTable from './SparepartRackTable';

const SparepartRackTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="Sparepart Rack Table" />
      <SparepartRackDataTable />
    </Fragment>
  );
};
export default SparepartRackTablePage;