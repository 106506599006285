import React, { Fragment } from 'react';
import DetailSparepartRackContent from './DetailSparepartRackContent';
import { Breadcrumbs } from '../../AbstractElements';
import { useParams } from 'react-router';

const DetailSparepartRackPage = () => {

  var {rackId} = useParams()
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Sparepart Rack Detail" />
      <DetailSparepartRackContent rackId={rackId}/>
    </Fragment>
  );
};
export default DetailSparepartRackPage;