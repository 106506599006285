import React, { Fragment, } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import Layout from '../Layout/Layout';
import { useAuth } from '../AuthContext';

const LayoutRoutes = () => {
  const {profil, token} = useAuth();
  return (
    <Fragment>
      <Routes>
        {routes.filter(({protect})=>{
            if(protect && !(profil.role==='admin' || profil.role==='hod'))return false;
            return true;
        }).map(({ path, Component }, i) => (
          <Route element={<Layout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        ))}
      </Routes>
    </Fragment >
  );
};

export default LayoutRoutes;