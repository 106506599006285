import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button, Input } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { deleteSchedule, getSchedule, getWeeklySchedules } from '../../../api/schedule';
import { deleteWeeklySchedule } from '../../../api/schedule';
import { getMonthlySchedules } from '../../../api/schedule';
import { deleteMonthlySchedule } from '../../../api/schedule';
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner';

var rowDeleteFunc = null
var navigateToEditSchedule = null
var duplicateSchedule = null


export const tableColumns = [
    {
        name: 'Equipment',
        selector: Row => Row['Equipment']['name'],
        sortable: true,
        center: true,
    },
    {
        name: 'Interval',
        selector: Row => Row['Interval']['name'],
        sortable: true,
        center: true,
    },
    {
        name: 'Start Date',
        selector: Row => {
          let date = new Date(Row['start_date'])
          return date.getDate().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getFullYear().toString()
        },
        sortable: true,
        center: true,
    },
    {
      name: 'Action',
      selector: Row =>(<> 
        <Button color="primary" style={{marginRight:'0.05em'}} size="sm" onClick={()=>duplicateSchedule(Row["ID"])}>copy</Button>
        <Button color="primary" size="sm" onClick={()=>navigateToEditSchedule(Row["ID"])}>edit</Button></>)
  }
];




const ScheduleTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data)
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterEquipmentName, setFilterEquipmentName] = useState()
  const navigate = useNavigate();

  rowDeleteFunc = async(id)=>{
    setLoading(true);
    deleteSchedule(token, id).then(()=>{
      getSchedule(token).then((jsonRes)=>{
        setData(jsonRes);
        setLoading(false);
      }).catch(()=>{

      })
    }).catch(()=>{
      setTimeout(()=>{
      getSchedule(token).then((jsonRes)=>{
        setData(jsonRes);
        setLoading(false);
      }).catch(()=>{

      })
    }, 1000)})
    
     
  }

  navigateToEditSchedule = (id)=>{
    navigate(`/masterdata/schedule/`+id+`/edit`)
  }

  
  duplicateSchedule = (scheduleId)=>{
    navigate("/masterdata/schedule/"+scheduleId+"/duplicate")
  }



  const handleRowSelected = useCallback(state => {
     
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getSchedule(token).then((jsonRes)=>{
      setData(jsonRes);
      setFilteredData(jsonRes);
      setLoading(false);
    }).catch(()=>{
      
    })
    

  },[])

  return (
    <Fragment>
     
      <Container fluid={true} className="data-tables">
        <Row>
          <Col sm="12">
            
            <Card>
              <CardHeader style={{display:'flex', flexDirection:'r'}} className="pb-0">
                Equipment Name: <Input type='text' onChange={(e)=>{
                  setFilterEquipmentName(e.target.value)
                  const filteredItems = data.filter(
                    item => item.Equipment.name &&  item.Equipment.name.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredData(filteredItems)
                  
                }}></Input>
              </CardHeader>
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              {loading?(
              <Oval
                  height={100}
                  width={100}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  ariaLabel='oval-loading'
                  secondaryColor="blue"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

              />):(
                <DataTable
                  style={{overflow:'auto'}}
                  data={filteredData}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                />
              )}
              </CardBody>

              <CardFooter>
              
                  <Btn attrBtn={{ color: 'primary', onClick:()=>navigate(`/masterdata/schedule/create`) }} >Add Schedule</Btn>
                
                  
              </CardFooter>
            </Card>
          </Col>
        </Row>
        

      </Container>
    </Fragment>
  );

};
export default ScheduleTable;