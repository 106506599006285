import MachineForm from './MachineForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const MachineFormPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Equipment Form" />
             <MachineForm/>
            </Fragment>
        )
}

export default MachineFormPage;