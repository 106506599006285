import { baseUrl, onTokenExpired } from "./apiConfig";


const getIntervals = async (token) =>{
    return fetch(baseUrl+'/api/intervals',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
            return onTokenExpired().then((newToken)=>{
              return getIntervals(newToken)
            })
          }

        throw new Error("error")

    })
}

export {getIntervals};
