import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader , Card, Button, CardHeader, Table, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router';
import Loading from '../../CommonElements/Loading';
import DataTable from 'react-data-table-component';
import { getSparepartRackById, getSparepartsByRackId } from '../../api/sparepartRack';


var rowDetailFunc = null

const tableColumns = [
    {
        name: 'Name',
        selector: Row => Row['name'],
        sortable: true,
        center: true,
    },
    {
        name: 'Stock',
        selector: Row => Row['stock'],
        sortable: true,
        center: true,
    },
    {
        name: 'Safe Stock',
        selector: Row => Row['safe_stock'],
        sortable: true,
        center: true,
   },
    {
    name: 'Action',
    selector: Row => {
      return(
          

                <Button size="sm" color='primary' onClick={()=>{rowDetailFunc(Row['ID'])}}>detail</Button>
               
      )
    }
    ,
    sortable: true,
    center: true,
},
];




const DetailSparepartRack = ({rackId}) => {
  const {token} = useAuth()
  const [sparepartRack, setSparepartRack] = useState(null)
  const [spareparts, setSpareparts] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  
  rowDetailFunc = (id)=>{
    navigate(`/masterdata/sparepart/`+id)
  }
    



  useEffect(()=>{
    getSparepartRackById(token, rackId).then((sparepartRackJson)=>{
      setSparepartRack(sparepartRackJson);
      setLoading(false);
    })

    getSparepartsByRackId(token, rackId).then((sparepartsResJson)=>{
      setSpareparts(sparepartsResJson);
    })
    
  },[])
    
  return (
    <Fragment>
     
        <CardBody>
        <Row>
        {loading?(
          <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <CardBody>
              <Loading loading={loading}/>
            </CardBody>
          </Card>
        ):(
          <>
           <Col md="12 mb-3">
           <Card>
            <CardBody>
                Rack Name : {sparepartRack.name}<br/>
                Location : {sparepartRack.location.location_name}<br/>
            </CardBody>
           </Card>
           </Col>

           <Col md="12 mb-3">
              <Card>
              <CardBody>
              <DataTable
                  data={spareparts}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination
                />
              </CardBody>
              </Card>
           </Col>

           

           </>
           
            

           
        )}
          </Row>
        </CardBody>
       
 
    </Fragment>
  );
};





const DetailSparepartRackContent = ({rackId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <DetailSparepartRack rackId={rackId} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default DetailSparepartRackContent;