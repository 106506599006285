import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Label, Button } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { Oval } from 'react-loader-spinner';
import { getSparepartRacks } from '../../../api/sparepartRack';
import { useNavigate } from 'react-router';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import QRCode from "react-qr-code";

var navigateToDetail = null




var rowDeleteFunc = null
var showQrCode = null



const QRModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Sparepart Rack QrCode
      </ModalHeader>
      <ModalBody style={{ background: 'white', padding: '16px' }}>
          <QRCode value={window.location.hostname+"/sparepart-rack/detail/"+props.qrValue.toString()} /><br></br>
          <Label>Rack name :</Label> {props.rack_name} <br></br>
          <Label>Location :</Label> {props.location} <br></br>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

export const tableColumns = [
    {
        name: 'Name',
        selector: Row =>{ 
          return (<span onClick={()=>{
           navigateToDetail(Row['ID']); 
          } }>{Row['name']}</span>) 
         },
        sortable: true,
        center: true,
    },
    {
        name: 'Location',
        selector: Row => Row['location']['location_name'],
        sortable: true,
        center: true,
    }, {
      name: 'Action',
      selector: Row =>(
      <>
        
        
  
        <Button size="sm" style={{marginLeft:'0.05em'}} color="primary" onClick={()=>{
          showQrCode(Row['ID'], Row['name'], Row['location']['location_name']);
          
        } } >Show Qr</Button>
      </>
      
      ),
      sortable: true,
      center: true,
  },
];




const SparepartRackDataTable = () => {
  const {token} = useAuth();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState({open: false, value:0});
  const navigate = useNavigate();


  const qrModalToggler = ()=>{
    setQrModalOpen({open: false, value: 0, rack_name:"", location:""})
  }
   
  showQrCode = (qrVal, rack_name, location)=>{
    
    setQrModalOpen({open: true, value: qrVal, rack_name: rack_name, location: location})

  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  navigateToDetail = (id)=>{
    navigate("/sparepart-rack/detail/"+id)
  }

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getSparepartRacks(token).then((jsonRes)=>{
        setData(jsonRes);
        setLoading(false);
    }).catch(()=>{

    })

  },[])

  return (
    <Fragment>
      <Container fluid={true} className="data-tables">
      <QRModal isOpen={qrModalOpen.open} toggler={qrModalToggler} qrValue={qrModalOpen.value} rack_name={qrModalOpen.rack_name} location={qrModalOpen.location}></QRModal>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Data Table</H5>
              </CardHeader>
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              {loading?(
                    <Oval
                        height={100}
                        width={100}
                        color="blue"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loading}
                        ariaLabel='oval-loading'
                        secondaryColor="blue"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                        

                    />):(
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                 
                />)}
             

              </CardBody>
              <CardFooter>
              <Btn attrBtn={{ color: 'primary', onClick:()=>navigate(`/masterdata/sparepart-rack/create`) }} >Add Sparepart Rack</Btn>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default SparepartRackDataTable;