import React, { Fragment } from 'react';
import DetailEquipmentContent from './DetailEquipmentContent';
import { Breadcrumbs } from '../../AbstractElements';
import { useParams } from 'react-router';

const DetailEquipmentPage = () => {
  var {equipmentId} = useParams()
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Equipment Detail" />
      <DetailEquipmentContent equipmentId = {equipmentId} />
    </Fragment>
  );
};
export default DetailEquipmentPage;