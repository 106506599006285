import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { getWeeklySchedules } from '../../../api/schedule';
import { deleteWeeklySchedule } from '../../../api/schedule';
import { getMonthlySchedules } from '../../../api/schedule';
import { deleteMonthlySchedule } from '../../../api/schedule';
import { Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import { getPersons } from '../../../api/user';

var navigateToEditForm = null


export const tableColumns = [
    {
        name: 'Username',
        selector: Row => Row['username'],
        sortable: true,
        center: true,
    },
    {
        name: 'Role',
        selector: Row => Row['role'],
        sortable: true,
        center: true,
    },
    {
        name: 'Location',
        selector: Row => Row['location'],
        sortable: true,
        center: true,
    } ,
    {
        name: 'Email',
        selector: Row => Row['email'],
        sortable: true,
        center: true,
    } , 
    {
      name: 'Action',
      selector: Row => {
        return (
          <Button color='primary' size='sm' onClick={()=>{
            navigateToEditForm(Row["id"])
          }}>
            edit
          </Button>
        )
      },
      sortable: true,
      center: true,
  } , 
];




const UserTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [loading, setLoading] = useState(true);
  const [filterName, setFilterName] = React.useState();
  const navigate = useNavigate();

  navigateToEditForm = (userId)=>{
     navigate("/masterdata/user/"+userId+"/"+"edit")
  }





 
  useEffect(()=>{
      getPersons(token).then((jsonRes)=>{
        
          
          setData(jsonRes);
          setFilteredData(jsonRes);
          setLoading(false);
          return jsonRes;
      }).catch(()=>{

    })

    

  },[])

  return (
    <Fragment>
     
      <Container fluid={true} className="data-tables">
        <Row>
          <Col sm="12">
            
            <Card>
              <CardHeader className="pb-0">
              User Name: <Input type='text' onChange={(e)=>{
                  setFilterName(e.target.value)
                  const filteredItems = data.filter(
                    item => item.username &&  item.username.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredData(filteredItems)
                  
                }}></Input>
              </CardHeader>
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              {loading?(
              <Oval
                  height={100}
                  width={100}
                  color="blue"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  ariaLabel='oval-loading'
                  secondaryColor="blue"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

              />):(
                <DataTable
                  data={filteredData}
                  columns={tableColumns}
                  pagination
            
                />
              )}
              </CardBody>

              <CardFooter>
              
                  <Btn attrBtn={{ color: 'primary', onClick:()=>navigate(`/masterdata/user/create`) }} >Add User</Btn>
                
                  
              </CardFooter>
            </Card>
          </Col>
        </Row>
        

      </Container>
    </Fragment>
  );

};
export default UserTable;