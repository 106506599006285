import { baseUrl, onTokenExpired } from "./apiConfig";

const getSparepartRacks = async (token) =>{
    return fetch(baseUrl+'/api/sparepart-rack',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
          return onTokenExpired().then((newToken)=>{
            return getSparepartRacks(newToken)
          })
        }

        throw new Error("error")
    })
}


const createSparepartRack=async(token, sparepartRack)=>{
    return fetch(baseUrl+'/api/sparepart-rack',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(sparepartRack)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createSparepartRack(newToken, sparepartRack)
        })
      }

      return response.json().then((jsonRes)=>{
        throw new Error(jsonRes.message)
      })
    })
}


const getSparepartRackById = async(token, id)=>{
    return fetch(baseUrl+'/api/sparepart-rack/'+id,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
          }
    })
    .then((response) => {
      if(response.status===200)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getSparepartRackById(newToken, id)
        })
      }
     
      throw new Error("error")
    })
  }

const getSparepartsByRackId = async(token, id)=>{
  return fetch(baseUrl+'/api/sparepart-rack/'+id+'/spareparts',{
    method: 'GET',
    headers: {
        'Authorization': 'Bearer ' + token,
      }
})
.then((response) => {
  if(response.status===200)
  {
    return response.json()
  }

  if(response.status===401){
    return onTokenExpired().then((newToken)=>{
      return getSparepartsByRackId(newToken, id)
    })
  }
 
  throw new Error("error")
})
}





export {getSparepartRacks, createSparepartRack, getSparepartRackById, getSparepartsByRackId}
