import React, { Fragment } from 'react';
import './App.css';
import Routers from './Routes';
import ProductProvider from './_helper/ecommerce/product/ProductProvider';
import ProjectProvider from './_helper/project-app/ProjectProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/customizer/CustomizerProvider';
import { AuthProvider } from './AuthContext';
import { NotificationProvider } from './NotificationContext';

function App() {
      return (
            <Fragment>
                  <AuthProvider>  
                  <NotificationProvider>
                  <CustomizerProvider>                                                                                                                    
                        <ProductProvider>
                              <ProjectProvider>
                                    <AnimationThemeProvider>    
                                                                                                                                              
                                                <Routers/>
                                                
                                    </AnimationThemeProvider>                                                                                                           
                              </ProjectProvider>
                        </ProductProvider>
                  </CustomizerProvider>
                  </NotificationProvider>
                  </AuthProvider>   
            </Fragment >
      );
}
export default App;