import React, { Fragment, useEffect, useState } from 'react';
import { H5 } from '../../AbstractElements';
import { Card, Button, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { useNavigate } from  "react-router-dom";
import { useAuth } from '../../AuthContext';





const ProfilContent = () => {
  const {token, profil} = useAuth()


  

  return (
    <Fragment>
      <CardBody>
        <Table>
          <tr>
            <td>Email</td><td>{profil.email}</td>
          </tr>
          <tr>
            <td>Username</td><td>{profil.username}</td>
          </tr>
          <tr>
            <td>Role</td><td>{profil.role}</td>
          </tr>
        </Table>
      </CardBody>
       
    </Fragment>
    
  );
};




const Profil = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>Profil</H5>
                </CardHeader>

                <ProfilContent />
              </Card>
            </Col>
          </Row>
             
      </Container>
    </Fragment>
  );
};
export default Profil;


