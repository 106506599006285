import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Button, CardFooter } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import { getMonthlySchedules, getSchedule, getScheduleById, getSchedulesByEquipmentId, getWeeklySchedules } from '../../api/schedule';
import { useAuth } from '../../AuthContext';
import { useNavigate } from  "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Close, SaveChanges } from '../../Constant/index';
import { map } from 'leaflet';
import { JsonFormWrapper } from '../../Component/JsonForm';
import { checkStatusBatchOfReport, getReportByScheduleIdAndSequenceNumber } from '../../api/report';
import { DownloadReportPdfButton } from '../../Component/Report PDF';
import Loading from '../../CommonElements/Loading';



function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function dateInWeekOfTheYar(a) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(a.getFullYear(), 0, 1);

  let daysOfTheYear = Math.floor((utc1 - utc2) / _MS_PER_DAY)+1;
  let weekOftheYear = Math.ceil(daysOfTheYear/7)
  return weekOftheYear;
}



const numberOfWeek = [5, 4, 4, 5, 4, 4, 5, 4, 4, 5, 4, 5];
const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


const startAtWeek = [];
for(let i=0;i<12;i++)
{
  let curMonth = 0;
  for(let j=0;j<i;j++)
  {
    curMonth+=numberOfWeek[j];
  }

  startAtWeek.push(curMonth);
}



function daysInMonth (month, year) {
  return new Date(year, month, 0).getDate();
}


const InfoModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >{Close}</Btn>
      </ModalFooter>
    </Modal>
  );
};






const curDate = new Date();
let tempMonths= [];
const tempColumnWeek = []
for(let i=0;i<53;i++)
{
  tempColumnWeek.push([])
}

if (curDate.getMonth()%3===0){
  tempMonths.push(curDate.getMonth())
  tempMonths.push(curDate.getMonth()+1)
  tempMonths.push(curDate.getMonth()+2)
 
}else if(curDate.getMonth()%3===1){
  tempMonths.push(curDate.getMonth()-1)
  tempMonths.push(curDate.getMonth())
  tempMonths.push(curDate.getMonth()+1)

 
}else{
  tempMonths.push(curDate.getMonth()-2)
  tempMonths.push(curDate.getMonth()-1)
  tempMonths.push(curDate.getMonth())

  
}


const proccessData = (columnWeek, months, year, dataSchedules, reports)=>{


  for(let i=0;i<dataSchedules.length;i++)
  {
    
    const schDate = new Date(Date.parse(dataSchedules[i].start_date));
    let startDate = new Date(year, months[0], 1);
    let lastDate = new Date(year, months[2]+1, 0);
    if(months[0]!==0)startDate.setDate(startDate.getDate()-10);
    if(months[2]!==11)lastDate.setDate(lastDate.getDate()+10);

    for(let d = startDate; d<=lastDate; d.setDate(d.getDate()+1))
    {
      let dayDiff = dateDiffInDays(schDate, d);
      if(dayDiff<0)continue;
      if(dayDiff===0 && dataSchedules[i].Interval.abbr==='FX'){
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:1})
        continue
      }

      if(dayDiff%7===0 && dataSchedules[i].Interval.abbr==='W')
      {
        
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/7+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/7+1})
        continue;
      }

      if(dayDiff%30===0 && dataSchedules[i].Interval.abbr==='MT')
      {
       
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/30+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/30+1})
        continue;
      }

      if(dayDiff%90===0 && dataSchedules[i].Interval.abbr==='QT')
      {
       
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/90+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/90+1})
        continue;
      }

      if(dayDiff%180===0 && dataSchedules[i].Interval.abbr==='6M')
      {
        let dataSchedule =  JSON.parse(JSON.stringify(dataSchedules[i]));
        dataSchedule.sequence_id = dayDiff/180;
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/180+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/180+1})
        continue;
      }

      
      if(dayDiff%366===0 && dataSchedules[i].Interval.abbr==='AA')
      {
     
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/366+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/366+1})
        continue;
      }

      if(dayDiff%(365*3)===0 && dataSchedules[i].Interval.abbr==='3Y')
      {
       
        columnWeek[dateInWeekOfTheYar(d)-1].push({...dataSchedules[i],  sequence_id:dayDiff/(365*3)+1});
        reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/(365*3)+1})
        continue;
      }

    }
    
  }

}

const convertCheckStatusBatchOfReportApiResultToDict = (apiResult)=>{
  const result={};
  for(let i=0;i<apiResult.length;i++)
  {
    result[apiResult[i].schedule_id.toString()+","+apiResult[i].sequence_number.toString()] = apiResult[i];
  }
  return result;
}

const CalenderYearContent = ()=>{
    const {token, profil} = useAuth();
    const [reportsStatus, setReportsStatus]= useState({});
    const [year, setYear] = useState(curDate.getFullYear());
    const [columnWeek, setColumnWeek] = useState(tempColumnWeek);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    const [months, setMonths] = useState(tempMonths)

    const [modalInfo, setModalInfo] = useState({title:'', content:'', jsonForm:{}, visible:false, sequence_id:-1, schedule_id:-1, approval_visible:false, done: false});

    const toogleModal=()=>{
        setModalInfo({...modalInfo, visible:!modalInfo.visible});
    }

  
    const onScheduleClicked = (id, sequenceId)=>{
        getScheduleById(token, id).then((jsonRes)=>{
          const info ={}
          info.title = jsonRes.name
          info.content = (<ul class="list-group">
                          <li class="list-group-item">Machine  : {jsonRes.equipment_name}</li>
                          <li class="list-group-item">Location : {jsonRes.location_name}</li>
                          <li class="list-group-item">Pic : {jsonRes.pic}</li>
                       
                        </ul>);
          info.jsonForm = JSON.parse(jsonRes.json_scheme);
          info.schedule = jsonRes;
          info.sequence_id = sequenceId;
          info.schedule_id = id;
          info.formReportVisible = false

          if(profil.username===jsonRes.pic){
            info.formReportVisible = true
          }

          getReportByScheduleIdAndSequenceNumber(token, id, sequenceId).then((jsonReport)=>{
              info.visible = true
              info.approval_visible = false;
              info.jsonForm = JSON.parse(jsonReport.report_json)
              info.done = jsonReport.done
              info.report = jsonReport
              
              if(jsonReport.done===true){
                info.approval_visible = true;
              }
              setModalInfo(info)
          }).catch(()=>{
              info.visible = true
              info.done = false
              info.approval_visible = false;
              setModalInfo(info)
          })

         
        })
    }

    useEffect(()=>{
      getSchedule(token).then((jsonRes)=>{
        setData(jsonRes);
        const tempCWeek = [];
        for(let i=0;i<53;i++)
        {
          tempCWeek.push([])
        }
        const reports=[]
       
        proccessData(tempCWeek, months, year, jsonRes, reports);

        checkStatusBatchOfReport(token, reports).then((res)=>{
          const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
          setReportsStatus(dictReport)
          setLoading(false)
          setColumnWeek(tempCWeek);
        })

        
 
       
        
      }).catch(()=>{

      })
    }, [])

    //next calendar callback
    const nextCallback = ()=>{
      
      setLoading(true);
      let tempMonths = [...months]
      let tempCWeek = []
      const reports=[]
      for(let i=0;i<53;i++)
      {
        tempCWeek.push([])
      }
      

     
      
      if(tempMonths[2]+3>=12){
        tempMonths=[0,1,2];
   
        
        proccessData(tempCWeek, tempMonths, year+1, data, reports);
        
        
        
       
        

        checkStatusBatchOfReport(token, reports).then((res)=>{
          const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
          setReportsStatus(dictReport)
          setYear(year+1);
          setLoading(false)
          setMonths(tempMonths);
          setColumnWeek(tempCWeek);
        })

        return;
      }
        tempMonths[0]+=3;
        tempMonths[1]+=3;
        tempMonths[2]+=3;

     
       
        
        
        proccessData(tempCWeek, tempMonths, year, data, reports);
        
      

        //set data
       
        
        checkStatusBatchOfReport(token, reports).then((res)=>{
          
          const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
          setReportsStatus(dictReport)
          setLoading(false)
          
          setColumnWeek(tempCWeek)
          setMonths(tempMonths);
        })
    }

    const previousCallback = ()=>{
      setLoading(true)
      let tempMonths = [...months]
      let tempCWeek = []
      const reports=[]

      for(let i=0;i<53;i++)
      {
        tempCWeek.push([])
      }

      if(months[0]-3<0){
        tempMonths= [9, 10, 11];

        
       
        proccessData(tempCWeek, tempMonths, year-1, data, reports);
  
      
        //set data
      

        checkStatusBatchOfReport(token, reports).then((res)=>{
          
          const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
          setReportsStatus(dictReport)
          setYear(year-1);
          setLoading(false)
          setMonths(tempMonths);
          setColumnWeek(tempCWeek);
        })
        return;
      }
      tempMonths[0]-=3;
      tempMonths[1]-=3;
      tempMonths[2]-=3;
  
      
      
      proccessData(tempCWeek, tempMonths, year, data, reports);

    

      //set data
     
      checkStatusBatchOfReport(token, reports).then((res)=>{
        const dictReport = convertCheckStatusBatchOfReportApiResultToDict(res);
        setReportsStatus(dictReport)
        setLoading(false)
        setMonths(tempMonths);
        setColumnWeek(tempCWeek);
      })
    }



    const header = []
    //setup header
  
    const content = []
    content.push([])
    for(let i=startAtWeek[months[0]];i<=startAtWeek[months[0]]+numberOfWeek[months[0]]+numberOfWeek[months[1]]+numberOfWeek[months[2]]-1;i++)
    {
      header.push(<th>{i+1}</th>);
      content[0].push(<td>{i+1}</td>)
    }
    
    let maxHeight= 0;
    for(let i=startAtWeek[months[0]];i<=startAtWeek[months[0]]+numberOfWeek[months[0]]+numberOfWeek[months[1]]+numberOfWeek[months[2]]-1;i++)
    {
      if(columnWeek[i].length>maxHeight)maxHeight=columnWeek[i].length;
    }

   

    for(let y=1;y<=maxHeight;y++)
    {
      content.push([])
      for(let i=startAtWeek[months[0]];i<=startAtWeek[months[0]]+numberOfWeek[months[0]]+numberOfWeek[months[1]]+numberOfWeek[months[2]]-1;i++)
      {
        if(columnWeek[i].length<y)content[y].push(<td></td>)
        else {
          let color = "primary";
          if(columnWeek[i][y-1].Interval.abbr==='MT')color='warning';
          if(columnWeek[i][y-1].Interval.abbr==='QT')color='danger';
          if(columnWeek[i][y-1].Interval.abbr==='6M')color='light';
          if(columnWeek[i][y-1].Interval.abbr==='AA')color='info';
          if(columnWeek[i][y-1].Interval.abbr==='3Y')color='success';
          
          content[y].push((
          <td>
                 <Button style={{padding:'0.2em'}} onClick={()=>{onScheduleClicked(columnWeek[i][y-1].ID, columnWeek[i][y-1].sequence_id)}} color={color} size="sm">
                    <div style={{display:"flex", flexDirection: 'column'}}>
                      <div style={{alignSelf:"center", flex:4}}> {columnWeek[i][y-1].Equipment.name}</div>
                      {((columnWeek[i][y-1].ID.toString()+","+columnWeek[i][y-1].sequence_id.toString()) in reportsStatus)?(
                          reportsStatus[columnWeek[i][y-1].ID.toString()+","+columnWeek[i][y-1].sequence_id.toString()].approved.Valid===true?(
                            reportsStatus[columnWeek[i][y-1].ID.toString()+","+columnWeek[i][y-1].sequence_id.toString()].approved.Bool===true?(
                             
                                <div style={{alignSelf:'flex-start', flex:1, fontSize:'9px'}}>
                                  acc
                                </div>
                              ):(
                                <div style={{alignSelf:'flex-start', flex:1, fontSize:'9px'}}>
                                  rejected
                                </div>
                              
                          )
                          ):( reportsStatus[columnWeek[i][y-1].ID.toString()+","+columnWeek[i][y-1].sequence_id.toString()].done===true?
                                ( <div style={{alignSelf:'flex-start', flex:1, fontSize:'9px'}}>done</div>):
                                ('')

                            )
                      ):(
                       '' 
                      )}
                    </div>
                   
                  </Button>
          </td>
        ))
        }
      }
    }
    
    return(
      <Fragment>
      <Container fluid={true}>
        <InfoModal title={modalInfo.title} isOpen={modalInfo.visible} toggler={toogleModal}>
          {modalInfo.content}

          <div style={{marginTop:'0.5em'}}>
         
          {modalInfo.done===false && modalInfo.formReportVisible===true?(<Button style={{marginRight:'0.5em'}} color='primary' onClick={()=>{navigate("/schedule/"+modalInfo.schedule_id+"/"+modalInfo.sequence_id)}}>form report</Button>):('')}
          {modalInfo.approval_visible===true?(<Button style={{marginRight:'0.5em'}} color='success' onClick={()=>{navigate("/approval/schedule/"+modalInfo.schedule_id+"/"+modalInfo.sequence_id)}}>approval</Button>):''}
          {modalInfo.approval_visible===true?(<DownloadReportPdfButton 
                                                jsonReport={modalInfo.jsonForm}
                                                date={modalInfo.report.schedule_date}
                                                startDate={modalInfo.report.start_date}
                                                finishedDate={modalInfo.report.end_date}
                                                equipmentName={modalInfo.schedule.equipment_name}
                                                equipmentSerialNumber={modalInfo.schedule.equipment_serial_number}
                                               
                                              
                                                >Download report</DownloadReportPdfButton>):('')}

          </div>
        </InfoModal>
        {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
        ):(
        
        <Row>
          <Col sm="12">
        <Card style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>

             <CardHeader  style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              
                <Btn  attrBtn={{ color: 'primary', onClick:()=>{previousCallback()},  class:"btn-pill"}} ><i class="fa fa-angle-left"></i>  previous</Btn>
              
                  <H5 class="text-center" style={{flex:5}}>Calendar {year}</H5>
               
                <Btn  attrBtn={{ color: 'primary', onClick:()=>{nextCallback()},  class:"btn-pill"}} >next  <i class="fa fa-angle-right"></i></Btn>
               
              </CardHeader>
           
            <CardBody style={{overflow: 'auto'}}>
             
         
          
            <Table bordered>
                <thead>
                  <tr>
                    <th class="text-center" colspan={numberOfWeek[months[0]]}>{monthName[[months[0]]]}</th>
                    <th class="text-center" colspan={numberOfWeek[months[1]]}>{monthName[[months[1]]]}</th>
                    <th class="text-center" colspan={numberOfWeek[months[2]]}>{monthName[[months[2]]]}</th>
                  </tr>
                </thead>
                <tbody>
                  {content.map((rcontent)=>{
                    return(
                      <tr>{rcontent}</tr>
                    )
                  })}
                </tbody>
            </Table>
            </CardBody>
            <CardFooter style={{display:'flex', flexDirection:'row', justifyContent:'space-between', overflow: 'auto'}}>
              <div style={{display:'flex', flexDirection:'column'}}>weekly:<Button   color= 'primary' size="sm" >W</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}>monthly         :  <Button  size="sm"  color= 'warning' >MT</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}>quaterly       :  <Button  size="sm"  color= 'danger' >QT</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}>Every 6 months :  <Button  size="sm"  color= 'light' >6M</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}>Annualy        :  <Button  size="sm"  color= 'info' >AA</Button></div>
              <div style={{display:'flex', flexDirection:'column'}}>Every 3 years  :  <Button  size="sm"  color= 'success' >3Y</Button></div>
              

            </CardFooter>

        </Card>
        </Col>
        </Row>
        )}
        </Container>
        </Fragment>
    )
}

export default CalenderYearContent;