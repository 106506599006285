import React, { Fragment } from 'react';
import LogCardViewContent from './LogCardViewContent';
import { Breadcrumbs } from '../../AbstractElements';

const LogCardViewPage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Calendar" title="Schedule Calendar" />
      <LogCardViewContent />
    </Fragment>
  );
};
export default LogCardViewPage;