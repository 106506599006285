import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button, ButtonGroup } from 'reactstrap';
import { Btn, H5 } from '../../AbstractElements';
import { useAuth } from '../../AuthContext';
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import { getSparepartsReachSafeStock } from '../../api/sparepart';



var rowDetailFunc = null

export const tableColumns = [
    {
        name: 'name',
        selector: Row => Row['name'],
        sortable: true,
        center: true,
    },
    {
        name: 'location',
        selector: Row => Row['location']['location_name'],
        sortable: true,
        center: true,
    },
    {
        name: 'stock',
        selector: Row => Row['stock'],
        sortable: true,
        center: true,
    },
    {
        name: 'safe stock',
        selector: Row => Row['safe_stock'],
        sortable: true,
        center: true,
    },
    {
    name: 'action',
    selector: Row => {
      //if(Row["role"]!=="admin")return <></>
      return(
                <Button size="sm" color='primary' onClick={()=>{rowDetailFunc(Row['ID'])}}>detail</Button>
               
      )
    }
    ,
    sortable: true,
    center: true,
},
];




const SparepartAlertDataTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getSparepartsReachSafeStock(token).then((jsonRes)=>{
        for(let i=0; i<jsonRes.length; i++)
        {
          
            jsonRes[i]["role"] = profil.role;
        }
       
        setData(jsonRes);
        setLoading(false);
    }).catch(()=>{

    })

  },[])


  rowDetailFunc = (id)=>{
    navigate(`/masterdata/sparepart/`+id)
  }
    
     
  

  return (
    <Fragment>
      <Container fluid={true} className="data-tables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Data Table</H5>
              </CardHeader>
              
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center', overflow:'auto'}}>
              {loading?(
              <Oval
                  height={100}
                  width={100}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  ariaLabel='oval-loading'
                  secondaryColor="blue"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

              />):(
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default SparepartAlertDataTable;