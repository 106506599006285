import { baseUrl, onTokenExpired } from "./apiConfig";


const updateApproval = async (token, approval)=>{
  
    return fetch(baseUrl+'/api/approvals',{
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(approval)
    })
    .then((response) => {
      if(response.status===200)
      {
        return response.json()
      }
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return updateApproval(newToken, approval)
        })
      }
  
      return response.json().then((resJson)=>{
        throw new Error(resJson.message)
      })


    })
    
}

const getApprovals = async (token) =>{
  return fetch(baseUrl+'/api/approvals',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getApprovals(newToken)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}



const getScheduleApprovalByScheduleId = async (token, scheduleId) =>{
  return fetch(baseUrl+'/api/schedules/'+scheduleId+'/approvals',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getScheduleApprovalByScheduleId(newToken, scheduleId)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}


const createScheduleApproval = async (token, scheduleId, scheduleApproval)=>{
  return fetch(baseUrl+'/api/schedules/'+scheduleId+'/approvals',{
    method: 'POST',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(scheduleApproval)
  })
  .then((response) => {
    if(response.status===201)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return createScheduleApproval(newToken, scheduleId, scheduleApproval)
      })
    }
    
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })

};

const deleteScheduleApprovalById = async(token, id)=>{
  return fetch(baseUrl+'/api/schedules/approvals/'+id,{
    method: 'DELETE',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
})
.then((response) => {
  if(response.status===200)
  {
    return response.json()
  }

  if(response.status===401){
    return onTokenExpired().then((newToken)=>{
      return deleteScheduleApprovalById(newToken, id)
    })
  }
  throw new Error("failed to delete schedule")
})
}



const getNumberOfUnprocessedApprovals = async (token) =>{
  return fetch(baseUrl+'/api/approvals/number-of-unprocessed-approvals',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getNumberOfUnprocessedApprovals(newToken)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}


export {updateApproval, 
        getApprovals, 
        getNumberOfUnprocessedApprovals, 
        getScheduleApprovalByScheduleId, 
        deleteScheduleApprovalById,
        createScheduleApproval};