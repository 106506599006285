import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import { createUser, getPersons, getUserById, updateUser } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loading from '../../../CommonElements/Loading';
import { getUserRoles } from '../../../api/role';
import { getLocations } from '../../../api/location';



const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        User succesfully updated
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
  username: yup.string().min(4, "username is required, minimum 4 character").required("username is required, minimum 4 character"),
  role: yup.number().typeError('role is required').moreThan(0, 'role is required').required('role is required'),
}).required();

const FormContent = ({userId}) => {
  const {profil, token} = useAuth()
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [roles, setUserRoles] = useState([]);
  const [user, setUser] = useState({});
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState('')

  useEffect(()=>{
    getUserRoles(token).then((jsonRes)=>{
        setUserRoles(jsonRes);
        getUserById(token, userId).then((userRes)=>{
          setUser(userRes)
          reset({
            username: userRes.username,
            role: userRes.user_role_id
          })
          setLoading(false)
          
        })
    }).catch(()=>{

    })

   

  }, [])


  const onSubmit = (data) => { 
    setLoading(true);
    
    updateUser(token, {
        ID: user.ID,
        username:data.username,
        user_role_id:data.role,
    }).then(()=>{
        setLoading(false);
        setSuccessModal(true);
    }).catch((error)=>{
        alert(error.message)
        setFailureModalMessage(error.message)
        setFailureModal(true);
        setLoading(false);
    })


    
    
  };

  
  const toggleSuccess = ()=>{navigate(`/masterdata/user`)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
      <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Username</Label>
                <input  {...register("username")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.username?.message}</p>
                
              </Col>
        </Row>

        <Row>
            
          <Col md="12 mb-3">
              <Label htmlFor="activity">Role</Label>
              <select {...register("role")} name="role"   className="form-control digits">
                  <option disabled selected value> -- select role -- </option>
                 {
                  roles.map((item)=>{
                    return (<option value={item.ID}>{item.user_role}</option>)
                  })
                 }
                 
                </select>
                <p style={{color:'red'}}>{errors.role?.message}</p>
          </Col>
          </Row>


          

         

        

        </CardBody>
        <CardFooter className="text-end">
          <Button style={{alignSelf:'flex-start', marginRight:'0.5em'}} onClick={()=>{
            navigate(`/masterdata/user/`+userId+`/edit-password`)
          }}>Change Password</Button>
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
         
        </CardFooter>
      </form>
      </Card>)}
    </Fragment>
  );
};




const EditUserForm = ({userId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Edit User Form</H5>
              </CardHeader>
              <FormContent userId={userId} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default EditUserForm;


