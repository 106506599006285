import { baseUrl } from "./apiConfig";

const login = async(email, password)=>{
    return fetch(baseUrl+'/api/login',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify({email: email, password: password})
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }
        throw new Error("login error")
    })
    
}

const refreshToken = async(token)=>{
    return fetch(baseUrl+'/api/refreshToken',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }
        throw new Error("login error")
    })

}

const refreshTokenCookie = async()=>{
    return fetch(baseUrl+'/api/refreshToken',{
        credentials: 'include',
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }
        throw new Error("login error")
    })
}

const logout = async()=>{
    return fetch(baseUrl+'/api/logout',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }
        throw new Error("logout error")
    })
}

export {login, logout,  refreshToken, refreshTokenCookie};