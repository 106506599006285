import { baseUrl, onTokenExpired } from "./apiConfig";


const getSchedule=async(token)=>{
  return fetch(baseUrl+'/api/schedules',{
    method: 'GET',
    headers: {
        'Authorization': 'Bearer ' + token
      }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getSchedule(newToken)
        })
      }

      throw new Error("error")
      
  })
}

const createSchedule= async (token, schedule)=>{
  return fetch(baseUrl+'/api/schedules',{
    method: 'POST',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(schedule)
  })
  .then((response) => {
    if(response.status===201)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return createSchedule(newToken, schedule)
      })
    }
    
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })

};

const updateSchedule= async (token, schedule)=>{
  return fetch(baseUrl+'/api/schedules',{
    method: 'PUT',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(schedule)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return updateSchedule(newToken, schedule)
      })
    }
    
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })

};


const deleteSchedule = async(token, id)=>{
  return fetch(baseUrl+'/api/schedules/'+id,{
      method: 'DELETE',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return deleteSchedule(newToken, id)
      })
    }
    throw new Error("failed to delete schedule")
  })
}

const getSchedulesByEquipmentId = async (token, id)=>{
  return fetch(baseUrl+'/api/schedules/equipments/'+id,{
    method: 'GET',
    headers: {
        'Authorization': 'Bearer ' + token
      }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getSchedulesByEquipmentId(newToken, id)
        })
      }

      throw new Error("error")
  })
}

const getScheduleById = async(token, id)=>{
  return fetch(baseUrl+'/api/schedules/'+id,{
    method: 'GET',
    headers: {
        'Authorization': 'Bearer ' + token
      }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getScheduleById(newToken, id)
        })
      }

      return response.json().then((jsonRes)=>{
        alert(JSON.stringify(jsonRes))
        throw new Error(jsonRes.message)
      })
  })
}

const stopSchedule = async(token, id, stopScheduleArg)=>{
  return fetch(baseUrl+'/api/schedules/'+id+'/stop',{
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(stopScheduleArg)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return stopSchedule(newToken, id, stopScheduleArg)
      })
    }
    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })
}




export {
       createSchedule,
       stopSchedule,
       updateSchedule, 
       deleteSchedule, 
       getSchedule,
       getSchedulesByEquipmentId,
       getScheduleById};