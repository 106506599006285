import React, { Fragment } from 'react';
import TestFile from './TestFileContent';
import { Breadcrumbs } from '../../AbstractElements';

const TestFilePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Calendar" title="Schedule Calendar" />
      <TestFile />
    </Fragment>
  );
};
export default TestFilePage;