import { Btn, H5 } from '../../AbstractElements';
import {  Monthly, Submit } from '../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader , Card, Button, CardHeader, Table, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getDoneReportsByEquipmentId, uploadAttachmentFile } from '../../api/report';
import { useAuth } from '../../AuthContext';
import { getEquipmentById, getMachines } from '../../api/machine';
import { useNavigate } from 'react-router';
import Loading from '../../CommonElements/Loading';




const DetailEquipment = ({equipmentId}) => {
  const {token} = useAuth()
  const [equipment, setEquipment] = useState(null)
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()



  useEffect(()=>{
    
    getEquipmentById(token, equipmentId).then((equipmentRes)=>{
      setEquipment(equipmentRes)
      getDoneReportsByEquipmentId(token, equipmentId).then((jsonRes)=>{

        let reportsDate = []
        for(let i=0;i<jsonRes.length;i++)
        {
            reportsDate.push({
                ...jsonRes[i],
                date: new Date(Date.parse(jsonRes[i].schedule_date))
            })
        }

        reportsDate.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return  a.date - b.date;
          });
        setReports(reportsDate)
        setLoading(false)
      })
    }).catch((error)=>{
      
    })
  },[])
    
  return (
    <Fragment>
     
        <CardBody>
        <Row>
        {loading?(
          <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <CardBody>
              <Loading loading={loading}/>
            </CardBody>
          </Card>
        ):(
          <>
           <Col md="12 mb-3">
           <Card>
            <CardHeader>Machine Detail</CardHeader>
            <CardBody>
                Equipment Name : {equipment.name}<br/>
                Part Number : {equipment.part_number}<br/>
                Serial Number : {equipment.serial_number}<br/>
                Location : {equipment.Location.location_name}<br/>
                PIC : {equipment.user.Username}<br/>
            </CardBody>
           </Card>
           </Col>

            {reports.map((report)=>{
                  
                  
                  return(
                        <Row>
                          <Col md="12 mb-3" style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                            <Button outline color='primary' style={{alignSelf:'stretch', padding:'1em'}} onClick={()=>{
                                navigate("/report/"+report.schedule_id+"/"+report.schedule_sequence_number)
                            }}>
                            {report.date.getFullYear()+'-'+(report.date.getMonth()+1)+'-'+report.date.getDate()}
                            </Button>

                          </Col>
                        </Row>
                  )

                })}

           </>
            

           
        )}
          </Row>
        </CardBody>
       
 
    </Fragment>
  );
};





const DetailEquipmentContent = ({equipmentId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <DetailEquipment equipmentId={equipmentId} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default DetailEquipmentContent;