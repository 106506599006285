import React, { Fragment, useEffect, useState } from 'react';
import { useAsync } from 'react-bootstrap-typeahead';
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Button, CardFooter, Label } from 'reactstrap';
import { H1, H5 } from '../../AbstractElements';
import { getMachines } from '../../api/machine';
import { checkStatusBatchOfReport } from '../../api/report';
import { getSchedule } from '../../api/schedule';
import { useAuth } from '../../AuthContext';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Loading from '../../CommonElements/Loading';
import { getNumberOfSparepartReachSafetyStock } from '../../api/sparepart';
import { CSVLink, CSVDownload } from "react-csv";
import { useNavigate } from 'react-router';
import * as FileSaver from 'file-saver';
import XLSX from "sheetjs-style";
import { getLocations } from '../../api/location';

ChartJS.register(ArcElement, Tooltip, Legend);

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function dateInWeekOfTheYar(a) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(a.getFullYear(), 0, 1);

  let daysOfTheYear = Math.floor((utc1 - utc2) / _MS_PER_DAY)+1;
  let weekOftheYear = Math.ceil(daysOfTheYear/7)
  return weekOftheYear;
}


const proccessData = (equipments, dataSchedules, reports, overdueList, reaminingList, thisWeekList)=>{

  const todayWeek = dateInWeekOfTheYar(new Date())
  const year = (new Date()).getFullYear()
  for(let ei=0; ei<equipments.length; ei++)
  {
      for(let i=0;i<dataSchedules.length;i++)
      {

        if(dataSchedules[i].equipment_id!=equipments[ei].ID)continue
            
        const schDate = new Date(Date.parse(dataSchedules[i].start_date));

        var stopDate = null
        if(dataSchedules[i].stop_date.Valid===true)
        {
          stopDate = new Date(Date.parse(dataSchedules[i].stop_date.Time));
        }

        let startDate = new Date(year, 0, 1);
        let lastDate = new Date(year, 11, 31);
    

        for(let d = startDate; d<=lastDate; d.setDate(d.getDate()+1))
        {
            let dayDiff = dateDiffInDays(schDate, d);
            if(dayDiff<0)continue;

            //cek if schedule have stopped
            if(stopDate!==null && dateDiffInDays(stopDate, d)>0)continue;

            if(dayDiff===0 && dataSchedules[i].Interval.abbr==='FX'){
             
              reports.push({schedule_id:dataSchedules[i].ID, sequence_number:1})
              
              if(dateInWeekOfTheYar(d)<todayWeek){
                  overdueList.push({schedule_id:dataSchedules[i].ID,  sequence_number:1})
              }else if(dateInWeekOfTheYar(d)>todayWeek){
                  reaminingList.push({schedule_id:dataSchedules[i].ID,  sequence_number:1})
              }else{
                  thisWeekList.push({schedule_id:dataSchedules[i].ID,  sequence_number:1})
              }
              continue
            }

          

            if(dayDiff%dataSchedules[i].Interval.interval===0)
            {
              if(dateInWeekOfTheYar(d)<todayWeek){
                  overdueList.push({schedule_id:dataSchedules[i].ID,  sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
              }else if(dateInWeekOfTheYar(d)>todayWeek){
                  reaminingList.push({schedule_id:dataSchedules[i].ID,  sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
              }else{
                  thisWeekList.push({schedule_id:dataSchedules[i].ID,  sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})
              }

              
              reports.push({schedule_id:dataSchedules[i].ID, sequence_number:dayDiff/dataSchedules[i].Interval.interval+1})

            }
       
        }

          
          
      }

  }

}

const convertCheckStatusBatchOfReportApiResultToDict = (apiResult)=>{
  const result={};
  for(let i=0;i<apiResult.length;i++)
  {
    result[apiResult[i].schedule_id.toString()+","+apiResult[i].sequence_number.toString()] = apiResult[i];
  }
  return result;
}

const ExportScheduleNoticeExcel = ({dataSchedules, fileName}) =>{
  const {token} = useAuth();
  const [locations, setLocations] = useState([])

  useEffect(()=>{
    getLocations(token).then((locationRes)=>{
      setLocations(locationRes);
     
    })
  }, [])


  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const currentDate = new Date();
  var startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  var lastDate = null

  var jsonScheduleNotice207 = []
  var jsonScheduleNotice209 = []
  var jsonScheduleNotice238 = []
  var jsonScheduleNoticeDormitory = []
  for(let li=0; li<locations.length;li++)
  {
    if(currentDate.getMonth()<11)
    {
      lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1)
    }else{
      lastDate = new Date(currentDate.getFullYear()+1, 0, 1)
    }

    
    for(let d = new  Date(startDate); d<=lastDate; d.setDate(d.getDate()+1))
    {
          
    
        for(let i=0;i<dataSchedules.length;i++)
        {
          if(dataSchedules[i].Equipment.location_id!=locations[li].ID)continue;
        
          const schDate = new Date(Date.parse(dataSchedules[i].start_date));

        

          var stopDate = null
          if(dataSchedules[i].stop_date.Valid===true)
          {
            stopDate = new Date(Date.parse(dataSchedules[i].stop_date.Time));
        
          }
        
  
        
      
          let dayDiff = dateDiffInDays(schDate, d);
          if(dayDiff<0)continue;
          

          
          if(stopDate!==null && dateDiffInDays(stopDate, d)>0)continue;

          
        
          if(dayDiff===0 && dataSchedules[i].Interval.abbr==='FX'){
            const newExcelRow = {
              "WEEK": dateInWeekOfTheYar(d),
              "SCHEDULE DATE":d.toDateString(),
              "MACHINE TYPE": dataSchedules[i].Equipment.name,
              "FREQUENCY": dataSchedules[i].Interval.name,
              "PERSON RESPONSIBLE": dataSchedules[i].Equipment.user.username
            }
            if(dataSchedules[i].Equipment.location_id===1){
              jsonScheduleNotice207.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===2)
            {
              jsonScheduleNotice209.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===3)
            {
              jsonScheduleNotice238.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===4)
            {
              jsonScheduleNoticeDormitory.push(newExcelRow);
            }

            continue;
          }

        

          if(dayDiff%dataSchedules[i].Interval.interval===0)
          {
      
            const newExcelRow = {
              "WEEK": dateInWeekOfTheYar(d),
              "SCHEDULE DATE":d.toDateString(),
              "MACHINE TYPE": dataSchedules[i].Equipment.name,
              "FREQUENCY": dataSchedules[i].Interval.name,
              "PERSON RESPONSIBLE": dataSchedules[i].Equipment.user.username
            }
            if(dataSchedules[i].Equipment.location_id===1){
              jsonScheduleNotice207.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===2)
            {
              jsonScheduleNotice209.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===3)
            {
              jsonScheduleNotice238.push(newExcelRow);
            }else if(dataSchedules[i].Equipment.location_id===4)
            {
              jsonScheduleNoticeDormitory.push(newExcelRow);
            }
            
            continue;
            

          }

        }
        

        


    }
  }
  
  

  



 

  const exportExcel = async ()=>{
    const ws207 = XLSX.utils.json_to_sheet(jsonScheduleNotice207);
    const ws209 = XLSX.utils.json_to_sheet(jsonScheduleNotice209);
    const ws238 = XLSX.utils.json_to_sheet(jsonScheduleNotice238);
    const wsDormitory = XLSX.utils.json_to_sheet(jsonScheduleNoticeDormitory);
  
    const wb = {Sheets:{'207':ws207, '209':ws209, '238': ws238, "dormitory": wsDormitory}, SheetNames:['207', '209', '238', "dormitory"]};
    const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, monthNames[currentDate.getMonth()]+" schedule notice " +fileExtension);
  }

  return (
    <>
      <Button color="primary" onClick={()=>exportExcel(fileName)}>
        Download This Month Schedule Notice
      </Button>
    </>
  )
}


const HomePageContent = ()=>{
    const {token} = useAuth()
    const [loading, setLoading] = useState(true)
    const [equipments, setEquiments] = useState([])
    const [schedules, setSchedules] = useState([])

    const [accepted, setAccepted] = useState(0)
    const [overdue, setOverdue] = useState(0)
    const [reamining, setReamining] = useState(0)
    const [thisWeek, setThisWeek] = useState(0)
    const [submitted, setSubmitted] = useState(0)
    const [rejected, setRejected] = useState(0)
    const [total, setTotal] = useState(0)
    const [sparepartAlert, setSparepartAlert] = useState(0)
    const navigate = useNavigate()

   

    const data = {
      labels: ['Rejected', 'This week', 'Submitted', 'Approved', 'Remaining', 'Overdue'],
      datasets: [
        {
          label: '# of Votes',
          data: [rejected, thisWeek, submitted, accepted, reamining, overdue],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    const doughnutOption = {
      maintainAspectRatio: false,
      legend: {
          display: false,
      },
      plugins: {
          datalabels: {
              display: false,
              color: 'white'
          }
      }
  };
    
    useEffect(()=>{
      getMachines(token).then((equipmentList)=>{
          getSchedule(token).then((scheduleList)=>{
         
            setSchedules(scheduleList);

            const reports = []
            const overdueList = []
            const thisWeekList = []
            const reaminingList = []

            proccessData(equipmentList, scheduleList, reports, overdueList, reaminingList, thisWeekList )
          

            checkStatusBatchOfReport(token, reports).then((statusesResult)=>{
              const dictReports = convertCheckStatusBatchOfReportApiResultToDict(statusesResult)
              let countOverdue = 0
              for(let i=0; i<overdueList.length; i++)
              {
                let curReport = dictReports[overdueList[i].schedule_id.toString()+","+overdueList[i].sequence_number.toString()]
                
                if(curReport.done===false && curReport.approved.Valid===false){
                  countOverdue+=1;
                }
                
              }

              let countAccepted = 0
              let countSubmitted = 0
              let counRejected = 0;

              for(let i=0; i<overdueList.length; i++)
              {
                let curReport = dictReports[overdueList[i].schedule_id.toString()+","+overdueList[i].sequence_number.toString()]
                
                if(curReport.approved.Valid === true &&  curReport.approved.Bool===true ){
                  countAccepted+=1;
                }

                if(curReport.approved.Valid === true &&  curReport.approved.Bool===false ){
                  counRejected+=1;
                }

                if(curReport.done===true && curReport.approved.Valid === false)
                {
                  countSubmitted+=1;
                }

                
                
              }

              for(let i=0; i<thisWeekList.length; i++)
              {
                let curReport = dictReports[thisWeekList[i].schedule_id.toString()+","+thisWeekList[i].sequence_number.toString()]
                
                if(curReport.approved.Valid === true &&  curReport.approved.Bool===true ){
                  countAccepted+=1;
                }

                
                if(curReport.approved.Valid === true &&  curReport.approved.Bool===false ){
                  counRejected+=1;
                }

                if(curReport.done===true && curReport.approved.Valid === false)
                {
                  countSubmitted+=1;
                }
                
              }

              for(let i=0; i<reaminingList.length; i++)
              {
                let curReport = dictReports[reaminingList[i].schedule_id.toString()+","+reaminingList[i].sequence_number.toString()]
                
                if(curReport.approved.Valid === true &&  curReport.approved.Bool===true ){
                  countAccepted+=1;
                }

                
                if(curReport.approved.Valid === true &&  curReport.approved.Bool===false ){
                  counRejected+=1;
                }

                

                if(curReport.done===true && curReport.approved.Valid === false)
                {
                  countSubmitted+=1;
                }
                
              }

              


              let countReamining = 0

             
              for(let i=0; i<reaminingList.length; i++)
              {
                let curReport = dictReports[reaminingList[i].schedule_id.toString()+","+reaminingList[i].sequence_number.toString()]
                
           
                if(curReport.done===false && curReport.approved.Valid === false){
                  countReamining+=1;
                }
                
              }

              let countThisWeek = 0

              
              for(let i=0; i<thisWeekList.length; i++)
              {
      
                let curReport = dictReports[thisWeekList[i].schedule_id.toString()+","+thisWeekList[i].sequence_number.toString()]
             
                
                if(curReport.done===false && curReport.approved.Valid===false){
                  countThisWeek+=1;
                }
                
              }

              

             

      

              getNumberOfSparepartReachSafetyStock(token).then((resultJson)=>{
                setSparepartAlert(resultJson.result)

                setRejected(counRejected)

                setThisWeek(countThisWeek)

                setReamining(countReamining)

                setAccepted(countAccepted)

                setSubmitted(countSubmitted)

                setTotal(reports.length)

            
              
                setOverdue(countOverdue)
                setLoading(false)

              })

            })
            

          })
      })

    }, [])

    
    
    return(
      <Fragment>
      <Container fluid={true}>
      
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
        ):(
        <>


        <Row style={{marginTop:'0.5em'}}>
          <Col sm="12" md="6">
          <Card style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
            <CardHeader><H5>Reports</H5></CardHeader>
            <CardBody style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <Doughnut data={data} />
            </CardBody>
            <CardFooter>Total : {total} </CardFooter>

          </Card>
          </Col>
         
        <Col sm="12" md="6">
        <Card style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
          <CardBody style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
            <Row>
              <Col xs="6" sm="6" md="6" style={{display:'flex', flexDirection:'column',alignItems:'stretch'}}>
                <h4 style={{textAlign:'center', color:'red'}}>Rejected</h4>
                <h1 style={{textAlign:'center', color:'red'}}>{rejected}</h1>
                <Button color='danger' onClick={()=>{
                      navigate(`/dashboard/rejected-report`)
                    }}>Show</Button>
              </Col>
              <Col xs="6" sm="6" md="6" style={{display:'flex', flexDirection:'column',alignItems:'stretch'}}>
                <h4 style={{textAlign:'center', color:'orange'}}>Overdue</h4>
                <h1 style={{textAlign:'center', color:'orange'}}>{overdue}</h1>
                <Button color='warning' onClick={()=>{
                       navigate(`/dashboard/to-do-report`)
                    }}>Show</Button>
              </Col>
            </Row>

            <Row style={{marginTop:'1em'}}>
              <Col xs="6" sm="6" md="6" style={{display:'flex', flexDirection:'column',alignItems:'stretch'}}>
                <h4 style={{textAlign:'center', color:'blue'}}>To Do This Week</h4>
                <h1 style={{textAlign:'center'}}>{thisWeek}</h1>
                <Button color='primary' onClick={()=>{
                       navigate(`/dashboard/to-do-report`)
                    }}>Show</Button>
              </Col>
              <Col xs="6" sm="6" md="6" style={{display:'flex', flexDirection:'column',alignItems:'stretch'}}>
                <h4 style={{textAlign:'center', color:'green'}}>Approved</h4>
                <h1 style={{textAlign:'center', color:'green'}}>{accepted}</h1>
                <Button color='success' onClick={()=>{
                       navigate(`/dashboard/approved-report`)
                    }}>Show</Button>
              </Col>
            </Row>

            <Row style={{marginTop:'3em', marginRight:'1em', marginLeft:'1em'}}>
              <ExportScheduleNoticeExcel dataSchedules={schedules}></ExportScheduleNoticeExcel>
            </Row>
            
           
          </CardBody>
 

        </Card>
        </Col>
        </Row>

        

         </>

        
        )}
        </Container>
        </Fragment>
    )
}

export default HomePageContent;