import EditMachineForm from './EditMachineForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useParams } from 'react-router';


const EditMachineFormPage=()=>{
        const {equipmentId} = useParams()
        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Edit Equipment Form" />
             <EditMachineForm equipmentId={equipmentId}/>
            </Fragment>
        )
}

export default EditMachineFormPage;