import LocationsTablePage from '../Pages/MasterData/Location/LocationTablePage';
import MachinesTablePage from '../Pages/MasterData/Machine/MachineTablePage';
import MachineFormPage from '../Pages/MasterData/Machine/MachineFormPage';


import ScheduleCalender from '../Pages/Schedule';
import ScheduleFormPage from '../Pages/MasterData/Schedule/ScheduleFormPage';
import ScheduleTablePage from '../Pages/MasterData/Schedule/ScheduleTablePage';
import UserTablePage from '../Pages/MasterData/User/UserTablePage';
import UserFormPage from '../Pages/MasterData/User/UserFormPage';
import ReportFormPage from '../Pages/Report/ReportFormPage';
import ApprovalFormPage from '../Pages/Approval/Approval1/ApprovalFormPage';
import SparepartFormPage from '../Pages/MasterData/Sparepart/SparepartFormPage';
import SparepartTablePage from '../Pages/MasterData/Sparepart/SparepartTablePage';
import SparepartDetailPage from '../Pages/MasterData/Sparepart/SparepartDetailPage';
import TestPdfPage from '../Pages/TestPdf/TestPdfPage';
import DashboardApprovalPage from '../Pages/PmDashboard/Approval/DashboardApprovalPage';
import DashboardReportPage from '../Pages/PmDashboard/Report/DashboardReportPage';
import DashboardRejectedReportPage from '../Pages/PmDashboard/Report/DashboardRejectedReportPage';
import DashboardTodoReportPage from '../Pages/PmDashboard/Report/DashboardToDoReportPage';
import ViewReportPage from '../Pages/Report/ViewReportPage';
import ProfilPage from '../Pages/Profil/ProfilPage';
import LogCardViewPage from '../Pages/LogCardView/LogCardViewPage';
import HomePage from '../Pages/Home/HomePage';
import SparepartAlertTablePage from '../Pages/Home/SparepartAlertTablePage';
import TestFilePage from '../Pages/TestUploadFile/TestFilePage';
import DetailEquipmentPage from '../Pages/DetailEquipment/DetailEquipmentPage';
import SparepartRackTablePage from '../Pages/MasterData/SparepartRack/SparepartRackTablePage';
import DetailSparepartRackPage from '../Pages/DetailSparepartRack/DetailSparepartRackPage';
import SparepartRackFormPage from '../Pages/MasterData/SparepartRack/SparepartRackFormPage';
import EditSparepartFormPage from '../Pages/MasterData/Sparepart/EditSparepartFormPage';
import EditMachineFormPage from '../Pages/MasterData/Machine/EditMachineFornPage';
import EditScheduleFormPage from '../Pages/MasterData/Schedule/EditScheduleFormPage';
import DuplicateSchedulePage from '../Pages/MasterData/Schedule/DuplicateSchedulePage';
import EditUserFormPage from '../Pages/MasterData/User/EditUserFormPage';
import ChangeUserPasswordPage from '../Pages/MasterData/User/ChangeUserPasswordPage';

export const routes = [
    { path: `/home/`, Component: <HomePage /> , protect: false},
    { path: `/test/`, Component: <TestFilePage /> , protect: false},
    { path: `/home/sparepart-alert`, Component: <SparepartAlertTablePage /> , protect: false},
    { path: `/calendar/`, Component: <LogCardViewPage /> , protect: false},
    { path: `/profil/`, Component: <ProfilPage /> , protect: false},
    { path: `/logcard/`, Component: <LogCardViewPage /> , protect: false},
    { path: `/schedule/:schedule_id/:sequence_id`, Component: <ReportFormPage /> , protect: false},
    { path: `/report/:schedule_id/:sequence_id`, Component: <ViewReportPage /> , protect: false},
    { path: `/approval/schedule/:schedule_id/:sequence_id`, Component: <ApprovalFormPage /> , protect: false},

    { path: `/masterdata/user`, Component: <UserTablePage />  , protect: true},
    { path: `/masterdata/user/create`, Component: <UserFormPage />, protect: true },
    { path: `/masterdata/user/:userId/edit`, Component: <EditUserFormPage />, protect: true },
    { path: `/masterdata/user/:userId/edit-password`, Component: <ChangeUserPasswordPage />, protect: true },
   
    { path: `/masterdata/schedule`, Component: <ScheduleTablePage />  , protect: false},
    { path: `/masterdata/schedule/:scheduleId/edit`, Component: <EditScheduleFormPage />  , protect: false},
    { path: `/masterdata/schedule/create`, Component: <ScheduleFormPage />, protect: false },

    { path: `/masterdata/location/`, Component: <LocationsTablePage /> , protect: false},

    { path: `/masterdata/equipment/`, Component: <MachinesTablePage /> , protect: false},
    { path: `/masterdata/equipment/:equipmentId/edit`, Component: <EditMachineFormPage /> , protect: false},
    { path: `/masterdata/equipment/create`, Component: <MachineFormPage /> , protect: false},

    { path: `/masterdata/sparepart/`, Component: <SparepartTablePage /> , protect: false},
    { path: `/masterdata/sparepart/:sparepartId`, Component: <SparepartDetailPage /> , protect: false},

    { path: `/masterdata/sparepart/create`, Component: <SparepartFormPage /> , protect: false},
    { path: `/masterdata/sparepart/:sparepartId/edit`, Component: <EditSparepartFormPage /> , protect: false},

    { path: `/masterdata/sparepart-rack/`, Component: <SparepartRackTablePage /> , protect: false},
    { path: `/masterdata/sparepart-rack/create`, Component: <SparepartRackFormPage /> , protect: false},

    
    { path: `/dashboard/approval`, Component: <DashboardApprovalPage /> , protect: false},
    { path: `/dashboard/to-do-report`, Component: <DashboardTodoReportPage /> , protect: false},
    { path: `/dashboard/rejected-report`, Component: <DashboardRejectedReportPage /> , protect: false},
    { path: `/dashboard/approved-report`, Component: <DashboardReportPage /> , protect: false},

    { path: `/equipment/detail/:equipmentId`, Component: <DetailEquipmentPage /> , protect: false},

    { path: "/masterdata/schedule/:scheduleId/duplicate", Component: <DuplicateSchedulePage /> , protect: false},

    { path: `/sparepart-rack/detail/:rackId`, Component: <DetailSparepartRackPage /> , protect: false},
];