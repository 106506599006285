import React, { Fragment } from 'react';
import { Bell, X } from 'react-feather';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HeaderNotificationSvg } from '../../../Data/svgIcons';
import { H3, H5, LI, P, UL } from '../../../AbstractElements';
import teacher from '../../../assets/images/avtar/teacher.png';
import reportimage from '../../../assets/pms_images/report_img.png'
import approvalimage from '../../../assets/pms_images/approval_img.png'
import rejectedimage from '../../../assets/pms_images/report_rejected_img.png'
import sparepartimage from '../../../assets/pms_images/sparepart_img.png'
import { useNotification } from '../../../NotificationContext';


const NotificationData = [
  {
    id:1,
    name:"Approval",
    desp:"8 report need approval",
    time:"dfsjdkfsd",
    class:'bg-light-primary',
    img: approvalimage
  },
  {
    id:2,
    name:"Report",
    desp:"9 report need to be submitted",
    time:"dfsjdkfsd",
    class:'bg-light-primary',
    img: reportimage
  },
  {
    id:2,
    name:"Rejected report",
    desp:"9 report rejected",
    time:"dfsjdkfsd",
    class:'bg-light-primary',
    img: rejectedimage
  }
]

const Notification = () => {
  const {notificationApproval, notificationRejected, notificationTodo, notificationSparepart} = useNotification()

  const NotificationData = []

  let cnt = 0;
  if(notificationApproval>0)
  {
    cnt+=1;
    NotificationData.push( {
      id:1,
      name:"Approval",
      desp:notificationApproval+" report need approval",
      class:'bg-light-primary',
      link:`/dashboard/approval`,
      img: approvalimage
    })
  }

  if(notificationRejected>0){
    cnt+=1;
    NotificationData.push( {
      id:2,
      name:"Rejected report",
      desp:notificationRejected+" report rejected",
      class:'bg-light-primary',
      link:`/dashboard/rejected-report`,
      img: rejectedimage
    })
  }

  if(notificationTodo>0){
    cnt+=1;
    NotificationData.push( {
      id:3,
      name:"Todo report",
      desp:notificationTodo+" need to be done",
      class:'bg-light-primary',
      link:`/dashboard/to-do-report`,
      img: reportimage
    })
  }

  if(notificationSparepart>0){
    cnt+=1;
    NotificationData.push( {
      id:4,
      name:"Sparepart Stock Warning",
      desp:notificationSparepart+" sparepart reach warning safety stock",
      class:'bg-light-primary',
      link:`/home/sparepart-alert`,
      img: sparepartimage
    })
  }
  

  return (
    <Fragment>
      <LI attrLI={{ className: 'onhover-dropdown' }}>
      <div className="notification-box"><HeaderNotificationSvg /><span className="badge rounded-pill badge-warning">{notificationApproval+notificationRejected+notificationTodo+notificationSparepart}</span></div>
        <div className={`onhover-show-div notification-dropdown`}>
          <div className="dropdown-title">
            <H3>Notification</H3><a className="f-right" href="#javascript"><Bell /></a>
          </div>
          <UL attrUL={{ className: 'simple-list custom-scrollbar' }}>
            {
              NotificationData.map((item) => {
                return (
                  <LI key={item.id}>
                    <Media>
                    <div className={`notification-img ${item.class}`}><img src={item.img} alt="" /></div>
                      <Media body>
                        <H5><Link to={item.link} className='f-14 m-0'>{item.name}</Link></H5>
                        <P>{item.desp}</P><span>{item.time}</span>
                      </Media>
                    </Media>
                  </LI>
                );
              })
            }
            <LI attrLI={{ className: 'p-0' }}></LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  );
};
export default Notification;