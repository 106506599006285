import React, { Fragment, useEffect, useState } from 'react';
import { Card, Button, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { useNavigate } from  "react-router-dom";
import { useAuth } from '../../../AuthContext';
import { getApprovals } from '../../../api/approval';
import { getApprovedReport, getUserRejectedReport } from '../../../api/report';
import Loading from '../../../CommonElements/Loading';





const ReportListContent = () => {
  const {token, profil} = useAuth()
  const [reports, setReports] = useState([])
  const [filteredReports, setFilteredReports]= useState([])
  const [loading, setLoading] = useState(true)
  const [filterText, setFilterText] =useState('')
  const navigate = useNavigate();

  useEffect(()=>{
    getApprovedReport(token).then((jsonRes)=>{
      let reportsDate = []
        for(let i=0;i<jsonRes.length;i++)
        {
            reportsDate.push({
                ...jsonRes[i],
                date: new Date(Date.parse(jsonRes[i].schedule_date))
            })
        }

        reportsDate.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return  a.date - b.date;
          });
        setReports(reportsDate)
        setFilteredReports(reportsDate)
        setLoading(false)
   
    })
  }, [])

  

  return (
    <Fragment>
      {loading?(
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Loading loading={loading}/>
        </div>
           
       ):(
        <>
        <Row>
          <Col md="12 mb-3" style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
            <Input type='text' placeholder="Equipment name" onChange={(e)=>{
                  setFilterText(e.target.value)
                  const filteredItems = reports.filter(
                    item => item.Schedule.Equipment.name &&  item.Schedule.Equipment.name.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredReports(filteredItems)
                  
                }}></Input>

          </Col>
        </Row>
        {
        filteredReports.map((report)=>{
        
         
          return(
                <Row>
                  <Col md="12 mb-3" style={{display:'flex', flexDirection:'column', alignItems:'stretch'}}>
                    <Button outline color='success' style={{alignSelf:'stretch', padding:'1em'}} onClick={()=>{
                        navigate("/report/"+report.schedule_id+"/"+report.schedule_sequence_number)
                    }}>
                     {report.Schedule.Equipment.name+"-"+report.date.getFullYear()+'-'+(report.date.getMonth()+1)+'-'+report.date.getDate()}
                    </Button>

                  </Col>
                </Row>
          )

        })
      }</>
      )}
       
    </Fragment>
    
  );
};




const ReportList = () => {
  return (
    <Fragment>
      <Container fluid={true}>
      
              <ReportListContent />
           
      </Container>
    </Fragment>
  );
};
export default ReportList;


