import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useAuth } from '../../../AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loading from '../../../CommonElements/Loading';
import { createSparepart } from '../../../api/sparepart';
import { getLocations } from '../../../api/location';
import { getSparepartRacks } from '../../../api/sparepartRack';




const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Sparepart succesfully created
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
  name: yup.string().min(4, "name is required, minimum 4 character").required("username is required, minimum 4 character"),
  location: yup.number().typeError('location is required').moreThan(0, 'location is required').required('location is required'),
  sparepart_rack: yup.number().typeError('sparepart rack is required').moreThan(0, 'sparepart rack is required').required('sparepart rack is required'),
  sparepart_id: yup.string().min(2, "sparepart id is required, minimum 2 character").required("sparepart id is required, minimum 2 character"),
  sap_id: yup.string().min(2, "sap id is required, minimum 2 character").required("sap id is required, minimum 2 character"),
}).required();

const FormContent = () => {
  const {profil, token} = useAuth()
  const { register, handleSubmit,control, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(0)
  const [locations, setLocations]= useState([]);
  const [sparepartRacks, setSparepartRacks] = useState([]);
  const [filteredSparepartRacks, setFilteredSparepartsRacks] = useState([]);
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState("")


  useEffect(()=>{
    getLocations(token).then((jsonRes)=>{
      setLocations(jsonRes)
    })

    getSparepartRacks(token).then((jsonRes)=>{
      setSparepartRacks(jsonRes)
      setFilteredSparepartsRacks(jsonRes)
    })

  }, [])


  const onSubmit = (data) => { 
    setLoading(true);
    createSparepart(token, {
        "name":data.name,
        "location_id":data.location,
        "safe_stock":stock,
        "sparepart_rack_id":data.sparepart_rack,
        "sparepart_id": data.sparepart_id,
        "sap_id":data.sap_id

    }).then(()=>{
        setLoading(false);
        setSuccessModal(true);
    }).catch((error)=>{
        setFailureModalMessage(error.message);
        setLoading(false);
        setFailureModal(true);
    })
  };

 
  const toggleSuccess = ()=>{
    navigate(`/masterdata/sparepart`)
    setLoading(false);
}
  const toggleFailure = ()=>{
    setFailureModal(!failureModal)
    setLoading(false);
}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message = {failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
         <CardHeader className="pb-0">
                <H5>Sparepart Form</H5>
              </CardHeader>
          <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <Label htmlFor="name">Name</Label>
                <input  {...register("name")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.name?.message}</p>
                
              </Col>
          </Row>

        <Row>
          <Col md="12 mb-3">
            <Label htmlFor="name">Safe Stock</Label>
              <input className="form-control digits" value={stock}  type='text' onChange={(e)=>{
                const re =/^[0-9\b]+$/;

                // if value is not blank, then test the regex
                if(e.target.value.trim()==='')
                {
                  setStock(0)
                  return
                }
            
                if (re.test(e.target.value)) {
                  let val = parseInt(e.target.value.trim())
                  setStock(val)
                
                  
                }
              }}></input>
        </Col>
        </Row>

          <Row>
            
            <Col md="12 mb-3">
                <Label htmlFor="location">Sparepart Location</Label>
                <select {...register("location", {onChange:(e)=>{
                  let tempSparepartsRacks = []
                  for(let i=0;i<sparepartRacks.length;i++)
                  {
                    if(sparepartRacks[i].location_id==e.target.value)
                    {
                      tempSparepartsRacks.push(sparepartRacks[i]);
                    }
                  }

                  setFilteredSparepartsRacks(tempSparepartsRacks);
                }})} name="location"   className="form-control digits">
                    <option disabled selected value> -- select location -- </option>
                   {
                    locations.map((item)=>{
                      return (<option value={item.ID}>{item.location_name}</option>)
                    })
                   }
                   
                  </select>
                  <p style={{color:'red'}}>{errors.location?.message}</p>
            </Col>
            </Row>

            <Row>
            
            <Col md="12 mb-3">
                <Label htmlFor="sparepart_rack">Sparepart Rack</Label>
                <select {...register("sparepart_rack")} name="sparepart_rack"   className="form-control digits">
                    <option disabled selected value> -- select rack -- </option>
                   {
                    filteredSparepartRacks.map((item)=>{
                      return (<option value={item.ID}>{item.name}({item.location.location_name})</option>)
                    })
                   }
                   
                  </select>
                  <p style={{color:'red'}}>{errors.location?.message}</p>
            </Col>
            </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="name">Sparepart ID</Label>
                <input  {...register("sparepart_id")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.sparepart_id?.message}</p>
                
              </Col>
          </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="name">SAP ID</Label>
                <input  {...register("sap_id")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.sap_id?.message}</p>
                
              </Col>
          </Row>

    

        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
        </CardFooter>
      </form>
      </Card>)}
    </Fragment>
  );
};




const SparepartForm = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
          
            <FormContent/>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default SparepartForm;


