import ReportList from './ReportList';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const DashboardReportPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Dashboard" title="Approved Report" />
             <ReportList/>
            </Fragment>
        )
}

export default DashboardReportPage;