import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm,Controller } from 'react-hook-form';
import { Card, Button, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getActivities } from '../../../api/activity';
import { getMachines } from '../../../api/machine';
import { getPersons } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { createSchedule } from '../../../api/schedule';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../../CommonElements/Loading';
import { json, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Select from 'react-select';
import rfc3339 from '../../../_helper/Function/rfc3339';
import { getIntervals } from '../../../api/interval';
import { JsonFormCreatorWrapper } from '../../../Component/JsonForm';



const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Schedule succesfully created
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Fail to create new schedule
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
  name: yup.string().min(4, "name is required, min 4 character"),
  equipment: yup.object().required('equipment is required'),
  interval: yup.number().typeError('interval is required').moreThan(0, 'interval is required').required('interval is required'),
  startDate: yup.date().typeError('start date is required').required('start date is required')

}).required();

const FormContent = () => {
  const {profil, token} = useAuth()
  const { register, handleSubmit, control, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const [activities, setActivities] = useState([])
  const [currentActivity, setCurrentActivity] = useState('');
  const [machines, setMachines] = useState([])
  const [users, setUsers] = useState([])
  const [intervals, setIntervals] = useState([])
  const [loading, setLoading] = useState(false);
  const [jsonForm, setJsonForm] = useState({});
  const [approvals, setApprovals] = useState([])
  const [currentApproval, setCurrentApproval] = useState({value:-1, label:''})
  const navigate = useNavigate();

  const addActivity = ()=>{
    
    if(currentActivity.trim()==="")return;
    const finalActivities = activities;
    finalActivities.push(currentActivity.trim());
    setCurrentActivity('');
    setActivities(finalActivities);
  }

  const deleteActivity = (activity)=>{
    let arr = activities;
    arr = arr.filter(e => e !== activity); // will return ['A', 'C']
    setActivities(arr);
  }

  useEffect(()=>{
   

    getMachines(token).then((jsonRes)=>{
      let options = []
      for(let i=0;i<jsonRes.length;i++)
      {
        options.push({value: jsonRes[i].ID, label: jsonRes[i].name})
      }
      setMachines(options);
    }).catch(()=>{

    })

    getPersons(token).then((jsonRes)=>{
      let persons = [];
      for(let i=0;i<jsonRes.length;i++)
      {
          persons.push({value:jsonRes[i].id, label: jsonRes[i].username})
      }
      setUsers(persons);
    
    }).catch((error)=>{

    })

    getIntervals(token).then((jsonRes)=>{
      setIntervals(jsonRes);
    }).catch(()=>{
      
    })

  }, [])


  const onSubmit = (data) => { 
      if(jsonForm.hasOwnProperty("children")===false || jsonForm.children.length<1)return;
      if(approvals.length<1)return;
      setLoading(true);

      let uploadedApproval = []
      for(let i=0;i<approvals.length;i++)
      {
        uploadedApproval.push({"user_id":approvals[i].value})
      }
    
    
      const uploadedData = {
        name: data.name,
        equipment_id:data.equipment.value,
        interval_id: data.interval,
        start_date: rfc3339(new Date(data.startDate)),
        json_scheme: JSON.stringify(jsonForm),
        approvals: uploadedApproval
      }

      
      createSchedule(token, uploadedData).then(()=>{
        setSuccessModal(true);
        setLoading(false);
      }).catch(()=>{
        setFailureModal(true)
        setLoading(false);
      })
    
    
  };

  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const toggleSuccess = ()=>{navigate(`/masterdata/schedule`)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
      <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Schedule name</Label>
                <input  {...register("name")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.name?.message}</p>
                
              </Col>
          </Row>

         
          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Equipment</Label>
                <Controller
                    control={control}
                    name="equipment"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        name={name}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        inputRef={ref}
                        isSearchable={true}
                        options={machines}
                      />
                    )}
                  />
                  <p style={{color:'red'}}>{errors.equipment?.message}</p>
                    
              </Col>
          </Row>
    

          <Col md="12 mb-3">
            <Label htmlFor="interval">Time interval</Label>
                <select {...register('interval')} name="interval"   className="form-control digits">
                  <option disabled selected value> -- select time interval -- </option>
                  {
                    intervals.map((interval)=>{
                      return (<option value={interval.ID}>{interval.name}</option>)
                    })
                  }
                 
                
                </select>
                <p style={{color:'red'}}>{errors.interval?.message}</p>
            </Col>
          

          <Row>
          <Col md="12 mb-3">
            <Label htmlFor="startDate">Start date</Label>
                  <input {...register('startDate')}  className="form-control digits" type="date" />
                  <p style={{color:'red'}}>{errors.startDate?.message}</p>
            </Col>
          </Row>

          <Row>
          <Col md="12 mb-3" style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                 <JsonFormCreatorWrapper jsonForm={jsonForm} onUpdateJson={(newJsonForm)=>{setJsonForm(newJsonForm)}} formTitle="Schedule"/>
            </Col>
          </Row>

          <Row>
          <Col md="12 mb-3">
            <Label htmlFor="approval">Approval :</Label>
              {
                approvals.map((approval)=>{
                  return ( <div style={{display:'flex', flexDirection:'row', justifyContent:'stretch', marginBottom:'0.2em'}}>
                      <input  className="form-control digits" type='text' value={approval.label} disabled={true}/>
                      <Button  size='sm' style={{marginLeft:'0.2em'}}  color="danger" onClick={()=>{
                        let arr = approvals.filter(item => item.value !== approval.value)
                        setApprovals(arr)
                      }}>remove</Button>
                    </div>)
                })
              }
              <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                <Select 
                            
                            isSearchable={true}
                            options={users}
                            onChange={(value)=>{
                              setCurrentApproval(value)
                            }}
                          />
                <Button size='sm' style={{marginLeft:'0.2em'}} color="primary" onClick={()=>{
                    if(currentApproval.value===-1)return;
                    for(let i=0;i<approvals.length; i++)
                    {
                      if(approvals[i].value===currentApproval.value)return;
                    }
                    let arr = [...approvals]
                    arr.push(currentApproval)

                    setApprovals(arr)
                }}>add approval</Button>
              </div>
              {approvals.length<1?(<span style={{color:'red'}}>*approval is required</span>):('')}
            </Col>
          </Row>

        </CardBody>
        <CardFooter className="text-end">
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
        </CardFooter>
      </form>
      </Card>
      )
    }
    </Fragment>
    
  );
};




const ScheduleForm = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Schedule Form</H5>
              </CardHeader>
              <FormContent />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ScheduleForm;


