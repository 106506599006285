import { baseUrl, onTokenExpired } from "./apiConfig";

const getMachines = async (token) =>{
    return fetch(baseUrl+'/api/equipments',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
          return onTokenExpired().then((newToken)=>{
            return getMachines(newToken)
          })
        }

        

        throw new Error("error")
    })
}


const createMachine=async(token, machine)=>{
    return fetch(baseUrl+'/api/equipments',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(machine)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createMachine(newToken, machine)
        })
      }

      return response.json().then((resJson)=>{
        throw new Error(resJson.message)
      })
    })
}

const updateMachine=async(token, machine)=>{
  return fetch(baseUrl+'/api/equipments',{
      method: 'PUT',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(machine)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return updateMachine(newToken, machine)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}



const deleteEquipmentById = async(token, id)=>{
    return fetch(baseUrl+'/api/equipments/'+id,{
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    })
    .then((response) => {
      if(response.status===200)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return deleteEquipmentById(newToken, id)
        })
      }

      throw new Error("error")
    })
  }



  const getEquipmentById = async (token, equipmentId) =>{
    return fetch(baseUrl+'/api/equipments/'+equipmentId+"/detail",{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
          return onTokenExpired().then((newToken)=>{
            return getEquipmentById(newToken, equipmentId)
          })
        }

        

        throw new Error("error")
    })
}





export {getMachines, createMachine, deleteEquipmentById,  getEquipmentById, updateMachine};