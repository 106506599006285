import React, { Fragment } from 'react';
import { H4, H6, P, UL } from '../../../AbstractElements';
import { Image } from '../../../AbstractElements';
import logo from '../../../excelitas_logo1.png';

const FormHeader = (props) => {
    return (
        <Fragment>
            <div style={{marginBottom:'30px'}}>
            <div className="logo-wrapper">
                <Image style={{with:"100%"}} attrImage={{ className: 'img-fluid for-light', src: `${logo}`, alt: '' }} />
      
            </div>
                
            </div>
        </Fragment>
    );
};
export default FormHeader;