import TodoReportList from './ToDoReportList';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const DashboardTodoReportPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Dashboard" title="To Do Report" />
                <TodoReportList/>
            </Fragment>
        )
}

export default DashboardTodoReportPage;