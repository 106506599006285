import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react';
import differenceBy from 'lodash/differenceBy';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Button, Input, ButtonGroup } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import { useAuth } from '../../../AuthContext';
import { deleteEquipmentById, getMachines } from '../../../api/machine';
import { useNavigate } from "react-router-dom";
import { Oval } from  'react-loader-spinner'
import { deleteSparepartById, getSpareparts } from '../../../api/sparepart';


var rowDetailFunc = null

export const tableColumns = [
    {
        name: 'Name',
        selector: Row => Row['name'],
        sortable: true,
        center: true,
    },
    {
        name: 'Stock',
        selector: Row => Row['stock'],
        sortable: true,
        center: true,
    },
    {
        name: 'Safe Stock',
        selector: Row => Row['safe_stock'],
        sortable: true,
        center: true,
   },
    {
        name: 'Location',
        selector: Row => Row['location']['location_name'],
        sortable: true,
        center: true,
    },
    {
        name:"Rack",
        selector: Row => Row['sparepart_rack']['name'],
        sortable: true,
        center: true,
    },
    {
    name: 'Action',
    selector: Row => {
      return(
          

                <Button size="sm" color='primary' onClick={()=>{rowDetailFunc(Row['ID'])}}>detail</Button>
               
      )
    }
    ,
    sortable: true,
    center: true,
},
];




const SparepartDataTable = () => {
  const {token, profil} = useAuth();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data)
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterSparepartName, setFilterSparepartName] = useState("")
  const navigate = useNavigate();

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.name)}?`)) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, 'name'));
        toast.success('Successfully Deleted !');
      }
    };

    return <Btn attrBtn={{ color: 'danger', onClick: handleDelete }} key="delete">Delete</Btn>;
  }, [data, selectedRows, toggleCleared]);

  useEffect(()=>{
    getSpareparts(token).then((jsonRes)=>{
        setData(jsonRes);
        setFilteredData(jsonRes);
        setLoading(false);
    }).catch(()=>{

    })

  },[])


  rowDetailFunc = (id)=>{
    navigate(`/masterdata/sparepart/`+id)
  }
    
     
  

  return (
    <Fragment>
      <Container fluid={true} className="data-tables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
              Sparpart Name: <Input type='text' onChange={(e)=>{
                  setFilterSparepartName(e.target.value)
                  const filteredItems = data.filter(
                    item => item.name &&  item.name.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  setFilteredData(filteredItems)
                  
                }}></Input>
              </CardHeader>
              
              <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center', overflow:'auto'}}>
              {loading?(
              <Oval
                  height={100}
                  width={100}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loading}
                  ariaLabel='oval-loading'
                  secondaryColor="blue"
                  strokeWidth={2}
                  strokeWidthSecondary={2}

              />):(
                <DataTable
                  data={filteredData}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  pagination
                />
              )}
              </CardBody>
              <CardFooter>
              
              <Btn attrBtn={{ color: 'primary', onClick:()=>navigate(`/masterdata/sparepart/create`) }} >Add Sparepart</Btn>
            
              
            </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default SparepartDataTable;