import React, { Fragment } from 'react';
import EditUserForm from './EditUserForm';
import { Breadcrumbs } from '../../../AbstractElements';
import { useParams } from 'react-router';

const EditUserFormPage = () => {
    var {userId} = useParams()
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="edit user" />
      <EditUserForm userId={userId} />
    </Fragment>
  );
};
export default EditUserFormPage;