import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../AbstractElements';
import SparepartAlertDataTable from './SparepartAlertDataTable';

const SparepartAlertTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Home" title="Sparepart Stock Alert" />
      <SparepartAlertDataTable />
    </Fragment>
  );
};
export default SparepartAlertTablePage;