import { Btn, H5 } from '../../AbstractElements';
import {  Monthly, Submit } from '../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader , Card, Button, CardHeader, Table, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';





const jsonFormToHtmlString = (jsonForm)=>{
    if(jsonForm.hasOwnProperty("children")){
       let result = `<div> <ol style="flex-direction:'column'; overflow:'auto'">`
        
       let childrenrs = jsonForm.children.map((childJsonForm)=>{
                            let childrenResult = `<li>`
                            childrenResult += jsonFormToHtmlString(childJsonForm) + `</li>`;
                            return childrenResult;
                        })
        for(let i=0;i<childrenrs.length;i++)
        {
            result += childrenrs[i];
        }
        result += `</ol>`
        result += `</div>`
        return result;
    }

    if(jsonForm.hasOwnProperty("form_type")){
        if(jsonForm.form_type==="SHORT_ANSWER")
        {
            return `<label style="margin-right: '.5rem' ">`+jsonForm.question+`</label><input type='text' value='`+jsonForm.answer+`' ></input>`;
            
        }
        if(jsonForm.form_type==="MULTIPLE_CHOICE")
        {
           let result =`<label style="margin-right: '.5rem' ">`+jsonForm.question+`</label>`;
                 
                    
           let childrens = jsonForm.options.map((option, index)=>{
                            return `<input type="radio" id='`+option+`'name='`+option+`' value='`+index+`' ${jsonForm.choice===index?('checked'):('')}></input> <label style="margin-right: '.5rem'" for='`+option+`'>`+option+`</label><span></span>`
                        })
            
            for(let i=0;i<childrens.length;i++)
            {
                result += childrens[i]
            }

            return result;
            
                  
                
            
        }
        if(jsonForm.form_type==="CHECKBOX")
        {
           let result = `<label style="margin-right: '.5rem'">`+jsonForm.question+`</label>`
                 
           let childrens =  jsonForm.options.map((option)=>{
                            return  `<input type="checkbox" ${(jsonForm.choice.indexOf(option) > -1)?('checked'):('')} id='option' name='option' value='`+option+`'/> <label style="margin-right: '.5rem'" for='option'>`+option+`</label><span></span>`;
                        })
                    
            for(let i=0;i<childrens.length;i++)
            {
                result += childrens[i]
            }

            return result;
                  
            
        }

        if(jsonForm.form_type==="SHORT_ANSWER_TABLE")
        {
            let result = `
                   <label>`+jsonForm.question+`</label>
                   <table>
                    <thead>
                        <tr>
                            <th>Question</th>`;
            let headingChildrens = jsonForm.column.map((column)=>{
                                    return  `<th>`+column+`</th>`
                                })

            for(let i=0;i<headingChildrens.length;i++)
            {
                result += headingChildrens[i]
            }

            
            result +=`</tr></thead><tbody>`
            let childrensRow= jsonForm.questions.map((row)=>{
                            let resultsRowCol = jsonForm.column.map((column, cIndex)=>{
                                            return `<td>`+row.answers[cIndex]+`</td>`;
                                        })

                            let resultRow= `<tr><td style="text-align: left;">`+row.question+`</td>`
                            for(let i=0;i<resultsRowCol.length;i++){
                                resultRow+=resultsRowCol[i];
                            }
                            resultRow+=`</tr>`
                            return resultRow;
                        })

            for(let i=0;i<childrensRow.length;i++)
            {
                result+= childrensRow[i];
            }
            result +=`</tbody></table>`
            return result
                  
           
        }

        if(jsonForm.form_type==="MULTIPLE_CHOICE_TABLE")
        {
            let result =`<label>`+jsonForm.question+`</label><table><thead><tr><th>Question</th>`
            let headingChildrens=jsonForm.column.map((column)=>{
                                    return  `<th>`+column+`</th>`
                                })
            for(let i=0;i<headingChildrens.length;i++)
            {
                result+= headingChildrens[i];
            }
            result+=`</tr></thead><tbody>`
            let rowChildrens =jsonForm.questions.map((row)=>{
                            let resultsRowCols = jsonForm.column.map((column, cIndex)=>{
                                            return `<td><input type="radio" id='`+column+row.question+`' name='`+column+row.question+cIndex+`' value='`+cIndex+`' ${(row.choice===cIndex)?('checked'):('')}></input></td>`;
                                        })
                            let resultRow = `<tr><td>`+row.question+`</td>`

                            for(let i=0;i<resultsRowCols.length;i++)
                            {
                                resultRow+=resultsRowCols[i];
                            }
                            resultRow+=`</tr>`

                            return resultRow;
                        })
            for(let i=0;i<rowChildrens.length;i++)
            {
                result+=rowChildrens[i];
            }

            result+=`</tbody></table>`
                  
            
            return result;
        }

        
        if(jsonForm.form_type==="CHECKBOX_TABLE")
        {
            let result = `<table><thead><tr><th>Question</th>`
            
            let headingChildrens = jsonForm.column.map((column)=>{
                                    return  `<th>`+column+`</th>`
                                })
            for(let i=0;i<headingChildrens.length;i++)
            {
                result+=headingChildrens[i]
            }
            result+=`</tr></thead><tbody>`
            let rowChildren = jsonForm.questions.map((row)=>{
                            let resultRowCols = jsonForm.column.map((column, cIndex)=>{
                                            return `<td><input type="checkbox" ${(row.choice.indexOf(column) > -1)?('checked'):('')} id='`+column+`' name='`+column+`' value='`+column+`'/> </td>`;
                                        })

                            let resultRow= `<tr><td>`+row.question+`</td>`
                            for(let i=0;i<resultRowCols.length;i++)
                            {
                                resultRow+=resultRowCols[i];
                            }
                            resultRow+=`</tr>`
                            return resultRow;
                        })
            
            for(let i=0;i<rowChildren.length;i++)
            {
                result+=rowChildren[i]
            }
            result += `</tbody></table>`
            

            return result;
            
                  
            
            
        }
    }
}



const jsonFormToHtmlWithoutListString = (jsonForm)=>{
    if(jsonForm.hasOwnProperty("children")){
       let result = `<div style="flex-direction:column; overflow:auto ; border:1px solid black; align-items:stretch; padding:5px">`
       result+= `<style> table, th, td {
        text-align: center;
        border: 1px solid black;
        border-collapse: collapse;
      } </style>`
       let itemFormNumber=0;
       let childrenrs = jsonForm.children.map((childJsonForm)=>{
                            let childrenResult = `<div>`
                            itemFormNumber+=1
                            childrenResult +="<label>"+itemFormNumber+". </label>"
                            childrenResult += jsonFormToHtmlWithoutListString(childJsonForm) + `</div>`;
                            return childrenResult;
                        })
        for(let i=0;i<childrenrs.length;i++)
        {
            result +=childrenrs[i];
        }
        result += `</div>`
        return result;
    }

    if(jsonForm.hasOwnProperty("form_type")){
        if(jsonForm.form_type==="SHORT_ANSWER")
        {
            return `<label style="style="margin-right: 16px;">`+jsonForm.question+`</label><span>  : `+jsonForm.answer+`</span>`;
            
        }
        if(jsonForm.form_type==="MULTIPLE_CHOICE")
        {
           let result =`<label style="margin-right: 16px;">`+jsonForm.question+`</label>`;
                 
                    
           let childrens = jsonForm.options.map((option, index)=>{
                            return `<input style="margin-right: 16px;" type="radio" id='`+option+`'name='`+option+`' value='`+index+`' ${jsonForm.choice===index?('checked'):('')}></input> <label style="margin-right: 16px;" for='`+option+`'>`+option+`</label><span></span>`
                        })
            
            for(let i=0;i<childrens.length;i++)
            {
                result += childrens[i]
            }

            return result;
            
                  
                
            
        }
        if(jsonForm.form_type==="CHECKBOX")
        {
           let result = `<label style="margin-right: 16px;">`+jsonForm.question+`</label>`
                 
           let childrens =  jsonForm.options.map((option)=>{
                            return  `<input style="margin-right: 16px;" type="checkbox" ${(jsonForm.choice.indexOf(option) > -1)?('checked'):('')} id='option' name='option' value='`+option+`'/> <label style="margin-right: 16px;" for='option'>`+option+`</label><span></span>`;
                        })
                    
            for(let i=0;i<childrens.length;i++)
            {
                result += childrens[i]
            }

            return result;
                  
            
        }

        if(jsonForm.form_type==="SHORT_ANSWER_TABLE")
        {
            let result = `
                   
                   <label>`+jsonForm.question+`</label>
                   <table style="border: 1px solid black;">
                    <thead>
                        <tr style="border: 1px solid black;">
                            <th style="border: 1px solid black;">Question</th>`;
            let headingChildrens = jsonForm.column.map((column)=>{
                                    return  `<th style="border: 1px solid black;">`+column+`</th>`
                                })

            for(let i=0;i<headingChildrens.length;i++)
            {
                result += headingChildrens[i]
            }

            
            result +=`</tr></thead><tbody>`
            let childrensRow= jsonForm.questions.map((row)=>{
                            let resultsRowCol = jsonForm.column.map((column, cIndex)=>{
                                            return `<td style="border: 1px solid black;">`+row.answers[cIndex]+`</td>`;
                                        })

                            let resultRow= `<tr ><td style="text-align: left;">`+row.question+`</td>`
                            for(let i=0;i<resultsRowCol.length;i++){
                                resultRow+=resultsRowCol[i];
                            }
                            resultRow+=`</tr>`
                            return resultRow;
                        })

            for(let i=0;i<childrensRow.length;i++)
            {
                result+= childrensRow[i];
            }
            result +=`</tbody></table>`
            return result
                  
           
        }

        if(jsonForm.form_type==="MULTIPLE_CHOICE_TABLE")
        {
            let result =`<label>`+jsonForm.question+`</label><table style=" border: 1px solid black;border-collapse: collapse;"><thead><tr><th>Question</th>`
            let headingChildrens=jsonForm.column.map((column)=>{
                                    return  `<th>`+column+`</th>`
                                })
            for(let i=0;i<headingChildrens.length;i++)
            {
                result+= headingChildrens[i];
            }
            result+=`</tr></thead><tbody>`
            let rowChildrens =jsonForm.questions.map((row)=>{
                            let resultsRowCols = jsonForm.column.map((column, cIndex)=>{
                                            return `<td><input type="radio" id='`+column+row.question+`' name='`+column+row.question+cIndex+`' value='`+cIndex+`' ${(row.choice===cIndex)?('checked'):('')}></input></td>`;
                                        })
                            let resultRow = `<tr><td style="text-align: left;">`+row.question+`</td>`

                            for(let i=0;i<resultsRowCols.length;i++)
                            {
                                resultRow+=resultsRowCols[i];
                            }
                            resultRow+=`</tr>`

                            return resultRow;
                        })
            for(let i=0;i<rowChildrens.length;i++)
            {
                result+=rowChildrens[i];
            }

            result+=`</tbody></table>`
                  
            
            return result;
        }

        if(jsonForm.form_type==="CHECKBOX_TABLE")
        {
            let result = `<table style=" border: 1px solid black;border-collapse: collapse;"><thead><tr><th>Question</th>`
            
            let headingChildrens = jsonForm.column.map((column)=>{
                                    return  `<th>`+column+`</th>`
                                })
            
            for(let i=0;i<headingChildrens.length;i++)
            {
                result+=headingChildrens[i]
            }
            
            result+=`</tr></thead><tbody>`
            let rowChildren = jsonForm.questions.map((row)=>{
                            let resultRowCols = jsonForm.column.map((column, cIndex)=>{
                                            return `<td><input type="checkbox" ${(row.choice.indexOf(column) > -1)?('checked'):('')} id='`+column+`' name='`+column+`' value='`+column+`'/> </td>`;
                                        })

                            let resultRow= `<tr><td style="text-align: left;">`+row.question+`</td>`
                            for(let i=0;i<resultRowCols.length;i++)
                            {
                                resultRow+=resultRowCols[i];
                            }
                            resultRow+=`</tr>`
                            return resultRow;
                        })
            
            for(let i=0;i<rowChildren.length;i++)
            {
                result+=rowChildren[i]
            }
            
            result += `</tbody></table>`

            return "<label>"+jsonForm.question+"</label>"+result;
                  
            
            
        }
    }
}





const JsonForm = ({jsonForm, onUpdateJson})=>{
    
    if(jsonForm.hasOwnProperty("children")){
       return(
        <div>
        <h4>{jsonForm.title} Form</h4>
        <ol style={{flexDirection:'column', overflow:'auto'}}>
            {
                jsonForm.children.map((childJsonForm)=>{
                    return (<li><JsonForm jsonForm={childJsonForm} onUpdateJson={onUpdateJson}></JsonForm></li>)
                })
            }
        </ol>
        </div>
       )
    }

    if(jsonForm.hasOwnProperty("form_type")){
        if(jsonForm.form_type==="SHORT_ANSWER")
        {
            return (
                <><label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label><Input type='text' value={jsonForm.answer} onChange={(e)=>{jsonForm.answer=e.target.value; onUpdateJson(); }}></Input></>
            )
        }
        if(jsonForm.form_type==="MULTIPLE_CHOICE")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option, index)=>{
                            return  <><input type="radio" id={option+jsonForm.question} name={option+jsonForm.question} value={index} checked={jsonForm.choice===index} onChange={(e)=>{jsonForm.choice=index; onUpdateJson();}}></input> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }
        if(jsonForm.form_type==="CHECKBOX")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option)=>{
                            return  <><input type="checkbox" checked={jsonForm.choice.indexOf(option) > -1} id={option} name={option} value={option} onChange={(e)=>{
                                let arr = jsonForm.choice;
                                arr = arr.filter(el => el !== option);
                                if(e.target.checked)
                                {
                                    arr.push(option);
                                }
                                jsonForm.choice = arr;
                                onUpdateJson();
                            }}/> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }

        if(jsonForm.form_type==="SHORT_ANSWER_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input type='text' value={row.answers[cIndex]} onChange={(e)=>{row.answers[cIndex]=e.target.value; onUpdateJson()}}/></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="MULTIPLE_CHOICE_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input type="radio" id={column+row.question} name={column+row.question+cIndex} value={cIndex} checked={row.choice===cIndex} onChange={(e)=>{row.choice=cIndex; onUpdateJson()}}></input></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="CHECKBOX_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td> <input type="checkbox" checked={row.choice.indexOf(column) > -1} id={column} name={column} value={column} onChange={(e)=>{
                                                let arr = row.choice;
                                                arr = arr.filter(el => el !== column);
                                                if(e.target.checked)
                                                {
                                                    arr.push(column);
                                                }
                                                row.choice = arr;
                                                onUpdateJson();
                                            }}/> </td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }
    }
}



const JsonFormDisabled = ({jsonForm, onUpdateJson})=>{
    
    if(jsonForm.hasOwnProperty("children")){
       return(
        <div>
        <h4>{jsonForm.title} Form</h4>
        <ol style={{flexDirection:'column', overflow:'auto'}}>
            {
                jsonForm.children.map((childJsonForm)=>{
                    return (<li><JsonForm jsonForm={childJsonForm} onUpdateJson={onUpdateJson}></JsonForm></li>)
                })
            }
        </ol>
        </div>
       )
    }

    if(jsonForm.hasOwnProperty("form_type")){
        if(jsonForm.form_type==="SHORT_ANSWER")
        {
            return (
                <><label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label><Input disabled type='text' value={jsonForm.answer} onChange={(e)=>{jsonForm.answer=e.target.value; onUpdateJson(); }}></Input></>
            )
        }
        if(jsonForm.form_type==="MULTIPLE_CHOICE")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option, index)=>{
                            return  <><input disabled type="radio" id={option} name={option} value={index} checked={jsonForm.choice===index} onChange={(e)=>{jsonForm.choice=index; onUpdateJson();}}></input> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }
        if(jsonForm.form_type==="CHECKBOX")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option)=>{
                            return  <><input disabled type="checkbox" checked={jsonForm.choice.indexOf(option) > -1} id={option} name={option} value={option} onChange={(e)=>{
                                let arr = jsonForm.choice;
                                arr = arr.filter(el => el !== option);
                                if(e.target.checked)
                                {
                                    arr.push(option);
                                }
                                jsonForm.choice = arr;
                                onUpdateJson();
                            }}/> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }

        if(jsonForm.form_type==="SHORT_ANSWER_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input disabled type='text' value={row.answers[cIndex]} onChange={(e)=>{row.answers[cIndex]=e.target.value; onUpdateJson()}}/></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="MULTIPLE_CHOICE_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input disabled type="radio" id={column+row.question} name={column+row.question+cIndex} value={cIndex} checked={row.choice===cIndex} onChange={(e)=>{row.choice=cIndex; onUpdateJson()}}></input></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="CHECKBOX_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td> <input disabled type="checkbox" checked={row.choice.indexOf(column) > -1} id={column} name={column} value={column} onChange={(e)=>{
                                                let arr = row.choice;
                                                arr = arr.filter(el => el !== column);
                                                if(e.target.checked)
                                                {
                                                    arr.push(column);
                                                }
                                                row.choice = arr;
                                                onUpdateJson();
                                            }}/> </td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }
    }
}



const JsonShortAnswerFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
   

    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Short Answer Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>
                <Label>Form preview:</Label>
                <div style={{display:'flex', flexDirection:'row'}}><JsonForm onUpdateJson={()=>{}} jsonForm={ {form_type:"SHORT_ANSWER",
                                                                                            question:question,
                                                                                            answer:"",
                                                                                            required:true,}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push({form_type:"SHORT_ANSWER",
                                        question:question,
                                        answer:"",
                                        required:true,})
                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}



const JsonMultipleChoiceFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
    const [option, setOption] = useState([]);
    const [currentOption, setCurrentOption] =useState('');
   

   
    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Multiple Choice Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>

                <div>
                    <label  style={{ marginRight: '.5rem' }}>option :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentOption(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentOption.trim().length<1)return;
                        let options = [];
                        for(let i=0;i<option.length;i++)
                        {
                            options.push(option[i].toLowerCase().trim());
                        }
                        if(options.indexOf(currentOption.toLocaleLowerCase().trim())>-1)return;
                        options.push(currentOption.trim());
                        setOption(options);
                        
                    }}>add option</Button>
                </div>
                <Label>Form preview:</Label>
                <div><JsonForm onUpdateJson={()=>{}}  jsonForm={  {form_type:"MULTIPLE_CHOICE",
                                            question:question,
                                            options: option,
                                            choice:-1,
                                            required:true,}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push( {form_type:"MULTIPLE_CHOICE",
                                                    question:question,
                                                    options: option,
                                                    choice:-1,
                                                    required:true,})
                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}


const JsonCheckboxFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
    const [option, setOption] = useState([]);
    const [currentOption, setCurrentOption] = useState("");
  

    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Multiple Choice Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>

                <div>
                    <label  style={{ marginRight: '.5rem' }}>option :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentOption(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentOption.trim().length<1)return;
                        let options = [];
                        for(let i=0;i<option.length;i++)
                        {
                            options.push(option[i].toLowerCase().trim());
                        }
                        if(options.indexOf(currentOption.toLocaleLowerCase().trim())>-1)return;
                        options.push(currentOption.trim());
                        setOption(options);
                        
                    }}>add option</Button>
                </div>
                <Label>Form preview:</Label>
                <div><JsonForm onUpdateJson={()=>{}} jsonForm={ {form_type:"CHECKBOX",
                                            question:question,
                                            options: option,
                                            choice:[],
                                            required:true,}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push( {form_type:"CHECKBOX",
                                                    question:question,
                                                    options: option,
                                                    choice:[],
                                                    required:true,})
                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}



const JsonShortAnswerTableFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
    const [columns, setColumns] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [currentColumn, setCurrentColumn] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");
  
    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Short Answer Table Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>

                <div>
                    <label  style={{ marginRight: '.5rem' }}>column :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentColumn(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentColumn.trim().length<1)return;
                        let tcolumns = [];
                        for(let i=0;i<columns.length;i++)
                        {
                            tcolumns.push(columns[i].toLowerCase().trim());
                        }
                        if(tcolumns.indexOf(currentColumn.toLocaleLowerCase().trim())>-1)return;
                        tcolumns.push(currentColumn.trim());
                        setColumns(tcolumns);
                        
                    }}>add column</Button>
                </div>
                <div>
                    <label  style={{ marginRight: '.5rem' }}>question row :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentQuestion(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentQuestion.trim().length<1)return;
                        let tquestions = [];
                        for(let i=0;i<questions.length;i++)
                        {
                            tquestions.push(questions[i].toLowerCase().trim());
                        }
                        if(tquestions.indexOf(currentQuestion.toLocaleLowerCase().trim())>-1)return;
                        tquestions.push(currentQuestion.trim());
                        setQuestions(tquestions);
                        
                    }}>add row question</Button>
                </div>
                <Label>Form preview:</Label>
                <div style={{overflow:'auto'}}><JsonForm onUpdateJson={()=>{}} jsonForm={  {
                                            form_type:"SHORT_ANSWER_TABLE",
                                            column:columns,
                                            question:question,
                                            questions:questions.map((question)=>{
                                                let answers = [];
                                                for(let i=0;i<questions.length;i++)answers.push("");
                                                return  {
                                                    question:question,
                                                    answers:answers
                                                }
                                            })}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push( {
                                                form_type:"SHORT_ANSWER_TABLE",
                                                column:columns,
                                                question:question,
                                                questions:questions.map((question)=>{
                                                    let answers = [];
                                                    for(let i=0;i<questions.length;i++)answers.push("");
                                                    return  {
                                                        question:question,
                                                        answers:answers
                                                    }
                                                })});
                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}



const JsonMultipleChoiceTableFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
    const [columns, setColumns] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [currentColumn, setCurrentColumn] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");

    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Multiple Choice Table Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>

                <div>
                    <label  style={{ marginRight: '.5rem' }}>column :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentColumn(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentColumn.trim().length<1)return;
                        let tcolumns = [];
                        for(let i=0;i<columns.length;i++)
                        {
                            tcolumns.push(columns[i].toLowerCase().trim());
                        }
                        if(tcolumns.indexOf(currentColumn.toLocaleLowerCase().trim())>-1)return;
                        tcolumns.push(currentColumn.trim());
                        setColumns(tcolumns);
                        
                    }}>add column</Button>
                </div>
                <div>
                    <label  style={{ marginRight: '.5rem' }}>question row :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentQuestion(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentQuestion.trim().length<1)return;
                        let tquestions = [];
                        for(let i=0;i<questions.length;i++)
                        {
                            tquestions.push(questions[i].toLowerCase().trim());
                        }
                        if(tquestions.indexOf(currentQuestion.toLocaleLowerCase().trim())>-1)return;
                        tquestions.push(currentQuestion.trim());
                        setQuestions(tquestions);
                        
                    }}>add row question</Button>
                </div>
                <Label>Form preview:</Label>
                <div style={{overflow:'auto'}}><JsonForm onUpdateJson={()=>{}} jsonForm={  {
                                            form_type:"MULTIPLE_CHOICE_TABLE",
                                            column:columns,
                                            question:question,
                                            questions:questions.map((question)=>{
                                                let answers = [];
                                                for(let i=0;i<questions.length;i++)answers.push("");
                                                return  {
                                                    question:question,
                                                    choice:-1
                                                }
                                            })}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push( {
                                                form_type:"MULTIPLE_CHOICE_TABLE",
                                                column:columns,
                                                question:question,
                                                questions:questions.map((question)=>{
                                                    let answers = [];
                                                    for(let i=0;i<questions.length;i++)answers.push("");
                                                    return  {
                                                        question:question,
                                                        choice:-1
                                                    }
                                                })});
                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}

const JsonCheckboxTableFormCreatorDialog = (props)=>{
    const [question, setQuestion] = useState("");
    const [columns, setColumns] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [currentColumn, setCurrentColumn] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");

    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Multiple Choice Table Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>

                <div>Question: <input type='text' onChange={(e)=>{setQuestion(e.target.value)}}></input></div>

                <div>
                    <label  style={{ marginRight: '.5rem' }}>column :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentColumn(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentColumn.trim().length<1)return;
                        let tcolumns = [];
                        for(let i=0;i<columns.length;i++)
                        {
                            tcolumns.push(columns[i].toLowerCase().trim());
                        }
                        if(tcolumns.indexOf(currentColumn.toLocaleLowerCase().trim())>-1)return;
                        tcolumns.push(currentColumn.trim());
                        setColumns(tcolumns);
                        
                    }}>add column</Button>
                </div>
                <div>
                    <label  style={{ marginRight: '.5rem' }}>question row :</label>
                    <input  style={{ marginRight: '.5rem' }} type='text' onChange={(e)=>setCurrentQuestion(e.target.value)}></input>
                    <Button color='light' onClick={()=>{
                        if(currentQuestion.trim().length<1)return;
                        let tquestions = [];
                        for(let i=0;i<questions.length;i++)
                        {
                            tquestions.push(questions[i].toLowerCase().trim());
                        }
                        if(tquestions.indexOf(currentQuestion.toLocaleLowerCase().trim())>-1)return;
                        tquestions.push(currentQuestion.trim());
                        setQuestions(tquestions);
                        
                    }}>add row question</Button>
                </div>
                <Label>Form preview:</Label>
                <div style={{overflow:'auto'}}><JsonForm onUpdateJson={()=>{}} jsonForm={  {
                                            form_type:"CHECKBOX_TABLE",
                                            column:columns,
                                            question:question,
                                            questions:questions.map((question)=>{
                                                let answers = [];
                                                for(let i=0;i<questions.length;i++)answers.push("");
                                                return  {
                                                    question:question,
                                                    choice:[]
                                                }
                                            })}}
                    ></JsonForm></div>
                
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'success', onClick: ()=>{
                    if(question.trim().length<1)return;
                    props.jsonForm.children.push( {
                                                form_type:"CHECKBOX_TABLE",
                                                column:columns,
                                                question:question,
                                                questions:questions.map((question)=>{
                                                    let answers = [];
                                                    for(let i=0;i<questions.length;i++)answers.push("");
                                                    return  {
                                                        question:question,
                                                        choice:[]
                                                    }
                                                })});

                    props.onDismis();
                    props.onUpdateJson();
                } }} >
            Add
            </Btn>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
 

}


const JsonFormCreatorSelectionDialog = (props)=>{
    return (
        <Modal isOpen={props.isOpen}  size={props.size} centered>
          <ModalHeader >
            Add Form Item
          </ModalHeader>
          <ModalBody style={{display:'flex', flexDirection:'column'}}>
                <Button color='light' onClick={()=>{props.setSelection("SHORT_ANSWER")}}>short answer</Button>
                <Button color='light' onClick={()=>{props.setSelection("MULTIPLE_CHOICE")}}>multiple choice</Button>
                <Button color='light' onClick={()=>{props.setSelection("CHECKBOX")}}>checkbox</Button>
                <Button color='light' onClick={()=>{props.setSelection("SHORT_ANSWER_TABLE")}}>short answer table</Button>
                <Button color='light' onClick={()=>{props.setSelection("MULTIPLE_CHOICE_TABLE")}}>multiple choice table</Button>
                <Button color='light' onClick={()=>{props.setSelection("CHECKBOX_TABLE")}}>checkbox table</Button>               
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{ color: 'primary', onClick: props.onDismis }} >Close</Btn>
          </ModalFooter>
        </Modal>
      );
};



const JsonFormCreatorDialog = (props) => {
    const [selection, setSelection] = useState("SELECTION");

    const onDismis =()=>{
        props.toggler();
        setSelection("SELECTION");
    }

    var resComponent =<></>
    if(selection==="SELECTION"){
        resComponent = <JsonFormCreatorSelectionDialog {...props}  onDismis = {onDismis} setSelection = {(choice)=>setSelection(choice)}/>
    }else if(selection==="SHORT_ANSWER"){
        resComponent = <JsonShortAnswerFormCreatorDialog {...props} onDismis = {onDismis}></JsonShortAnswerFormCreatorDialog>
    }else if(selection==="MULTIPLE_CHOICE")
    {
        resComponent = <JsonMultipleChoiceFormCreatorDialog  {...props} onDismis = {onDismis}></JsonMultipleChoiceFormCreatorDialog>
    }else if(selection==="SHORT_ANSWER_TABLE")
    {
        resComponent = <JsonShortAnswerTableFormCreatorDialog  {...props} onDismis = {onDismis}></JsonShortAnswerTableFormCreatorDialog>
    }else if(selection==="MULTIPLE_CHOICE_TABLE")
    {
        resComponent = <JsonMultipleChoiceTableFormCreatorDialog  {...props} onDismis = {onDismis}></JsonMultipleChoiceTableFormCreatorDialog>
    }else if(selection==="CHECKBOX")
    {
        resComponent = <JsonCheckboxFormCreatorDialog  {...props} onDismis = {onDismis}></JsonCheckboxFormCreatorDialog>
    }else if(selection==="CHECKBOX_TABLE")
    {
        resComponent = <JsonCheckboxTableFormCreatorDialog  {...props} onDismis = {onDismis}></JsonCheckboxTableFormCreatorDialog>
    }

    return resComponent;
   
  };

const JsonFormCreator = ({jsonForm, onUpdateJson})=>{

    const [dialogVisible, setDialogVisible] = useState(false);


   
    
    if(jsonForm.hasOwnProperty("children")){
       return(
        <div>
            <JsonFormCreatorDialog isOpen={dialogVisible} onUpdateJson={onUpdateJson} toggler={()=>{setDialogVisible(!dialogVisible)}} jsonForm={jsonForm}></JsonFormCreatorDialog>
        <h2>Form {jsonForm.title}</h2>
        <ol style={{flexDirection:'column', overflow:'auto'}}>
            {
                jsonForm.children.map((childJsonForm, index)=>{
                    return (<li><JsonFormCreator jsonForm={childJsonForm}></JsonFormCreator><Button size="sm" style={{ marginLeft: '.5rem' }} color='danger' onClick={()=>{delete jsonForm.children.splice(index, 1); onUpdateJson()}}>remove</Button></li>)
                })
            }
        </ol>
        <Button color='primary' onClick={()=>{
            setDialogVisible(true);
        }}>Add Form Item</Button>
        </div>
       )
    }

    if(jsonForm.hasOwnProperty("form_type")){
        if(jsonForm.form_type==="SHORT_ANSWER")
        {
            return (
                <><label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label><input className="form-control" style={{ margin: '.5rem' }} type='text' ></input></>
            )
        }
        if(jsonForm.form_type==="MULTIPLE_CHOICE")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option, index)=>{
                            return  <><input type="radio" id={option} name={option} value={index} checked={jsonForm.choice===index} ></input> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }
        if(jsonForm.form_type==="CHECKBOX")
        {
            return (
                <>
                    <label style={{ marginRight: '.5rem' }}>{jsonForm.question}</label>
                 
                    {
                        jsonForm.options.map((option)=>{
                            return  <><input type="checkbox" checked={jsonForm.choice.indexOf(option) > -1} id={option} name={option} value={option} /> <label style={{ marginRight: '.5rem' }} for={option}>{option}</label><span></span></>
                        })
                    }
                  
                </>
            )
        }

        if(jsonForm.form_type==="SHORT_ANSWER_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input className="form-control" type='text'/></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="MULTIPLE_CHOICE_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td><input type="radio" id={column+row.question} name={column+row.question+cIndex} value={cIndex} checked={row.choice===cIndex} ></input></td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }

        if(jsonForm.form_type==="CHECKBOX_TABLE")
        {
            return (
                <>
                   <Label>{jsonForm.question}</Label>
                   <Table borderless>
                    <thead>
                        <tr>
                            <th>Question</th>
                            {
                                jsonForm.column.map((column)=>{
                                    return  <th>{column}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {jsonForm.questions.map((row)=>{
                            let results = jsonForm.column.map((column, cIndex)=>{
                                            return <td> <input type="checkbox" checked={row.choice.indexOf(column) > -1} id={column} name={column} value={column}/> </td>;
                                        })

                            return <tr><td>{row.question}</td>{results}</tr>
                        })}
                    </tbody>


                   </Table>
                  
                </>
            )
        }
    }
}

const FormContent = () => {
    const [formJson, setFormJson]= useState({
        title: "test title",
        topForm:true,
        children:[
            {
                form_type:"SHORT_ANSWER",
                question:"testt shor answer 1",
                answer:"",
                required:true,
            },
            {
                form_type:"MULTIPLE_CHOICE",
                question:"apakah aman ?",
                options:["yes", "no"],
                choice:-1,
                required:true
            },
            {
                form_type:"CHECKBOX",
                question:"apakah aman ?",
                options:["yes", "no", "tes"],
                choice:[],
                required:true
            },
            {
                form_type:"SHORT_ANSWER_TABLE",
                column:["atas", "bawah", "pinggir"],
                question:"cek mesin rumah",
                questions:[
                    {
                        question:"mesin cuci",
                        answers:["","",""]
                    },
                    {
                        question:"mesin gilin",
                        answers:["","",""]
                    }
                ]
            },
            {
                form_type:"MULTIPLE_CHOICE_TABLE",
                column:["atas", "bawah", "pinggir"],
                question:"cek mesin rumah",
                questions:[
                    {
                        question:"mesin cuci",
                        choice:-1
                    },
                    {
                        question:"mesin gilin",
                        choice:-1
                    }
                ]
            },
            {
                form_type:"CHECKBOX_TABLE",
                column:["atas", "bawah", "pinggir"],
                 questions:[
                                {
                                    question:"mesin cuci",
                                    choice:[]
                                },
                                {
                                    question:"mesin gilin",
                                    choice:[]
                                }
                            ]
                        }

            
        ]
    })

  

  return (
    <Fragment>
     
        <CardBody>
        <Row>
           <Col md="12 mb-3">
           <div className='border' style={{padding:'.5rem'}}> 
                <JsonFormCreator jsonForm={formJson} ></JsonFormCreator>
            </div>
            </Col>
          </Row>
        </CardBody>
       
 
    </Fragment>
  );
};


const JsonFormCreatorWrapper = ({jsonForm, formTitle, onUpdateJson})=>{
    var newJsonForm = JSON.parse(JSON.stringify(jsonForm))
  
    if(newJsonForm.hasOwnProperty("children")===false)
    {
        newJsonForm.topForm = true;
        newJsonForm.title = formTitle;
        newJsonForm.children = [];
    }
    

    return  <div className='border' style={{padding:'.5rem'}}> 
     <JsonFormCreator jsonForm={newJsonForm} onUpdateJson={()=>{onUpdateJson(newJsonForm)}}/></div>

    

}


const JsonFormWrapper = ({jsonForm, formTitle, onUpdateJson})=>{
    var newJsonForm = JSON.parse(JSON.stringify(jsonForm))
  
    if(newJsonForm.hasOwnProperty("children")===false)
    {
        newJsonForm.topForm = true;
        newJsonForm.title = formTitle;
        newJsonForm.children = [];
       
    }
    

    return  <div className='border' style={{padding:'.5rem', borderRadius:'.5rem'}}> 
     <JsonForm jsonForm={newJsonForm} onUpdateJson={()=>{onUpdateJson(newJsonForm)}}/></div>

    

}


const JsonFormDisabledWrapper = ({jsonForm, formTitle, onUpdateJson})=>{
    var newJsonForm = JSON.parse(JSON.stringify(jsonForm))
  
    if(newJsonForm.hasOwnProperty("children")===false)
    {
        newJsonForm.topForm = true;
        newJsonForm.title = formTitle;
        newJsonForm.children = [];
        
    }
    

    return  <div className='border' style={{padding:'.5rem', borderRadius:'.5rem'}}> 
     <JsonFormDisabled jsonForm={newJsonForm} onUpdateJson={()=>{}}/></div>

    

}




export {JsonFormCreatorWrapper, JsonFormWrapper, JsonFormDisabledWrapper, jsonFormToHtmlString, jsonFormToHtmlWithoutListString};


