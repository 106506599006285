import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import LocationsDataTable from './LocationTable';

const LocationsTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="Locations Table" />
      <LocationsDataTable />
    </Fragment>
  );
};
export default LocationsTablePage;