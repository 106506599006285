import { Btn, H5 } from '../../../AbstractElements';
import {  Monthly, Submit } from '../../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Button } from 'reactstrap';
import { createUser, getPersons } from '../../../api/user';
import { useAuth } from '../../../AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import Loading from '../../../CommonElements/Loading';
import { getLocations } from '../../../api/location';
import { createMachine, deleteEquipmentById, getEquipmentById, updateMachine } from '../../../api/machine';
import Select from 'react-select';




const SuccesModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Success
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        Machine succesfully updated
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};

const FailureModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        Error
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.message}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};



const schema = yup.object().shape({
  name: yup.string().min(4, "name is required, minimum 4 character").required("username is required, minimum 4 character"),
  pic: yup.object().required('person in charge is required'),
}).required();

const FormContent = ({equipmentId}) => {
  const {profil, token} = useAuth()
  const { register, handleSubmit,control, reset, formState: { errors } } = useForm({ resolver: yupResolver(schema),});
  const navigate = useNavigate();
  const [locations, setLocatios] = useState([])
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([])
  const [lastEquipmentState, setLastEquipmentState] = useState(null)
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalError, setFailureModalError] = useState("Fail to create new machine")


  useEffect(()=>{
    getPersons(token).then((jsonRes)=>{
        let persons = [];
        for(let i=0;i<jsonRes.length;i++)
        {
            persons.push({value:jsonRes[i].id, label: jsonRes[i].username})
        }
        setUsers(persons);

        getEquipmentById(token, equipmentId).then((equipmentRes)=>{
            
            reset({
                name:equipmentRes.name,
                serial_number: equipmentRes.serial_number,
                part_number: equipmentRes.part_number,
              
            })
            setLastEquipmentState(equipmentRes)
            setLoading(false)
    
        })
    })

    
  

  }, [])


  const onSubmit = (data) => { 
    setLoading(true);
    updateMachine(token, {
        "ID":parseInt(equipmentId),
        "name":data.name,
        "part_number":data.part_number,
        "serial_number":data.serial_number,
        "pic": data.pic.value
        
    
    }).then(()=>{
        setLoading(false);
        setSuccessModal(true);
    }).catch((error)=>{
        setFailureModalError(error.message);
        setLoading(false);
        setFailureModal(true);
    })


    
    
  };

 
  const toggleSuccess = ()=>{
    navigate(`/masterdata/equipment`)
    setLoading(false);
}
  const toggleFailure = ()=>{
    setFailureModal(!failureModal)
    setLoading(false);
}

  

  return (
    <Fragment>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalError} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
      {loading?(
      <Card style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <CardBody>
          <Loading loading={loading}/>
        </CardBody>
      </Card>
      ):(
      <Card>
          <CardHeader className="pb-0">
                <H5>Edit Equipment Form</H5>
              </CardHeader>
      <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <Label htmlFor="name">Name</Label>
                <input  {...register("name")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.name?.message}</p>
                
              </Col>
          </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="part_number">Part Number</Label>
                <input  {...register("part_number")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.part_number?.message}</p>
                
              </Col>
          </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="name">Serial Number</Label>
                <input  {...register("serial_number")} type="text" className="form-control digits"/>
                <p style={{color:'red'}}>{errors.serial_number?.message}</p>
                
              </Col>
          </Row>

          <Row>
           <Col md="12 mb-3">
            <Label htmlFor="pic">Person In Charge</Label>
                <Controller
                    control={control}
                    name="pic"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        name={name}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        inputRef={ref}
                        isSearchable={true}
                        options={users}
                      />
                    )}
                  />
                  <p style={{color:'red'}}>{errors.pic?.message}</p>
                    
              </Col>
          </Row>
        

        </CardBody>
        <CardFooter className="text-end">
          {profil.role==="admin"?(
             <Button style={{marginRight:'0.5em'}} color='danger' onClick={()=>{
              setLoading(true)
              deleteEquipmentById(token, equipmentId).then(()=>{
                navigate(`/masterdata/equipment`)
                setLoading(false);
              }).catch((error)=>{
                setLoading(false)
                setFailureModalError(error.message)
                setFailureModal(true)
                
              })
            }}>Delete</Button>
          ):('')}
         
          <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2' }}>{Submit}</Btn>
        </CardFooter>
      </form>
      </Card>)}
    </Fragment>
  );
};




const EditMachineForm = ({equipmentId}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
           <FormContent equipmentId={equipmentId}/>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default EditMachineForm;


