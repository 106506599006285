import ScheduleForm from './ScheduleForm';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const ScheduleFormPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Master Data" title="Schedule Form" />
             <ScheduleForm/>
            </Fragment>
        )
}

export default ScheduleFormPage;