import { baseUrl, onTokenExpired } from "./apiConfig";

const getSpareparts = async (token) =>{
    return fetch(baseUrl+'/api/spareparts',{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }

        if(response.status===401){
          return onTokenExpired().then((newToken)=>{
            return getSpareparts(newToken)
          })
        }

        throw new Error("error")
    })
}


const createSparepart=async(token, sparepart)=>{
    return fetch(baseUrl+'/api/spareparts',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(sparepart)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createSparepart(newToken, sparepart)
        })
      }

      return response.json().then((jsonRes)=>{
        throw new Error(jsonRes.message)
      })
    })
}


const updateSparepart=async(token, sparepart)=>{
  return fetch(baseUrl+'/api/spareparts',{
      method: 'PUT',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(sparepart)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return updateSparepart(newToken, sparepart)
      })
    }

    return response.json().then((jsonRes)=>{
      throw new Error(jsonRes.message)
    })
  })
}


const deleteSparepartById = async(token, id)=>{
    return fetch(baseUrl+'/api/spareparts/'+id,{
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    })
    .then((response) => {
      if(response.status!=200)
      {
        throw "upload error";
      }
      return response.json()
    })
  }

  
const getSparepartById = async(token, id)=>{
    return fetch(baseUrl+'/api/spareparts/'+id,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
          }
    })
    .then((response) => {
      if(response.status===200)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getSparepartById(newToken, id)
        })
      }
     
      throw new Error("error")
    })
  }

const createSparepartIn = async(token, sparepartIn)=>{
    return fetch(baseUrl+'/api/sparepart-in',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(sparepartIn)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createSparepartIn(newToken, sparepartIn)
        })
      }

      throw new Error("error");
    })
}


const createSparepartOut = async(token, sparepartOut)=>{
    return fetch(baseUrl+'/api/sparepart-out',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(sparepartOut)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createSparepartOut(newToken, sparepartOut)
        })
      }

      return response.json().then((jsonResponse)=>{
        throw new Error(jsonResponse.message)
      })
    })
}


const getNumberOfSparepartReachSafetyStock = async(token)=>{
  return fetch(baseUrl+'/api/spareparts/number-sparepart-reach-safe-stock',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token,
        }
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }

    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getNumberOfSparepartReachSafetyStock(newToken)
      })
    }
   
    throw new Error("error")
  })
}

const getSparepartsReachSafeStock = async (token) =>{
  return fetch(baseUrl+'/api/spareparts/reach-safe-stock',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getSparepartsReachSafeStock(newToken)
        })
      }

      throw new Error("error")
  })
}







export {getSparepartsReachSafeStock, getNumberOfSparepartReachSafetyStock, getSpareparts, createSparepart, deleteSparepartById, getSparepartById, createSparepartIn, createSparepartOut, updateSparepart};