var baseUrl = ""

if (process.env.NODE_ENV !== 'production') {
    baseUrl = "http://localhost:3000"
}

var onTokenExpired = async ()=>{

}

const setApiOnTokenExpired  = (onTokenExpiredFunc)=>{
    onTokenExpired = onTokenExpiredFunc;
}

export {baseUrl, onTokenExpired, setApiOnTokenExpired}