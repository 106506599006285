import { baseUrl, onTokenExpired } from "./apiConfig";

const getReportByScheduleIdAndSequenceNumber = async (token, scheduleId, sequenceId) =>{
    return fetch(baseUrl+'/api/reports/schedules/'+scheduleId+"/sequence/"+sequenceId,{
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
          }
    }).then((response)=>{
        if(response.status===200){
            return response.json();
        }
        
        if(response.status===401){
          return onTokenExpired().then((newToken)=>{
            return getReportByScheduleIdAndSequenceNumber(newToken, scheduleId, sequenceId)
          })
        }

        return response.json().then((jsonres)=>{

            throw new Error(jsonres.message);
        })
    })


}

const createReport = async (token, report)=>{
    return fetch(baseUrl+'/api/reports',{
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(report)
    })
    .then((response) => {
      if(response.status===201)
      {
        return response.json()
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return createReport(newToken, report)
        })
      }

      return response.json().then((resJson)=>{
        throw new Error(resJson.message)
      })
    })
}

const updateReport = async (token, report)=>{
    return fetch(baseUrl+'/api/reports',{
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(report)
    })
    .then((response) => {
      if(response.status===200)
      {
        return response.json();
      }

      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return updateReport(newToken, report)
        })
      }

      return response.json().then((resJson)=>{
        throw new Error(resJson.message)
      })

      
    })
}


const doneReport = async (token, report)=>{
  return fetch(baseUrl+'/api/reports/done',{
      method: 'post',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(report)
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return doneReport(newToken, report)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })

    
  })
}


const checkStatusBatchOfReport = async (token, reports)=>{
  return fetch(baseUrl+'/api/reports/check-status-batch-of-report/',{
    method: 'POST',
    headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(reports)
})
.then((response) => {
  if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return checkStatusBatchOfReport(newToken, reports)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
})
}


const getReportMaterialsByScheduleIdAndSequenceNumber = async (token, scheduleId, sequenceId) =>{
  return fetch(baseUrl+'/api/report-material/schedules/'+scheduleId+"/sequence/"+sequenceId,{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getReportMaterialsByScheduleIdAndSequenceNumber(newToken, scheduleId, sequenceId)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })


}

const createReportMaterial = async (token, reportMaterial)=>{
  return fetch(baseUrl+'/api/report-material',{
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(reportMaterial)
  })
  .then((response) => {
    if(response.status===201)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return createReportMaterial(newToken, reportMaterial)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}



const deleteReportMaterialById = async(token, id)=>{
  return fetch(baseUrl+'/api/report-material/'+id,{
      method: 'DELETE',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return deleteReportMaterialById(newToken, id)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}


const getUserRejectedReport = async (token) =>{
  return fetch(baseUrl+'/api/reports/rejected',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getUserRejectedReport(newToken)
        })
      }

      return response.json().then((jsonres)=>{

          throw new Error(jsonres.message);
      })
  })


}

const getNumberOfUserRejectedReport = async (token) =>{
  return fetch(baseUrl+'/api/reports/number-of-rejected-report',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getUserRejectedReport(newToken)
        })
      }

      return response.json().then((jsonres)=>{

          throw new Error(jsonres.message);
      })
  })


}

const getTodoReport = async (token) =>{
  return fetch(baseUrl+'/api/reports/to-do',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getTodoReport(newToken)
        })
      }

      return response.json().then((jsonres)=>{

          throw new Error(jsonres.message);
      })
  })


}


const getNumberOfTodoReport = async (token) =>{
  return fetch(baseUrl+'/api/reports/number-of-to-do',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getTodoReport(newToken)
        })
      }

      return response.json().then((jsonres)=>{

          throw new Error(jsonres.message);
      })
  })


}

const getApprovedReport = async (token) =>{
  return fetch(baseUrl+'/api/reports/approved',{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
      if(response.status===200){
          return response.json();
      }
      
      if(response.status===401){
        return onTokenExpired().then((newToken)=>{
          return getApprovedReport(newToken)
        })
      }

      return response.json().then((jsonres)=>{

          throw new Error(jsonres.message);
      })
  })


}


const uploadAttachmentFile = async (token, file)=>{
  const formData = new FormData();

  formData.append('file', file);

  return fetch(baseUrl+'/api/reports/file/',{
      method: 'POST',
      body: formData,
      headers: {
          'Authorization': 'Bearer ' + token,

        },
   
  })
  .then((response) => {
    if(response.status===201)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return uploadAttachmentFile(newToken, file)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}

const createReportAttachment =async (token, reportAttachment)=>{
    return fetch(baseUrl+'/api/reports/attachments/',{
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      body: JSON.stringify(reportAttachment)
  })
  .then((response) => {
    if(response.status===201)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return createReportAttachment(newToken, reportAttachment)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}



const getReportAttachmentsByScheduleIdAndSequenceNumber = async (token, scheduleId, sequenceId) =>{
  return fetch(baseUrl+'/api/reports/attachments/schedules/'+scheduleId+"/sequence/"+sequenceId,{
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + token
        }
  }).then((response)=>{
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getReportMaterialsByScheduleIdAndSequenceNumber(newToken, scheduleId, sequenceId)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })


}

const getDoneReportsByEquipmentId = (token, id)=>{
  return fetch(baseUrl+'/api/reports/equipments/'+id,{
    method: 'GET',
    headers: {
        'Authorization': 'Bearer ' + token
      }
 }).then((response)=>{
    if(response.status===200){
        return response.json();
    }
    
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return getDoneReportsByEquipmentId(newToken, id)
      })
    }

    return response.json().then((jsonres)=>{

        throw new Error(jsonres.message);
    })
})

}



const deleteReportAttachmentById = async(token, id)=>{
  return fetch(baseUrl+'/api/reports/attachments/'+id,{
      method: 'DELETE',
      headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  })
  .then((response) => {
    if(response.status===200)
    {
      return response.json()
    }
    if(response.status===401){
      return onTokenExpired().then((newToken)=>{
        return deleteReportAttachmentById(newToken, id)
      })
    }

    return response.json().then((resJson)=>{
      throw new Error(resJson.message)
    })
  })
}







export {getReportByScheduleIdAndSequenceNumber, 
        createReport, 
        createReportMaterial, 
        updateReport, 
        checkStatusBatchOfReport, 
        getReportMaterialsByScheduleIdAndSequenceNumber, 
        deleteReportMaterialById,
        doneReport,
        getUserRejectedReport,
        getNumberOfUserRejectedReport,
        getTodoReport,
        getApprovedReport,
        getNumberOfTodoReport,
        uploadAttachmentFile,
        createReportAttachment,
        deleteReportAttachmentById,
        getReportAttachmentsByScheduleIdAndSequenceNumber,
        getDoneReportsByEquipmentId
        

      };