import React, { Fragment, useEffect, useState } from 'react';
import { Card, Button, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { useNavigate } from  "react-router-dom";
import { useAuth } from '../../../AuthContext';
import { getApprovals } from '../../../api/approval';
import Loading from '../../../CommonElements/Loading';





const ApprovalListContent = () => {
  const {token, profil} = useAuth()
  const [approvals, setApprovals] = useState([])
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    
    getApprovals(token).then((resApprovals)=>{
      setApprovals(resApprovals)
      setLoading(false)
    })
    
  }, [])

  

  return (
    <Fragment>
      {loading?(
        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <Loading loading={loading}/>
        </div>
           
       ):(
        approvals.map((approval)=>{
         
          return(
                <Row>
                  <Col md="12 mb-3">
                    <Card>
                      <CardHeader><h3>{approval.equipment_name}</h3></CardHeader>
                      <CardBody>
                        <Table style={{alignSelf:'flex-start'}} borderless>
                        <tr>
                            <td>Schedule Name</td><td>{approval.schedule_name}</td>
                          </tr>
                          <tr>
                            <td>Serial Number</td><td>{approval.equipment_serial_number}</td>
                          </tr>
                          <tr>
                            <td>Part Number</td><td>{approval.equipment_part_number}</td>
                          </tr>
                        </Table>
              
                      </CardBody>
                      <CardFooter style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                        <Button color='primary' onClick={()=>{
                          navigate("/approval/schedule/"+approval.schedule_id+"/"+approval.schedule_sequence_id)
                        }}>show report</Button>
                      </CardFooter>
                    </Card>

                  </Col>
                </Row>
          )

        })
      )}
       
    </Fragment>
    
  );
};




const ApprovalList = () => {
  return (
    <Fragment>
      <Container fluid={true}>
      
              <ApprovalListContent />
           
      </Container>
    </Fragment>
  );
};
export default ApprovalList;


