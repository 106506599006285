import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import SparepartDataTable from './SparepartDataTable';

const SparepartTablePage = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Master Data" title="Sparepart Table" />
      <SparepartDataTable />
    </Fragment>
  );
};
export default SparepartTablePage;