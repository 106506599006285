import { Btn, H5 } from '../../AbstractElements';
import {  Monthly, Submit } from '../../Constant';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader , Card, Button, CardHeader, Table, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { uploadAttachmentFile } from '../../api/report';
import { useAuth } from '../../AuthContext';




const TestFileContent = () => {
    const {token} = useAuth()

    const [file, setFile] = useState(null)
   

  

  return (
    <Fragment>
     
        <CardBody>
        <Row>
           <Col md="12 mb-3">
            <input type="file"  onChange={(e)=>{
                setFile(e.target.files[0])
            }}/>

            <Button onClick={()=>{

                uploadAttachmentFile(token, file).then((uploadRes)=>{
                    alert(uploadRes.file_name)
                }).catch((error)=>{
                    alert(error)
                })
            }}>upload</Button>
            </Col>
          </Row>
        </CardBody>
       
 
    </Fragment>
  );
};





const TestFile = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>User Form</H5>
              </CardHeader>
              <TestFileContent />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TestFile;