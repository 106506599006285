import ApprovalList from './ApprovalList';
import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';


const DashboardApprovalPage=()=>{

        return(
            <Fragment>
            <Breadcrumbs parent="Dashboard" title="Approvals" />
             <ApprovalList/>
            </Fragment>
        )
}

export default DashboardApprovalPage;