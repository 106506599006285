import { Btn, H5 } from '../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardHeader, Container, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row, Button,  ListGroup, ListGroupItem, Table } from 'reactstrap';
import { useAuth } from '../../AuthContext';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../CommonElements/Loading';
import { createReport, createReportMaterial, deleteReportMaterialById, doneReport, getReportByScheduleIdAndSequenceNumber, getReportMaterialsByScheduleIdAndSequenceNumber, updateReport } from '../../api/report';
import { getScheduleById } from '../../api/schedule';
import { JsonFormCreatorWrapper, JsonFormWrapper, JsonFormDisabledWrapper } from '../../Component/JsonForm';
import { getSpareparts } from '../../api/sparepart';
import { DownloadReportPdfButton } from '../../Component/Report PDF';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import { baseUrl } from '../../api/apiConfig';


const AddMaterialModal = (props) => {
  const [stock, setStock] = useState('')
  const [sparepart, setSparepart] = useState(-1)
  const [stockError, setStockError] = useState('value must be greater than 0')
  const [sparepartError, setSparepartError] = useState('sparepart is required')
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Add Material
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
        <Label>Sparepart :</Label>
          <Select
                        value={sparepart}
                        isSearchable={true}
                        options={props.spareparts}
                        onChange={(selectedOption)=>{
                          setSparepart(selectedOption)
                          if(selectedOption.value>=1)setSparepartError("")
                          else setSparepartError("sparepart is required");
                        }}
          />
           <span style={{color:"red"}}>{sparepartError}</span><div style={{marginBottom:'0.5em'}}></div>
          <Label>Count :</Label>
          <input className="form-control digits" value={stock}   pattern="^[-/d]/d*$" type='text' onChange={(e)=>{
          const re =/^[0-9\b]+$/;

          // if value is not blank, then test the regex
          if(e.target.value.trim()==='')
          {
            setStock('')
            setStockError("value must be greater than 0")
            return
          }
      
          if (re.test(e.target.value)) {
            let val = parseInt(e.target.value.trim())
            setStock(val)
            if(val<1){
              setStockError("value must be greater than 0")
            }else{
              setStockError("")
            }
            
          }
        }}></input>
          <span style={{color:"red"}}>{stockError}</span><div style={{marginBottom:'0.5em'}}></div>
          <Button color='primary' size="sm" onClick={()=>{
            if(parseInt(stock)<1)return;
            if(sparepart.value<1)return;
            props.toggler()
            props.onAddMaterial({
              sparepart_id: sparepart.value,
              value: stock
            })
          }}>add material</Button>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
      </ModalFooter>
    </Modal>
  );
};


const SuccesModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Success
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          Form saved
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'success', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };
  
  const FailureModal = (props) => {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
        <ModalHeader toggle={props.toggler}>
          Error
        </ModalHeader>
        <ModalBody className={props.bodyClass}>
          {props.message}
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: 'danger', onClick: props.toggler }} >Close</Btn>
        </ModalFooter>
      </Modal>
    );
  };


const FormContent = ({ScheduleID, SequenceID}) => {
  const {profil, token} = useAuth()
  const [actionType, setActionType] = useState("CREATE"); 
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState({});
  const [formJson, setFormJson] = useState({});
  const [report, setReport] = useState({});
  const [reportMaterials, setReportMaterials] = useState([]);
  const [succesModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);
  const [failureModalMessage, setFailureModalMessage] = useState('');
  const [addMaterialModal, setAddMaterialModal] = useState(false);
  const [spareparts, setSpareparts]  = useState([]);
  const [counter, setCounter] = useState(0)
  const toggleMaterial = ()=>{setAddMaterialModal(!addMaterialModal)}
  const toggleSuccess = ()=>{setSuccessModal(!succesModal)}
  const toggleFailure = ()=>{setFailureModal(!failureModal)}
  const navigate = useNavigate();
  const [attachments, setAttachments] = useState([]);



  const onAddMaterial = async (jsonMaterial)=>{
    setLoading(true);
    const uploadedMaterial = {...jsonMaterial, schedule_id: parseInt(ScheduleID), schedule_sequence_number: parseInt(SequenceID)}
    createReportMaterial(token, uploadedMaterial).then(()=>{
      getReportMaterialsByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((resReportMaterials)=>{
        setReportMaterials(resReportMaterials);
        setLoading(false);
        setSuccessModal(true);
      })
    }).catch((error)=>{
        setFailureModalMessage(error.message)
        setFailureModal(true);
        setLoading(false);
    })
  }

  

  useEffect(()=>{

    

    
    getSpareparts(token).then((resSparepartJson)=>{
      let options = []
      for(let i=0;i<resSparepartJson.length;i++)
      {
        options.push({value: resSparepartJson[i].ID, label: resSparepartJson[i].name+`(${resSparepartJson[i].stock})`})
      }

      setSpareparts(options)
    })
    
    getReportByScheduleIdAndSequenceNumber(token, ScheduleID, SequenceID).then((jsonRes)=>{
       
          jsonRes.schedule = jsonRes.Schedule
          jsonRes.schedule.interval =  jsonRes.Schedule.Interval
          setFormJson(JSON.parse(jsonRes.report_json));
          setSchedule(jsonRes.Schedule);
          setReport(jsonRes);
          setActionType("UPDATE")
          setLoading(false);
        
      
    })
  }, [])
  return (
    <Fragment>
      <AddMaterialModal onAddMaterial={onAddMaterial} spareparts={spareparts} isOpen={addMaterialModal} toggler={toggleMaterial} >....</AddMaterialModal>
      <SuccesModal isOpen={succesModal} toggler={toggleSuccess} >....</SuccesModal>
      <FailureModal message={failureModalMessage} isOpen={failureModal} toggler={toggleFailure} >....</FailureModal>
 
      {loading?(
      <CardBody style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        
            
          <Loading loading={loading}/>
      </CardBody>
    
      ):(
      <>
      <CardBody>

     
        <Table style={{marginTop:'0.5em', marginBottom:'2em'}}>
        <tbody>
          <tr>
            <td>Schedule name</td><td>{schedule.name}</td>  
          </tr>   
          <tr>
            <td>Equipment name</td><td>{schedule.Equipment.name}</td>  
          </tr>   
          <tr>
            <td>Part number</td><td>{schedule.Equipment.part_number}</td>  
          </tr>   
          <tr>
            <td>Serial number</td><td>{schedule.Equipment.serial_number}</td>  
          </tr>  
          <tr>
            <td>Schedule date</td><td>{report.schedule_date}</td>  
          </tr> 

          <tr>
            <td>Person In Charge</td><td>{report.user.Username}</td>  
          </tr> 
       
       
       
        </tbody>
        </Table>
        
       
        <JsonFormDisabledWrapper jsonForm={JSON.parse(report.report_json)} formTitle="Schedule" onUpdateJson={(jsonForm)=>{
   
            
        }}/>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Materials</Label><span>:</span></div>
        <Table style={{marginTop:'0.5em'}}>
        <thead>
          <tr>
            <th>Sparepart Name</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {report.materials.map((reportMaterial)=>{
            return (<tr><td>{reportMaterial.sparepart.name}</td><td>{reportMaterial.value}</td></tr>)
          })}
        
        </tbody>
        </Table>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Attachments </Label><span>:</span></div>
        <ol style={{display:'flex', flexDirection:'column'}}>
        {
          
          report.attachments.map((attachment)=>{
          
              return (
                <li>
                  <a target="_blank" href={baseUrl+"/report/attachment/"+attachment.name}>{attachment.name}</a>
                  
                </li>)
           
          })
          
        }
        </ol>
        <div style={{marginTop:'0.5em'}}><Label style={{marginRight:'0.5em'}}>Approval</Label><span>:</span></div>
        <ol style={{display:'flex', flexDirection:'column'}}>
        {
          
          report.approvals.map((approval)=>{
            if(approval.approval_type.name==="WAITING")
            {
              return (
                <li>
                  <Label>Approval {approval.user.username} STATUS {approval.approval_type.name}</Label>
                  
                </li>)
            }
            return (
            <li>
              <Label>Approval {approval.user.username} STATUS {approval.approval_type.name}, reason:</Label>
              <div className='border'>{approval.reason}</div>
            </li>)
          })
          
        }
        </ol>
       
      </CardBody>
      <CardFooter>
      <DownloadReportPdfButton                  onDownloadFinished={()=>{setCounter(counter+1)}}
                                                schedule={report.Schedule}
                                                report = {report}
                                                jsonReport={JSON.parse(report.report_json)}
                                                date={report.schedule_date}
                                                startDate={report.start_date}
                                                finishedDate={report.end_date}
                                                equipmentName={report.Schedule.Equipment.name}
                                                equipmentSerialNumber={report.Schedule.Equipment.serial_number}
                                               
                                              
                                                >Download report</DownloadReportPdfButton>
                                               

      </CardFooter>
      </>
      )}
    
    
    </Fragment>
  );
};




const ViewReport = ({ScheduleID, SequenceID}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>Report Form</H5>
              </CardHeader>

              <FormContent ScheduleID = {ScheduleID} SequenceID={SequenceID} />
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ViewReport;


